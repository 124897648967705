import { Opacity } from '@mui/icons-material';
import React, { useState } from 'react';

const ChatPopup = ({ onClose, onFetchInfo, info }) => {
    const [id, setId] = useState('');

    const handleFetch = () => {
        onFetchInfo(id);
    };

    return (
        <div style={styles.popup}>
            <div style={styles.header}>
                <span>AI Insight</span>
                <span style={styles.closeButton} onClick={onClose}>X</span>
            </div>
            <div style={styles.body}>
                <input
                    type="text"
                    placeholder="Enter Incident ID"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                    style={styles.input}
                />
                <button onClick={handleFetch} style={styles.button}>Enter</button>
                {info && (
                    <div style={styles.info}>
                        {info.error ? (
                            <span>{info.error}</span>
                        ) : (
                            <p><span style={{fontWeight:"bold"}}>Incident Description:</span> {info}</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const styles = {
    popup: {
        position: 'fixed',
        top: '80px',
        right: '20px',
        width: '300px',
        backgroundColor: '#000',
        Opacity:0.1,
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.3)',
        overflow: 'hidden'
    },
    header: {
        backgroundColor:"#34425A",
        color: '#fff',
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    closeButton: {
        cursor: 'pointer'
    },
    body: {
        padding: '10px'
    },
    input: {
        width: '100%',
        padding: '8px',
        marginBottom: '10px'
    },
    button: {
        width: '100%',
        padding: '8px',
        backgroundColor: '#f7f7f8',
        color: '#000',
        border: 'none',
        cursor: 'pointer'
    },
    info: {
        marginTop: '10px',
        color:"white"
    }
};

export default ChatPopup;
