import React, { useEffect, useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';

const ScatterGraph = () => {
  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loggedinId = useSelector(commonSelectors.selectLoginUserId);

  // Initialize state
  const [scatterData, setScatterData] = useState({
    datasets: [
      {
        label: 'Yearwise Incidents',
        data: [],
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        pointRadius: 6,
        pointHoverRadius: 8,
      },
    ],
  });
  const [fromDate, setFromDate] = useState(new Date("01/01/2020"));
  const [toDate, setToDate] = useState(new Date());
  const [showFilters, setShowFilters] = useState(false);

  // Format date to MM/DD/YYYY
  const formatDateToMMDDYYYY = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const paddedDay = day < 10 ? '0' + day : day;
    const paddedMonth = month < 10 ? '0' + month : month;
    return `${paddedMonth}/${paddedDay}/${year}`;
  };

  // Fetch data based on the current date filters
  const fetchData = async () => {
    try {
      const response = await axios.post(
        apis.base + apis.GET_INCIDENT_FILTER_INCIDENT_COUNT_REPORT_API,
        {
          "start_date": formatDateToMMDDYYYY(fromDate),
          "to_date": formatDateToMMDDYYYY(toDate),
          "is_report": true,
        },
        {
          headers: {
            userid: loggedinId,
            Authorization: "Bearer " + baererToken,
          }
        }
      );

      const years = response.data.map(el => Object.keys(el)[0]);
      const counts = response.data.map(el => Object.values(el)[0].length);

      setScatterData({
        datasets: [
          {
            label: 'Yearwise Incidents',
            data: years.map((year, index) => ({ x: year, y: counts[index] })),
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 2,
            pointRadius: 6,
            pointHoverRadius: 8,
          },
        ],
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch data on mount and when dates change
  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

  // Handle filter button click
  const handleFilterButtonClick = () => {
    setShowFilters(!showFilters);
    if (showFilters) {
      fetchData();
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        ticks: {
          stepSize: 1,
          callback: function(value) {
            return value.toString(); // Adjust as necessary
          }
        }
      },
      y: {
        beginAtZero: true
      }
    },
  };

  return (
    <div className="panel panel-white">
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex justify-content-end mb-2">
            {/* <button
              type="button"
              className="btn btn-success"
              onClick={handleFilterButtonClick} style={{ position: "relative", bottom: "12px", left: "290px" }}
            >
              Apply Filters
            </button> */}
          </div>
          <div style={{ position: "relative", bottom: "85px", left: "183px" }}>
            <div className="d-flex mb-3">
              <div className="col-md-4">
                <label htmlFor="fromDate" className="form-label">From Date</label>
                <DatePicker
                  id="fromDate"
                  className="form-control"
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                />
              </div>
              <div className="col-md-4 ml-3">
                <label htmlFor="toDate" className="form-label">To Date</label>
                <DatePicker
                  id="toDate"
                  className="form-control"
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                />
              </div>
            </div>
            </div>
          
          <div style={{ height: "300px", width: "100%" }}>
            <Scatter 
              data={scatterData} 
              options={options}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScatterGraph;








// import React from 'react';
// import { Scatter } from 'react-chartjs-2';

// const ScatterChart = () => {
//     const mockData = {
//         labels: ['fall', 'adverse drug reaction', 'airway management', 'obstretics', 'restraints'],
//         datasets: [
//             {
//                 label: 'Scatter Plot',
//                 data: [
//                     { x: 1, y: 10 },
//                     { x: 2, y: 25 },
//                     { x: 3, y: 12 },
//                     { x: 4, y: 40 },
//                     { x: 5, y: 30 },
//                 ],
//                 borderColor: 'rgba(75, 192, 192, 1)',
//                 borderWidth: 2,
//                 pointRadius: 6,
//                 pointHoverRadius: 8,
//             },
//         ],
//     };

//     const options = {
//         scales: {
//             x: {
//                 type: 'category',
//                 title: {
//                     display: true,
                    
//                     color: 'black',
//                     font: {
//                         size: 14,
//                     },
//                 },
//             },
//             y: {
//                 title: {
//                     display: true,
                   
//                     color: 'black',
//                     font: {
//                         size: 14,
//                     },
//                 },
//             },
//         },
//         maintainAspectRatio: false,
//     };

//     return (
//         <div>
//             <div style={{ height: '300px', paddingBottom: '20px' }}>
//                 <Scatter data={mockData} options={options} />
//             </div>
//         </div>
//     );
// };

// export default ScatterChart;
