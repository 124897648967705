
import React, { useState, useEffect } from "react";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import { Link } from "react-router-dom";
import { Alert, Button, Select, Snackbar, TextField } from "@mui/material";
import { MenuItem } from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
    commonStore = store.getState().commonStore;
});




function CreateUser() {
    const [name, setName] = useState("");
    const [title, setTitle] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState(0);
    const [profile, setProfile] = useState({});
    const [profileId, setProfileId] = useState(0);
    const [department, setDepartment] = useState({});
    const [departmentId, setDepartmentId] = useState(0);
    const [location, setLocation] = useState("");
    const [nameError, setNameError] = useState("");
    const [titleError, setTitleError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [roleError, setRoleError] = useState("");
    const [profileError, setProfileError] = useState("");
    const [locationError, setLocationError] = useState("");
    const [departmentError, setDepartmentError] = useState("");
    const [formError, setFormError] = useState("");
    const [showSuccessPopup, setShowSuccessPopup] = useState("");  //popup
    const [showPassword, setShowPassword] = React.useState(false);
    const [error, setError] = useState(null);
    const [locationId, setLocationId] = useState(0);

    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);

    const { userId } = useParams();
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const Navigate = useNavigate();
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    console.log(profile, "profile");
    console.log(location, "location");
    console.log(department, "department");
    console.log(role, "role");


    const handleSubmit = () => {
        let formIsValid = true;

        if (!name.trim()) {
            setNameError("Please enter a username");
            formIsValid = false;
        } else {
            setNameError("");
        }     

        if (!title.trim()) {
            setTitleError("Please enter a Title"); 
            formIsValid = false;  
        } else {
            setTitleError("");
        }


        if (!email.trim()) {
            setEmailError("Please enter an email");
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError("Please enter a valid email address");
            formIsValid = false;
        } else {
            setEmailError("");
        }

        if (!password.trim()) {
            setPasswordError("Please enter a password");
            formIsValid = false;
        } else if (password.length < 8) {
            setPasswordError("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
            formIsValid = false;
        } else if (!/\d/.test(password)) {
            setPasswordError("Password must contain at least one number");
            formIsValid = false;
        } else if (!/[a-z]/.test(password)) {
            setPasswordError("Password must contain at least one lowercase letter");
            formIsValid = false;
        } else if (!/[A-Z]/.test(password)) {
            setPasswordError("Password must contain at least one uppercase letter");
            formIsValid = false;
        } else if (!/\W/.test(password)) {
            setPasswordError("Password must contain at least one special character");
            formIsValid = false;
        } else {
            setPasswordError("");
        }


        if (!role) {
            setRoleError("Please Select a role");
            formIsValid = false;
        } else {
            setRoleError("");
        }
        if (!profile) {
            setProfileError("Please Select a profile");
            formIsValid = false;
        } else {
            setProfileError("");
        }
        if (!location) {
            setLocationError("Please Select a location");
            formIsValid = false;
        } else {
            setLocationError("");
        }
        if (!department) {
            setDepartmentError("Please Select a department");
            formIsValid = false;
        } else {
            setDepartmentError("");
        }

        if (formIsValid) {
            const userData = {
                "user_name": name,
                "title": title,
                "email": email,
                "password": password,
                "role_id": role,
                "profile_id": profileId,
                "department_id": departmentId,
                "permission_set_id":0,
                "locations": location,
                "created_by":loginUserId,
                "updated_by":loginUserId,
                "created_timestamp": new Date().toISOString(),
                "updated_timestamp":  new Date().toISOString(),
            };

            axios.post(apis.base + apis.POST_USERS_API, userData,{ headers: { userid: loginUserId, Authorization: "Bearer " + baererToken } })
                .then((res) => {
                    console.log("res---", res.data);

                    setShowSuccessPopup(true);

                    // Reset form fields
                    setName("");
                    setTitle("");
                    setEmail("");
                    setPassword("");
                    setRole(null);
                    setProfile(null);
                    setDepartment(null);
                    // setDepartment(departmentData[0]?.department_info?.department_id || 0);
                    setLocation(null);

                    // window.location.reload(); 
                    setSnackbar({
                        open: true,
                        message: 'Submitted successfully',
                        severity: 'success',
                    })
                    //    callback(res);   //relod when submitting form
                    setError(null);

                })
                .catch((error) => {
                    setSnackbar({
                        open: true,
                        message: 'Submission failed',
                        severity: 'success',
                    });
                    console.error("Error submitting form:", error);
                    setError("Error while Creating a User. Please try again.");

                });
        } else {
            setFormError("");  
            // Please fill in all required fields.  
        }

    };


    const [profileData, setProfileData] = useState([]);
    const [roleData, setRolesData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);
    const [locationData, setLocationData] = useState([])
    useEffect(() => {
        axios.get(apis.base + apis.GET_ALL_PROFILE_API, { headers: { userid: loginUserId, Authorization: "Bearer " + baererToken } })
        .then(response => {
            setProfileData(response.data);
        })
        .catch(error => {
            console.log("Error fetching profile data:", error);
            // Handle error
        });
  
        axios.get(apis.base + apis.GET_ALL_ROLES_API, { headers: { userid: loginUserId, Authorization: "Bearer " + baererToken } })
            .then(response => {
                setRolesData(response.data);
            })
            .catch(error => {
                console.log("Error fetching roles data:", error);
                // Handle error
            });
  
            axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API, { headers: { userid: loginUserId, Authorization: "Bearer " + baererToken } })
            .then(response => {
                setDepartmentData(response.data);
            })
            .catch(error => {
                console.log("Error fetching department data:", error);
                // Handle error
            });
    

        axios.get(apis.base + apis.GET_LOCATION_API, { headers: { userid: loginUserId, Authorization: "Bearer " + baererToken } })
        .then(response => {
            setLocationData(response.data);
        })
        .catch(error => {
            console.log("Error fetching location data:", error);
            // Handle error
        });
    }, []);

    const handleSnackbarClose = () => {
        Navigate("/userslist")
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <div className="page-content">
            <div className="page-inner">
                <div className="page-title">
                    <h3 style={{ color: "black" }}>Create User</h3>
                    <div className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li><Link to="/Dashboard">Home</Link></li>
                            <li><Link to="/userslist">Users</Link></li>
                            <li>Create User</li>
                        </ol>
                    </div>
                </div>
                {/* <div className="panel panel-white"> */}
                <div id="main-wrapper" >
                    {/* <div className="row">
                        </div> */}
                    <div className="col-md-12" style={{ display: "flex", justifyContent: "center" }}>
                        <div className="col-md-6 panel panel-white"   >

                            <div className="panel-body">
                                {error && <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>{error}</p>}
                                {!error && (

                                    <div>
                                        <div className="form-group" >
                                            <label htmlFor="exampleInputEmail1">User Name <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                            <TextField
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                type="text" id="exampleInputEmail1" placeholder="Enter user name"
                                                fullWidth
                                                InputProps={{
                                                    style: { fontSize: '13px' } // Adjust the font size here
                                                }} />

                                            {nameError && <p style={{ color: "red" }}>{nameError}</p>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Title <span style={{ fontWeight: "bold", color: "red" }}>*</span></label>
                                            <Select fullWidth
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}>
                                                <MenuItem value="Mr.">Mr.</MenuItem>
                                                <MenuItem value="Mrs.">Mrs.</MenuItem>
                                                <MenuItem value="Miss.">Miss.</MenuItem>
                                                <MenuItem value="Ms.">Ms.</MenuItem>
                                            </Select>
                                            {titleError && <p style={{ color: "red" }}>{titleError}</p>}
                                        </div>

                                        <div className="form-group" >
                                            <label htmlFor="exampleInputEmail1">Email ID <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                            <TextField
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                type="email" id="exampleInputEmail1" placeholder="Example@gmail.com"
                                                fullWidth
                                                InputProps={{
                                                    style: { fontSize: '13px' } // Adjust the font size here
                                                }} />
                                            {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                                        </div>
                        
                                       <div className="form-group"
                                        >
                                            <InputLabel htmlFor="outlined-adornment-password"
                                                style={{ fontSize: '12px', color: 'black' }}
                                            >Password <span style={{ fontweight: "bold", color: "red" }} >*</span></InputLabel>
                                            <FormControl fullWidth variant="outlined"
                                            >
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            // edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                // label="Password"
                                                />
                                            </FormControl>
                                            {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Role<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                            <Select fullWidth 
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}   >
                                                {roleData.map((role, index) => (
                                                    <MenuItem key={role.id} value={role.id}>{role.role_name}</MenuItem>
                                                ))}
                                            </Select>
                                            {roleError && <p style={{ color: "red" }}>{roleError}</p>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Profile <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                            <Select fullWidth 
                                            value={profile?.profile_name}
                                            onChange={(e) => {
                                                console.log(e.target.value,"prfvalue")
                                                setProfile(e.target.value)
                                                setProfileId(e.target.value.profile_id)
                                                }}  >
                                             {profileData.map((profile_info) => (
            <MenuItem 
                key={profile_info?.profile_info?.profile_id} 
                value={profile_info?.profile_info}
                // selected={profile_info?.profile_info?.profile_id === profile}
            >
                {profile_info?.profile_info?.profile_name}
            </MenuItem>
        ))}
                                            </Select>
                                            {profileError && <p style={{ color: "red" }}>{profileError}</p>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Location <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                            <Select fullWidth
                                            value = {location}
                                            onChange={(e) => setLocation(e.target.value)}>

                                                {locationData?.map((location) => (
                                                    <MenuItem key={location.location_id} value={location.location_id}>{location.location_name}</MenuItem>
                                                ))}
                                                
                                            </Select>
                                            {locationError && <p style={{ color: "red" }}>{locationError}</p>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Department <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                            <Select fullWidth
                                            value={department?.department_name}
                                                onChange={(e) =>{ 
                                                    // console.log(e.target.value.department_id,"deptvalue");
                                                    setDepartment(e.target.value)
                                                    setDepartmentId(e.target.value.department_id)
                                                    }}>
                                                {departmentData.map((department, index) => (
                                                    <MenuItem  key={department?.department_id} value={department}>
                                                        {department?.department_name}
                                                        </MenuItem>
                                                ))}
                                            </Select>
                                            {departmentError && <p style={{ color: "red" }}>{departmentError}</p>}
                                        </div>

                                        {formError && <p style={{ color: "red" }}>{formError}</p>}

                                        <div className="panel-body" style={{ display: 'flex' }}>
                                            <div>
                                                <Button fullWidth variant="contained" type="button" className="btn btn-primary" style={{ height: "40px", width: "100px", fontSize: "15px", backgroundColor: "#22baa0", marginLeft: '-20px', }} onClick={handleSubmit}>Submit</Button>
                                            </div>


                                            {/* Success Popup */}
                                            {showSuccessPopup && (
                                                <Snackbar
                                                    open={snackbar.open}
                                                    autoHideDuration={3000}
                                                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                                                    style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                                >
                                                    <Alert onClose={handleSnackbarClose} severity={"success"} sx={{ width: '100%' }} variant="filled" style={{ width: '300px',backgroundColor:"whitesmoke" ,color: "green", height: '45px', fontSize: '12px' }}>
                                                        {snackbar.message}
                                                    </Alert>
                                                </Snackbar>

                                            )}
                                            <div>
                                                <Link to="/userslist">
                                                    <Button type="button" variant="contained" className="btn btn-success" style={{ height: "40px", width: "100px", marginLeft: '50px', fontSize: "15px", backgroundColor: "#34425a" }} >Cancel</Button>
                                                </Link>
                                            </div>


                                        </div>
                                    </div>
                                )}

                            </div>

                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default CreateUser;


