import { useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import { commonActions, commonSelectors } from "../../utils/commonstore.js";
import store from "../../utils/index.js";
import { useSelector } from "react-redux";
import loadingGif from "../../utils/loading.gif"

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

const LoginForm = () => {

    const [loginEmail, setLoginEmail] = useState("");
    const [password, setPassword] = useState("");
    const [selectedRole, setSelectedRole] = useState("Please Select");
    const permissionsetId = useSelector(commonSelectors.selectUserPermissionsetId);
    const navigate = useNavigate();

    const handleLogin = () => {
        if (selectedRole === "Anonymous") {
            handleAnonymousLogin();
        } else {
            handleAuthenticatedLogin();
        }
    };
    
    // const handleAuthenticatedLogin = async () => {
    //     try {
    //         const res = await axios.post(apis.base + apis.POSTUSER_LOGIN_API, {
    //             email: loginEmail,
    //             password: password
    //         });
    
    //         if (res.data.authenticated) {
    //             const retrievedUser = res.data.retrievedUser;
    //             const token = res.data.token;
    
    //             sessionStorage.setItem('rl6User', JSON.stringify({ retrievedUser, isVisitor: false }));
    //             sessionStorage.setItem('rl6fixbar', JSON.stringify({
    //                 header: false,
    //                 sidebar: false,
    //                 horizintalBar: false,
    //                 toggleSideBar: false,
    //                 compactMenu: false
    //             }));
    //             sessionStorage.setItem("token", token);
    //             sessionStorage.setItem("retrievedUser", JSON.stringify(retrievedUser));
    //             sessionStorage.setItem("user_id", retrievedUser.user_id);
    //             sessionStorage.setItem("permission_set_id", retrievedUser.permission_set_id);
    //             sessionStorage.setItem("profile_id", retrievedUser.profile_id);
    
    //             dispatch(commonActions.setUserInfo(retrievedUser));
    //             dispatch(commonActions.setLoginUserId(retrievedUser.user_id));
    //             dispatch(commonActions.setUserPermissionsetId(retrievedUser.permission_set_id));
    //             dispatch(commonActions.setUserProfileId(retrievedUser.profile_id));
    //             dispatch(commonActions.setBaererToken(token));
    
    //             let accessInfo;
    //             console.log("permissionsetId:",permissionsetId)
    //             if (permissionsetId) {
    //                 const permissionRes = await axios.get(apis.base + apis.GET_PROILE_PERMISSIONID_API + retrievedUser.permission_set_id, {
    //                     headers: {
    //                         userid: retrievedUser.user_id,
    //                         Authorization: `Bearer ${token}`
    //                     }
    //                 });
    //                 accessInfo = permissionRes.data;
    //                 sessionStorage.setItem("accessInfo", JSON.stringify(accessInfo));
    //                 dispatch(commonActions.setAccessInfo(accessInfo));
    //             } else {
    //                 const profileRes = await axios.get(apis.base + apis.GET_PROFILE_BY_ID_API + retrievedUser.profile_id, {
    //                     headers: {
    //                         userid: retrievedUser.user_id,
    //                         Authorization: `Bearer ${token}`
    //                     }
    //                 });
    //                 accessInfo = profileRes.data;
    //                 sessionStorage.setItem("accessInfo", JSON.stringify(accessInfo));
    //                 dispatch(commonActions.setAccessInfo(accessInfo));
    //             }
    //             navigate("/dashboard");
    //         } else {
    //             dispatch(commonActions.setBaererToken(""));
    //             alert("Login failed. Please check your credentials.");
    //         }
    //     } catch (error) {
    //         console.error("Login error:", error);
    //         alert("An error occurred during login. Please try again later.");
    //     }
    // };
    const [loading, setLoading] = useState(false);

    const handleAuthenticatedLogin = async () => {
        setLoading(true);
        try {
            const res = await axios.post(apis.base + apis.POSTUSER_LOGIN_API, {
                email: loginEmail,
                password: password
            });
    
            if (res.data.authenticated) {
                const retrievedUser = res.data.retrievedUser;
                const token = res.data.token;
    
                // Save user details in sessionStorage
                sessionStorage.setItem('rl6User', JSON.stringify({ retrievedUser, isVisitor: false }));
                sessionStorage.setItem('rl6fixbar', JSON.stringify({
                    header: false,
                    sidebar: false,
                    horizintalBar: false,
                    toggleSideBar: false,
                    compactMenu: false
                }));
                sessionStorage.setItem("token", token);
                sessionStorage.setItem("retrievedUser", JSON.stringify(retrievedUser));
                sessionStorage.setItem("user_id", retrievedUser.user_id);
                sessionStorage.setItem("permission_set_id", retrievedUser.permission_set_id);
                sessionStorage.setItem("profile_id", retrievedUser.profile_id);
    
                // Dispatch actions to Redux store
                dispatch(commonActions.setUserInfo(retrievedUser));
                dispatch(commonActions.setLoginUserId(retrievedUser.user_id));
                dispatch(commonActions.setUserPermissionsetId(retrievedUser.permission_set_id));
                dispatch(commonActions.setUserProfileId(retrievedUser.profile_id));
                dispatch(commonActions.setBaererToken(token));
    
                let accessInfo = {};
                const permissionSetId = retrievedUser.permission_set_id;
                const profileId = retrievedUser.profile_id;
    
                // Fetch Permission Set
                let permissionSet = {};
                if (permissionSetId) {
                    const permissionRes = await axios.get(apis.base + apis.GET_PROILE_PERMISSIONID_API + permissionSetId, {
                        headers: {
                            userid: retrievedUser.user_id,
                            Authorization: `Bearer ${token}`
                        }
                    });
                    permissionSet = permissionRes.data;
                    console.log(permissionRes.data, ":permissionSetData");
                }
    
                // Fetch Profile Data
                let profileData = {};
                if (profileId) {
                    const profileRes = await axios.get(apis.base + apis.GET_PROFILE_BY_ID_API + profileId, {
                        headers: {
                            userid: retrievedUser.user_id,
                            Authorization: `Bearer ${token}`
                        }
                    });
                    profileData = profileRes.data;
                    console.log(profileRes.data, ":profileData");
                }
    
                // Combine permissions: Use permissionSet to override profileData where applicable
                accessInfo = {
                    reports: permissionSet.reports ? 
                        (parseInt(permissionSet.reports, 2) | parseInt(profileData.reports || '0000', 2)).toString(2).padStart(4, '0') : 
                        profileData.reports || '0000',
                    profiles: permissionSet.profiles ? 
                        (parseInt(permissionSet.profiles, 2) | parseInt(profileData.profiles || '0000', 2)).toString(2).padStart(4, '0') : 
                        profileData.profiles || '0000',
                    forms: permissionSet.forms ? 
                        (parseInt(permissionSet.forms, 2) | parseInt(profileData.forms || '0000', 2)).toString(2).padStart(4, '0') : 
                        profileData.forms || '0000',
                    submitted_files: permissionSet.submitted_files ? 
                        (parseInt(permissionSet.submitted_files, 2) | parseInt(profileData.submitted_files || '0000', 2)).toString(2).padStart(4, '0') : 
                        profileData.submitted_files || '0000',
                    roles: permissionSet.roles ? 
                        (parseInt(permissionSet.roles, 2) | parseInt(profileData.roles || '0000', 2)).toString(2).padStart(4, '0') : 
                        profileData.roles || '0000',
                    users: permissionSet.users ? 
                        (parseInt(permissionSet.users, 2) | parseInt(profileData.users || '0000', 2)).toString(2).padStart(4, '0') : 
                        profileData.users || '0000',
                    departments: permissionSet.departments ? 
                        (parseInt(permissionSet.departments, 2) | parseInt(profileData.departments || '0000', 2)).toString(2).padStart(4, '0') : 
                        profileData.departments || '0000',
                    permission_list: permissionSet.permission_list ? 
                        (parseInt(permissionSet.permission_list, 2) | parseInt(profileData.permission_list || '0000', 2)).toString(2).padStart(4, '0') : 
                        profileData.permission_list || '0000',
                    locations: permissionSet.locations ? 
                        (parseInt(permissionSet.locations, 2) | parseInt(profileData.locations || '0000', 2)).toString(2).padStart(4, '0') : 
                        profileData.locations || '0000',
                    workflow: permissionSet.workflow ? 
                        (parseInt(permissionSet.workflow, 2) | parseInt(profileData.workflow || '0000', 2)).toString(2).padStart(4, '0') : 
                        profileData.workflow || '0000',
    
                    dashboard: permissionSet.dashboard ? 
                        (parseInt(permissionSet.dashboard, 2) | parseInt(profileData.dashboard || '0', 2)).toString(2).padStart(1, '0') : 
                        profileData.dashboard || '0',
                    audit: permissionSet.audit ? 
                        (parseInt(permissionSet.audit, 2) | parseInt(profileData.audit || '0', 2)).toString(2).padStart(1, '0') : 
                        profileData.audit || '0',
                    exports: permissionSet.exports ? 
                        (parseInt(permissionSet.exports, 2) | parseInt(profileData.exports || '0', 2)).toString(2).padStart(1, '0') : 
                        profileData.exports || '0',
                };
    
                // Save the combined accessInfo in sessionStorage and dispatch to store
                sessionStorage.setItem("accessInfo", JSON.stringify(accessInfo));
                dispatch(commonActions.setAccessInfo(accessInfo));
                
                navigate("/dashboard");
            } else {
                dispatch(commonActions.setBaererToken(""));
                alert("Login failed. Please check your credentials.");
            }
        } catch (error) {
            console.error("Login error:", error);
            alert("An error occurred during login. Please try again later.");
        } finally {
            setLoading(false);
        }
    };
    
    
    if (loading) {
    
        return (
            <div style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
              }}>
                <img src={loadingGif} alt="Loading..." style={{ width: '50px', height: '50px' }} />
                <p style={{ marginTop: '10px', fontFamily: 'Arial, sans-serif', fontSize: '14px', fontWeight: 'bold' }}>Loading ProjectM</p>
              </div>
          );
      }


    const handleAnonymousLogin = async () => {
        try {
            const [departmentsResponse, locationsResponse] = await Promise.all([
                axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API),
                axios.get(apis.base + apis.GET_LOCATION_API)
            ]);
            sessionStorage.setItem('rl6User', JSON.stringify({ real_name: "Patient", isVisitor: true }));
            sessionStorage.setItem('departments', JSON.stringify(departmentsResponse.data));
            sessionStorage.setItem('locations', JSON.stringify(locationsResponse.data));
            navigate("/forms");
        } catch (error) {
            console.error('Error fetching departments or locations:', error);
            alert("Error fetching departments or locations. Please try again later.");
        }
    };
    
        // const handleLogin = async () => {
        //     if (selectedRole === "Anonymous") {
        //         try {
        //             const [departmentsResponse, locationsResponse] = await Promise.all([
        //                 axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API),
        //                 axios.get(apis.base + apis.GET_LOCATION_API)
        //             ]);
        //             sessionStorage.setItem('rl6User', JSON.stringify({ real_name: "Patient", isVisitor: true }));
        //             sessionStorage.setItem('departments', JSON.stringify(departmentsResponse.data));
        //             sessionStorage.setItem('locations', JSON.stringify(locationsResponse.data));
        //             navigate("/forms");
        //         } catch (error) {
        //             console.error('Error fetching departments or locations:', error);
        //             alert("Error fetching departments or locations. Please try again later.");
        //         }
        //     } else {
        //         try {
        //             const res = await axios.post(apis.base + apis.POSTUSER_LOGIN_API, {
        //                 email: loginEmail,
        //                 password: password
        //             });
        
        //             if (res.data.authenticated) {
        //                 const retrievedUser = res.data.retrievedUser;
        //                 const token = res.data.token;
        
        //                 sessionStorage.setItem('rl6User', JSON.stringify({ retrievedUser, isVisitor: false }));
        //                 sessionStorage.setItem('rl6fixbar', JSON.stringify({
        //                     header: false,
        //                     sidebar: false,
        //                     horizintalBar: false,
        //                     toggleSideBar: false,
        //                     compactMenu: false
        //                 }));
        //                 sessionStorage.setItem("token", token);
        //                 sessionStorage.setItem("retrievedUser", JSON.stringify(retrievedUser));
        //                 sessionStorage.setItem("user_id", retrievedUser.user_id);
        //                 sessionStorage.setItem("permission_set_id", retrievedUser.permission_set_id);
        //                 sessionStorage.setItem("profile_id", retrievedUser.profile_id);
        
        //                 dispatch(commonActions.setUserInfo(retrievedUser));
        //                 dispatch(commonActions.setLoginUserId(retrievedUser.user_id));
        //                 dispatch(commonActions.setUserPermissionsetId(retrievedUser.permission_set_id));
        //                 dispatch(commonActions.setUserProfileId(retrievedUser.profile_id));
        //                 dispatch(commonActions.setBaererToken(token));
        
        //                 let accessInfo;
        
        //                 if (permissionsetId) {
        //                     const permissionRes = await axios.get(apis.base + apis.GET_PROILE_PERMISSIONID_API + retrievedUser.permission_set_id, {
        //                         headers: {
        //                             userid: retrievedUser.user_id,
        //                             Authorization: `Bearer ${token}`
        //                         }
        //                     });
        //                     accessInfo = permissionRes.data;
        //                     sessionStorage.setItem("accessInfo", JSON.stringify(accessInfo));
        //                     dispatch(commonActions.setAccessInfo(accessInfo));
        //                 } else {
        //                     const profileRes = await axios.get(apis.base + apis.GET_PROFILE_BY_ID_API + retrievedUser.profile_id, {
        //                         headers: {
        //                             userid: retrievedUser.user_id,
        //                             Authorization: `Bearer ${token}`
        //                         }
        //                     });
        //                     accessInfo = profileRes.data;
        //                     sessionStorage.setItem("accessInfo", JSON.stringify(accessInfo));
        //                     dispatch(commonActions.setAccessInfo(accessInfo));
        //                 }
        //                 navigate("/dashboard");
        //             } else {
        //                 dispatch(commonActions.setBaererToken(""));
        //                 alert("Login failed. Please check your credentials.");
        //             }
        //         } catch (error) {
        //             console.error("Login error:", error);
        //             alert("An error occurred during login. Please try again later.");
        //         }
        //     }
        // };
    
    
    
    return (
        <div>
            <main className="page-content">
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-3 center">
                                <div className="login-box">
                                    <a href="index.html" className="logo-name text-lg text-center">PROJECT M</a>
                                    <p className="text-center m-t-md">Please login into your account.</p>

                                    <form className="m-t-md" >
                                    <div className="form-group"></div>
                                    
                                        <div className="form-group">
                                            <input
                                                type="ID"
                                                className="form-control"
                                                placeholder="Login-Email"
                                                required
                                                onChange={(e) => setLoginEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Password"
                                                required
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            
                                        </div>
                                        <div> <button
                                            type="button"
                                            className="btn btn-success btn-block"
                                            onClick={handleLogin}
                                        >
                                            Login
                                        </button>
                                        </div>

                                        <button
                                        type="button"
                                        className="btn btn-dark btn-block"
                                        onClick={handleAnonymousLogin}
                                        style={{backgroundColor:"black",color:"whitesmoke",marginTop:"10px"}}
                                    >
                                        Login as Anonymous
                                    </button>

                                        {/* <a href="forgot.html" className="display-block text-center m-t-md text-sm">Forgot Password?</a> */}

                                    </form>
                                    <p className="text-center m-t-xs text-sm">2024 &copy; Medbee by Stalwarts.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </div>
    )
}
export default LoginForm;