import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { Button, TextField, Snackbar, } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import DatePicker from 'react-datepicker';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store from "../../utils/index";
import { commonSelectors } from "../../utils/commonstore";
const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

const LabSpecimenCollectionForm = () => {
  const { action, incident_id } = useParams();
  const editIncidentData = useSelector(commonSelectors.selectEditIncidentData);
  const viewIncidentData = useSelector(commonSelectors.selectViewIncidentData);


  const getCurrentTime = () => {
    const now = new Date();
    const utcOffset = -4 * 60; 
    const utcTime = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
    const estTime = new Date(utcTime + (utcOffset * 60 * 1000));
    const hours = String(estTime.getHours()).padStart(2, '0');
    const minutes = String(estTime.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const navigateTo = useNavigate();
  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loginUserId = useSelector(commonSelectors.selectLoginUserId);
  // general incident info
  const [generalincidenterror, setGeneralIncidenterror] = useState("");
  const [patientTypeError, setPatientTypeError] = useState("");
  const [injuryIncurredError, setInjuryIncurredError] = useState("");
  const [equipmentMalfunctionedError, setEquipmentMalfunctionedError] = useState("");
  // demographics info
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [genderError, SetGenderError] = useState("");
  const [dobError, setdobError] = useState("");
  const [addressError, setaddressError] = useState("");
  const [streetError, setstreetError] = useState("");
  const [cityError, setcityError] = useState("");
  const [stateError, setstateError] = useState("");
  const [countryError, setcountryError] = useState("");
  const [zipCodeError, setzipCodeError] = useState("");
  // incident details
  const [incidentdateError, setIncidentdateError] = useState("");
  const [stateincidentoccuredError, setStateIncidentOccuredError] = useState("");
  const [siteincidentoccuredError, setSiteIncidentOccuredError] = useState("");
  // special additional info
  const [specificIncidentTypeError, setSpecificIncidentTypeError] = useState("");
  const [reportedIncidentError, setReportedIncidentError] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [incidentdescriptionError, setIncidentDescriptionError] = useState("");


  const [showSuccessPopup, setShowSuccessPopup] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState('');



  const [inputValue, setInputValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const accessInfo = useSelector(commonSelectors.selectAccessInfo);

  const createAllowed = [ "1100", "1101", "1110", "1111"];

// Check if the user has the permission to submit (create or update)
const canSubmit = accessInfo?.forms && createAllowed.includes(accessInfo.forms);

// Determine if it's an "edit-only" permission (like 1010)
const isEditOnly = accessInfo?.forms === "1010" || accessInfo?.forms === "1011";

  const [labOrSpicemenCollectionState, setLabOrSpicemenCollectionState] = useState(
    {
      "general_incident_info": {
        "incident_type": "",
        "patient_type": "",
        "injury_incurred": "",
        "equipment_involved": ""
      },
      "demographics_info": {
        "first_name": "",
        "last_name": "",
        "gender": 0,
        "dob": new Date(),
        "address": "",
        "street": "",
        "city": "",
        "state": "",
        "country": "",
        "zipcode": ""
      },
      "incident_details": {
        "incident_date": new Date(),
        "incident_time": getCurrentTime(),
        "incident_state": 0,
        "incident_site": 0,
        "file_owner": "",
        "entered_date": new Date(),
        "entered_time": getCurrentTime(),
        "entered_by": "",
        "witness_name": "",
        "witness_address": "",
        "witness_phone": ""
      },
      "special_additional_info": {
        "department_id": 0,
        "specific_incident_type": "",
        "incident_date": new Date(),
        "hospitalized_on": new Date(),
        "specimen_type": "",
        "collection_site": "",
        "collector_name": "",
        "patient_reaction": "",
        "outcome": "",
        "sequelae": "",
        "relevant_history": "",
        "other_information": "",
        "physician_involved": false,
        "contributing_factors": "",
        "immediate_actions_taken": "",
        "reported_incident_severity": "",
        "incident_description": "",
        "feedback": false,
        "attachment": []
      }
    })

  const labOrSpicemenCollectionStateString = JSON.stringify(labOrSpicemenCollectionState);

  const handleStateChange = (key, value) => {
    setLabOrSpicemenCollectionState(prevState => {
        // Clone the previous state to ensure immutability
        const updatedState = JSON.parse(JSON.stringify(prevState));

        // Update the specific property based on the key
        switch (key) {
            // general incident info
            case "incident_type":
                updatedState.general_incident_info.incident_type = value;
                break;
            case "patient_type":
                updatedState.general_incident_info.patient_type = value;
                break;
            case "injury_incurred":
                updatedState.general_incident_info.injury_incurred = value;
                break;
            case "equipment_involved":
                updatedState.general_incident_info.equipment_involved = value;
                break;
            // demographics
            case "first_name":
                updatedState.demographics_info.first_name = value;
                break;
            case "last_name":
                updatedState.demographics_info.last_name = value;
                break;
            case "gender":
                updatedState.demographics_info.gender = value;
                break;
            case "dob":
                updatedState.demographics_info.dob = value;
                break;
            case "address":
                updatedState.demographics_info.address = value;
                break;
            case "street":
                updatedState.demographics_info.street = value;
                break;
            case "city":
                updatedState.demographics_info.city = value;
                break;
            case "state":
                updatedState.demographics_info.state = value;
                break;
            case "country":
                updatedState.demographics_info.country = value;
                break;
            case "zipcode":
                updatedState.demographics_info.zipcode = value;
                break;
            case "addressValues":
                const { zipcode, country, state, city, street, address } = value;
                updatedState.demographics_info = { zipcode, country, state, city, street, address }; 
                break;
            // incident details
            case "incident_date":
                updatedState.incident_details.incident_date = value;
                break;
            case "incident_time":
                updatedState.incident_details.incident_time = value;
                break;
            case "incident_state":
                updatedState.incident_details.incident_state = value;
                break;
            case "incident_site":
                updatedState.incident_details.incident_site = value;
                break;
            case "file_owner":
                updatedState.incident_details.file_owner = value;
                break;
            case "entered_date":
                updatedState.incident_details.entered_date = value;
                break;
            case "entered_time":
                updatedState.incident_details.entered_time = value;
                break;
            case "entered_by":
                updatedState.incident_details.entered_by = value;
                break;
            case "witness_name":
                updatedState.incident_details.witness_name = value;
                break;
            case "witness_phone":
                updatedState.incident_details.witness_phone = value;
                break;
            case "witness_address":
                updatedState.incident_details.witness_address = value;
                break;
            // lab collection info
            case "department_id":
                updatedState.special_additional_info.department_id = value;
                break;
            case "specific_incident_type":
                updatedState.special_additional_info.specific_incident_type = value;
                break;
            case "hospitalized_on":
                updatedState.special_additional_info.hospitalized_on = value;
                break;
            case "specimen_type":
                updatedState.special_additional_info.specimen_type = value;
                break;
            case "collection_site":
                updatedState.special_additional_info.collection_site = value;
                break;
            case "collector_name":
                updatedState.special_additional_info.collector_name = value;
                break;
            case "patient_reaction":
                updatedState.special_additional_info.patient_reaction = value;
                break;
            case "outcome":
                updatedState.special_additional_info.outcome = value;
                break;
            case "sequelae":
                updatedState.special_additional_info.sequelae = value;
                break;
            case "relevant_history":
                updatedState.special_additional_info.relevant_history = value;
                break;
            case "other_information":
                updatedState.special_additional_info.other_information = value;
                break;
            case "physician_involved":
                updatedState.special_additional_info.physician_involved = value;
                break;
            case "contributing_factors":
                updatedState.special_additional_info.contributing_factors = value;
                break;
            case "immediate_actions_taken":
                updatedState.special_additional_info.immediate_actions_taken = value;
                break;
            case "reported_incident_severity":
                updatedState.special_additional_info.reported_incident_severity = value;
                break;
            case "incident_description":
                updatedState.special_additional_info.incident_description = value;
                break;
            case "feedback":
                updatedState.special_additional_info.feedback = value;
                break;
            default:
                return prevState;
        }

        // Return the updated state
        return updatedState;
    });
};

  const generalIncidentTypeList = [
    { label: 'Lab/Specimen Collection' }
  ]
  const specificIncidentTypeList = [
    { label: 'Accidental puncture or laceration' },
    { label: 'Air embolism' },
    { label: 'Blood incompatibility' },
    { label: 'Catheter associated UTI' },
    { label: 'Central venous catheter related blood stream infection' },
    { label: 'Death among surgical inpatients' },
    { label: 'Falls and trauma' },
    { label: 'Foreign object retained after surgery' },
    { label: 'Iatrogenic pneumothorax' },
    { label: 'Iatrogenic pneumothorax with venous catheterization' },
  ]

  const reportedIncidentSeverityList = [
    { label: '1 - Minor' },
    { label: '2 - Moderate' },
    { label: '3 - Major' },
    { label: 'P3 - Potential Major' },
    { label: '4 - Catastrophic' },
    { label: 'P4-Potential Catastrophic' },
  ]
  const departmentsList = [
    { label: 'Neurology' },
    { label: 'Radiology' },
    { label: 'Orthology' },
  ]
  const patientReactionList = [
    { label: 'Allergic Reaction' },
    { label: 'Local Reaction' },
    { label: 'Systemic Reaction' },
    { label: 'Neurological Disorders' },

  ]

  const sequelaeList = [
    { label: 'Medically significant events' },
    { label: 'Persistent disability' },
    { label: 'Birth defect' },
    { label: 'Not Applicable' },
  ]

  const patientTypeList = [
    { label: 'In-Patient' },
    { label: 'Out-Patient' },
  ]

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;
    const namePattern = /^[a-zA-Z]+$/;
    const zipCodePattern = /^\d{6}$/;



    if (!labOrSpicemenCollectionState.general_incident_info.incident_type) {
      setGeneralIncidenterror("Please Select a General Incident Type.");
    } else {
      setGeneralIncidenterror("");
    }
    if (!labOrSpicemenCollectionState.general_incident_info.patient_type) {
      setPatientTypeError("Please Enter Patient Type.");
    } else {
      setPatientTypeError("");
    }
    if (!labOrSpicemenCollectionState.general_incident_info.injury_incurred) {
      setInjuryIncurredError("Please Enter Injury Incurred.");
    } else {
      setInjuryIncurredError("");
    }
    if (!labOrSpicemenCollectionState.general_incident_info.equipment_involved) {
      setEquipmentMalfunctionedError("Please Enter Equipment Involved/ Malfunctioned.");
    } else {
      setEquipmentMalfunctionedError("");
    }

    if (!labOrSpicemenCollectionState.demographics_info.last_name.match(namePattern)) {
      setLastNameError("Last name should only contain alphabets.");
      valid = false;
    } else {
      setLastNameError("");
    }



    // incidentdetails
    if (!labOrSpicemenCollectionState.incident_details.incident_date) {
      setIncidentdateError("Please Enter Incidentdate.");
    } else {
      setIncidentdateError("");
    }
    if (!labOrSpicemenCollectionState.incident_details.incident_state) {
      setStateIncidentOccuredError("Please Enter State Incident Occured.");
    } else {
      setStateIncidentOccuredError("");
    }

    if (!labOrSpicemenCollectionState.incident_details.incident_site) {
      setSiteIncidentOccuredError("Please Enter Site Incident Occured.");
    } else {
      setSiteIncidentOccuredError("");
    }

    // special incident info
    if (!labOrSpicemenCollectionState.special_additional_info.department_id) {
      setDepartmentError("Please select the department.");
    } else {
      setDepartmentError("");
    }
    if (!labOrSpicemenCollectionState.special_additional_info.specific_incident_type) {
      setSpecificIncidentTypeError("Please select specific incident type.");
    } else {
      setSpecificIncidentTypeError("");
    }
    if (!labOrSpicemenCollectionState.special_additional_info.reported_incident_severity) {
      setReportedIncidentError("Please select reported incident severity.");
    } else {
      setReportedIncidentError("");
    }
    if (!labOrSpicemenCollectionState.special_additional_info.incident_description) {
      setIncidentDescriptionError("Please Enter incident description.");
    } else {
      setIncidentDescriptionError("");
    }

    const validateInputs = () => {
      if (
        !labOrSpicemenCollectionState.general_incident_info.incident_type ||
        !labOrSpicemenCollectionState.general_incident_info.patient_type ||
        !labOrSpicemenCollectionState.general_incident_info.injury_incurred ||
        !labOrSpicemenCollectionState.general_incident_info.equipment_involved ||
        !labOrSpicemenCollectionState.demographics_info.last_name ||


        !labOrSpicemenCollectionState.incident_details.incident_date ||
        !labOrSpicemenCollectionState.incident_details.incident_state ||
        !labOrSpicemenCollectionState.incident_details.incident_site ||

        !labOrSpicemenCollectionState.special_additional_info.department_id ||
        !labOrSpicemenCollectionState.special_additional_info.incident_description ||
        !labOrSpicemenCollectionState.special_additional_info.specific_incident_type ||
        !labOrSpicemenCollectionState.special_additional_info.reported_incident_severity
      ) {
        return false;
      }
      return true;
    };

    if (!validateInputs()) {
      console.log("Form submission failed. Please correct the errors.");
      return;
    }
    if (editIncidentData.incident_id) {
      handleUpdate();
    } else {
      handlePost();
    }
  };

  const putdata = {

    "file_count": 0,
    "is_deleted": false,
    "spec": labOrSpicemenCollectionStateString,
    "updated_by": loginUserId,
    "updated_timestamp": new Date().toISOString('en-US'),
    "files_info": ""

  }

  const [files, setFiles] = useState([]);

  const handleFileUpload = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFiles([...files, ...selectedFiles]);
    }
  };
  const handlePost = () => {
    const formData = new FormData();
    var reqb = {
      is_deleted: false,
      spec: labOrSpicemenCollectionStateString,
      created_by: loginUserId,
      updated_by: loginUserId,
    }
    formData.append("IncidentInfo", JSON.stringify(reqb));


    files.forEach((file, index) => {
      formData.append(`files`, file);
    });


    axios.post(apis.base + apis.POST_INCIDENT_API, formData, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log("Response data:", response.data);
        console.log("Form submitted successfully!");
        setShowSuccessPopup(true);
        setSnackbarMessage("Form submitted successfully!");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleUpdate = () => {
    axios.put(apis.base + apis.PUT_INCIDENT_BYID_API + editIncidentData.incident_id, putdata, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((res) => {
        console.log("putdata", res);
        console.log("Form updated successfully!");
        // resetFormState();
        setShowSuccessPopup(true);
        setSnackbarMessage('Feedback updated successfully!');
      })
      .catch((error) => {
        console.error("Error updating form:", error);
      });
  }
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleCloseSnackbar = () => {
    setShowSuccessPopup(false);
    navigateTo('/forms') // Hide snackbar when closed
  };

  
  const [locationData, setLocationData] = useState([])
  const [departmentData, setDepartmentData] = useState([]);
  useEffect(() => {
    axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setDepartmentData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });

    axios.get(apis.base + apis.GET_LOCATION_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setLabOrSpicemenCollectionState(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
    axios.get(apis.base + apis.GET_INCIDENT_BYID_API + incident_id, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        if (incident_id) {
          setLabOrSpicemenCollectionState(JSON.parse(response.data.form_info.spec));
        }
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });

  }, []);


  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleDeleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  // First name , Last name , File Owner name (first letter should be capitalized )
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div>
      <div className="page-content">
        <div className="page-inner">
          <div className="page-title">
            <h3>Lab/Specimen Collection</h3>
            <div className="page-breadcrumb">
              <ol className="breadcrumb">
                <li><Link to="/Dashboard">Home</Link></li>
                <li><Link to="/forms">Forms</Link></li>
                <li className="active">Lab/Specimen Collection</li>
              </ol>
            </div>
          </div>

          <div class="col-md-12 ">
            <div class="col-md-12">
              {/* General Incident Type */}
              <form onSubmit={handleSubmit}>

                <div id="main-wrapper">
                  <div class="row ">
                    <div >
                      <div class="panel panel-white">
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >General Incident Information</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6"  >
                                    <label for="surgeryType">General Incident Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          options={generalIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select incident Type"
                                              fullWidth
                                            />
                                          )}
                                          value={generalIncidentTypeList.find(option => option.label === labOrSpicemenCollectionState.general_incident_info?.incident_type)}
                                        />) : (
                                        <Autocomplete
                                          disablePortal
                                          options={generalIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select incident Type"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}
                                      <div className="text-danger ">{generalincidenterror}</div>
                                    </div>

                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" >Patient Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                    <div >
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          options={patientTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select patient Type"
                                              fullWidth
                                            />
                                          )}
                                          value={patientTypeList.find(option => option.label === labOrSpicemenCollectionState.general_incident_info?.patient_type) || null}
                                        />
                                      ) : (
                                        <Autocomplete
                                          disablePortal
                                          options={patientTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select patient Type"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}
                                      <div className="text-danger ">{patientTypeError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType">Injury Incurred <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                    <TextField type="text"
                                        fullWidth
                                        placeholder="Injury Incurred"
                                        disabled={action == "view"}
                                        value={labOrSpicemenCollectionState.general_incident_info?.injury_incurred}
                                        onChange={(e) => {
                                          var temp = JSON.parse(JSON.stringify(labOrSpicemenCollectionState))
                                          temp.general_incident_info.injury_incurred = e.target.value;
                                          setLabOrSpicemenCollectionState(temp)
                                        }}
                                      />

                                      <div className="text-danger">{injuryIncurredError}</div>
                                    </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Equipment Involved/ Malfunctioned <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text"
                                        fullWidth
                                        placeholder="Equipment Involved/ Malfunctioned"
                                        disabled={action == "view"}
                                        value={labOrSpicemenCollectionState.general_incident_info?.equipment_involved}
                                        onChange={(e) => {
                                          const updatedState = {
                                            ...labOrSpicemenCollectionState,
                                            general_incident_info: {
                                              ...labOrSpicemenCollectionState.general_incident_info,
                                              equipment_involved: e.target.value
                                            }
                                          };
                                          setLabOrSpicemenCollectionState(updatedState);
                                        }}
                                      />
                                      <div className="text-danger">{equipmentMalfunctionedError}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="main-wrapper">
                  <div class="row">
                    <  div>
                      <div class="panel panel-white">
                        <Accordion >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title">Demographics</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6"  >
                                    <label  >First Name  </label>
                                    <div>
                                    <TextField type="text" fullWidth
                                        placeholder="First Name"

                                        disabled={action == "view"}

                                        value={labOrSpicemenCollectionState.demographics_info?.first_name}
                                        onChange={(e) => handleStateChange("first_name",capitalizeFirstLetter (e.target.value))}
                                      />

                                      <div className="text-danger">{firstNameError}</div>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label >Last Name<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      <TextField type="text" fullWidth
                                        placeholder="Enter Last Name"

                                        disabled={action == "view"}

                                        value={labOrSpicemenCollectionState?.demographics_info?.last_name}
                                        onChange={(e) => handleStateChange("last_name",capitalizeFirstLetter (e.target.value))}
                                      />

                                      <div className="text-danger">{lastNameError}</div>
                                    </div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="exampleInputName2">Sex</label>
                                    <div>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={labOrSpicemenCollectionState?.demographics_info?.gender === "male"} onChange={() => handleStateChange("gender", "male")} /> Male
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={labOrSpicemenCollectionState?.demographics_info?.gender === "female"} onChange={() => handleStateChange("gender", "female")} /> Female
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={labOrSpicemenCollectionState?.demographics_info?.gender === "others"} onChange={() => handleStateChange("gender", "others")} /> Others
                                      </label>
                                      <div className="text-danger col-md-6 ">{genderError}</div>
                                    </div>

                                  </div>

                                  < div class="form-group col-md-6 " style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="exampleInputName">DOB
                                      {/* <span style={{ fontweight: "bold", color: "red" }} >*</span> */}
                                    </label>
                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={labOrSpicemenCollectionState?.demographics_info?.dob}
                                      onChange={(date) => {
                                        setLabOrSpicemenCollectionState({
                                          ...labOrSpicemenCollectionState,
                                          demographics_info: {
                                            ...labOrSpicemenCollectionState?.demographics_info,
                                            dob: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />
                                    {/* <div className="text-danger">{dobError}</div> */}

                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className=" form-group col-md-6" style={{ position: "relative" }}>
                                    <label >Address</label>
                                    {action === "view" ? (
                                      <div>
                                        <GooglePlacesAutocomplete
                                          apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                          selectProps={{
                                            onChange: (e, a, b) => {
                                              handleStateChange("address", e.value)
                                              geocodeByPlaceId(e.value.place_id)
                                                .then(results => {
                                                  console.log(results)
                                                  handleStateChange("addressValues", {
                                                    "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                    "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                    "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                    "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                    "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                                  })
                                                })
                                                .catch(error => console.error(error));
                                            }

                                          }}
                                        />
                                        <div style={{ position: "absolute", width: "95%", height: "37px", background: "black", opacity: "0.1", zIndex: 2, top: "24px" }}>
                                        </div>
                                      </div>
                                    ) : (
                                      <GooglePlacesAutocomplete
                                        apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                        selectProps={{
                                          onChange: (e, a, b) => {
                                            handleStateChange("address", e.value)
                                            geocodeByPlaceId(e.value.place_id)
                                              .then(results => {
                                                console.log(results)
                                                handleStateChange("addressValues", {
                                                  "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                  "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                  "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                  "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                  "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                                })
                                              })
                                              .catch(error => console.error(error));
                                          }

                                        }}
                                      />
                                    )}
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label >Street</label>
                                    <div>
                                      <TextField type="text" fullWidth placeholder="Street"
                                        value={labOrSpicemenCollectionState?.demographics_info?.street}
                                        onChange={(e) => handleStateChange("street", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                      <div className="text-danger">{streetError}</div>
                                    </div>
                                  </div>

                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="exampleInputName">City</label>
                                    <TextField type="text" fullWidth placeholder="City"
                                      value={labOrSpicemenCollectionState?.demographics_info?.city}
                                      onChange={(e) => handleStateChange("city", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{cityError}</div>
                                  </div>


                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">State/Region</label>
                                    <TextField type="text" fullWidth placeholder="State/Region"
                                      value={labOrSpicemenCollectionState?.demographics_info?.state}
                                      onChange={(e) => handleStateChange("state", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{stateError}</div>
                                  </div>
                                </div>



                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="exampleInputName">Country</label>
                                    <TextField type="text" fullWidth placeholder="Country"
                                      value={labOrSpicemenCollectionState?.demographics_info?.country}
                                      onChange={(e) => handleStateChange("country", e.target.value)}
                                      disabled={action == "view"}

                                    />
                                    <div className="text-danger">{countryError}</div>
                                  </div>
                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">Zip code</label>
                                    <TextField
                                      placeholder='Zip Code'
                                      fullWidth
                                      type="text"
                                      disabled={action == "view"}
                                      value={labOrSpicemenCollectionState?.demographics_info?.zipcode}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d{0,5}$/.test(value)) {
                                          setInputValue(value);
                                          handleStateChange("zipcode", value);
                                        }
                                      }}
                                      inputProps={{
                                        pattern: '[0-9]*',
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="main-wrapper">
                  <div class="row">
                    <div>
                      <div class="panel panel-white">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >Incident  Details</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="surgeryType"  >Incident Date <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={labOrSpicemenCollectionState?.incident_details?.incident_date}
                                      onChange={(date) => {
                                        setLabOrSpicemenCollectionState({
                                          ...labOrSpicemenCollectionState,
                                          incident_details: {
                                            ...labOrSpicemenCollectionState?.incident_details,
                                            incident_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />


                                    <div className="text-danger">{incidentdateError}</div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" > Incident Time</label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "incident_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >State Where Incident Occurred at<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}

                                          disabled={action == "view"}

                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />
                                          )}
                                          // defaultValue={{ label: labOrSpicemenCollectionState.incident_details.incident_state }}
                                          value={locationData.filter(location => {
                                            return location.location_state == labOrSpicemenCollectionState.incident_details.location_state
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(location => {
                                              return location.location_state == labOrSpicemenCollectionState.incident_details.location_state
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />

                                          )}
                                        />
                                      )}
                                      <div className="text-danger">{stateincidentoccuredError}</div>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Site Where Incident Occured <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          disabled={action == "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_name : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined" />
                                          )}

                                          value={locationData.filter(locationsite => {
                                            return locationsite.location?.location_name == labOrSpicemenCollectionState.incident_details.location?.location_name
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(locationsite => {
                                              return locationsite.location?.location_name == labOrSpicemenCollectionState.incident_details.location?.location_name
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined" x />
                                          )}
                                        />
                                      )}

                                      <div className="text-danger">{siteincidentoccuredError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >File Owner </label>
                                    <div>
                                      <TextField type="text" placeholder='File Owner' fullWidth
                                        value={labOrSpicemenCollectionState?.incident_details?.file_owner}
                                        onChange={(e) => handleStateChange("file_owner", capitalizeFirstLetter(e.target.value))}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Entered By</label>
                                    <div>
                                      <TextField type="text" placeholder='Entered By' fullWidth
                                        value={labOrSpicemenCollectionState?.incident_details?.entered_by}
                                        onChange={(e) => handleStateChange("entered_by", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="procedureDate"  >Entered Date</label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={labOrSpicemenCollectionState?.incident_details?.entered_date}
                                      onChange={(date) => {
                                        setLabOrSpicemenCollectionState({
                                          ...labOrSpicemenCollectionState,
                                          incident_details: {
                                            ...labOrSpicemenCollectionState.incident_details,
                                            entered_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />

                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate">
                                      Entered Time (Estern Time Zone)
                                    </label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "entered_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Name</label>
                                    <div>
                                      <TextField type="text" placeholder='Witness Name' fullWidth
                                        value={labOrSpicemenCollectionState?.incident_details?.witness_name}
                                        onChange={(e) => handleStateChange("witness_name", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Phone</label>
                                    <div>

                                      <TextField
                                        placeholder='Witness Phone'
                                        fullWidth
                                        disabled={action == "view"}
                                        type="text"
                                        value={labOrSpicemenCollectionState?.incident_details?.witness_phone}
                                        onChange={(event) => {
                                          const value = event.target.value;
                                          if (/^\d{0,10}$/.test(value)) {
                                            setPhoneNumber(value);
                                            handleStateChange("witness_phone", value);
                                          }
                                        }}
                                        inputProps={{
                                          pattern: '[0-9]*',
                                        }}
                                      />

                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label for="procedureDate" style={{ marginLeft: '15px' }}>Witness Address </label>
                                  <div
                                    class="col-md-12"
                                  >
                                    <TextField type="text" placeholder='Witness Address' fullWidth
                                      value={labOrSpicemenCollectionState?.incident_details?.witness_address}
                                      onChange={(e) => handleStateChange("witness_address", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="main-wrapper">
                  <div class="row">
                    <div >
                      <div class="panel panel-white">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >

                            <h4 class="panel-title">Special Additional Information</h4>
                          </AccordionSummary>
                          <AccordionDetails>

                            <div class="panel-body">
                              <div class="form-horizontal">

                                {/* Surgery Details */}

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="exampleInputPassword1" >Department<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={departmentData}
                                          getOptionLabel={(department) => department.department_name}
                                          disabled={action === "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("department_id", value ? value.department_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select department"
                                              variant="outlined"
                                            />
                                          )}
                                          value={departmentData.filter(department => {
                                            return department.department_id == labOrSpicemenCollectionState.special_additional_info.department_id
                                          })[0] || null}
                                          defaultValue={
                                            departmentData.filter(department => {
                                              return department.department_id == labOrSpicemenCollectionState.special_additional_info.department_id
                                            })[0] || {}
                                          }
                                        />
                                      ) : (
                                        <Autocomplete
                                          options={departmentData}
                                          getOptionLabel={(department) => department.department_name}
                                          disabled={action === "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("department_id", value ? value.department_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select department"
                                              variant="outlined"
                                            />
                                          )}
                                        />
                                      )}
                                    </div>
                                    <div className="text-danger">{departmentError}</div>

                                  </div>

                                  <div class="form-group col-md-6" >
                                    <label for="exampleInputPassword1" >Specific Incident Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          disabled={action == "view"}
                                          options={specificIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("specific_incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Specific Incident Type"
                                              fullWidth
                                            />
                                          )}
                                          value={specificIncidentTypeList.find(option => option.label === labOrSpicemenCollectionState.special_additional_info.specific_incident_type) || null}
                                        />) : (
                                        <Autocomplete
                                          disablePortal
                                          options={specificIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("specific_incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Specific Incident Type"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}
                                    </div>

                                    <div className="text-danger">{specificIncidentTypeError}</div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="procedureDate" >Date of Incident</label>

                                    <DatePicker
                                      className='form-control'
                                      fullWidth
                                      selected={labOrSpicemenCollectionState.special_additional_info?.incident_date}
                                      onChange={(date) => {
                                        setLabOrSpicemenCollectionState({
                                          ...labOrSpicemenCollectionState,
                                          special_additional_info: {
                                            ...labOrSpicemenCollectionState.special_additional_info,
                                            incident_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />

                                  </div>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="procedureDate" >Hospitalized On</label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      selected={labOrSpicemenCollectionState.special_additional_info?.hospitalized_on}
                                      onChange={(date) => {
                                        setLabOrSpicemenCollectionState({
                                          ...labOrSpicemenCollectionState,
                                          special_additional_info: {
                                            ...labOrSpicemenCollectionState.special_additional_info,
                                            hospitalized_on: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />

                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="risks" >Specimen Type</label>
                                    <div>
                                      <TextField type="text" fullWidth placeholder="Blood, urine, saliva, tissue, etc"
                                        value={labOrSpicemenCollectionState?.special_additional_info?.specimen_type}
                                        onChange={(e) => handleStateChange("specimen_type", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="risks" >Collection Site</label>
                                    <div >
                                      <TextField fullWidth type="text" placeholder="Specify the site where the specimen was collected"
                                        value={labOrSpicemenCollectionState?.special_additional_info?.collection_site}
                                        onChange={(e) => handleStateChange("collection_site", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="risks" >Collector's Name</label>
                                    <div >
                                      <TextField fullWidth type="text" placeholder="Full name and credentials of the person collecting the specimen"
                                        value={labOrSpicemenCollectionState?.special_additional_info?.collector_name}
                                        onChange={(e) => handleStateChange("collector_name", e.target.value)}
                                        disabled={action == "view"} />
                                    </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="exampleInputPassword1" >Reaction of Patient</label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          disabled={action == "view"}
                                          options={patientReactionList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("patient_reaction", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField fullWidth {...params}
                                              placeholder=' Please select patient reaction'
                                            />
                                          )}
                                          value={patientReactionList.find(option => option.label === labOrSpicemenCollectionState.special_additional_info.patient_reaction) || null}
                                        />) : (<Autocomplete
                                          disablePortal
                                          options={patientReactionList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("patient_reaction", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField fullWidth {...params}
                                              placeholder=' Please select patient reaction'
                                            />
                                          )}
                                        />)}
                                    </div>

                                  </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Patient Outcome</label>
                                    <div>
                                      <TextField type="text" placeholder='Patient Outcome' fullWidth
                                        value={labOrSpicemenCollectionState?.special_additional_info?.outcome}
                                        onChange={(e) => handleStateChange("outcome", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="allergies" >Sequelae</label>
                                    <div >
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          disabled={action == "view"}
                                          options={sequelaeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("sequelae", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Sequelae"
                                              fullWidth
                                            />
                                          )}
                                          value={sequelaeList.find(option => option.label === labOrSpicemenCollectionState.special_additional_info.sequelae) || null}
                                        />) : (<Autocomplete
                                          disablePortal
                                          options={sequelaeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("sequelae", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Sequelae"
                                              fullWidth
                                            />
                                          )}
                                        />)}
                                    </div>
                                  </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="medications" >Allergies or other relevant history</label>
                                    <div >
                                      <TextField fullWidth placeholder="Including medical history, liver / kidney problems, smoking, alcohol use etc"
                                        value={labOrSpicemenCollectionState?.special_additional_info?.relevant_history}
                                        onChange={(e) => handleStateChange("relevant_history", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="medications" >Other Information</label>
                                    <div >
                                      <TextField fullWidth type="text" placeholder="enter any other information about incident"
                                        value={labOrSpicemenCollectionState?.special_additional_info?.other_information}
                                        onChange={(e) => handleStateChange("other_information", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"   >Was a phyiscian directly involved in the Incident</label>
                                    <div style={{ marginTop: '10px' }}>

                                      <label className="col-md-3">
                                        <input type="radio" name="physician_involved" disabled={action == "view"} checked={labOrSpicemenCollectionState.special_additional_info?.physician_involved === true} onChange={() => handleStateChange("physician_involved", true)} /> Yes
                                      </label>
                                      <label className="col-md-3">
                                        <input type="radio" name="physician_involved" disabled={action == "view"} checked={labOrSpicemenCollectionState.special_additional_info?.physician_involved === false} onChange={() => handleStateChange("physician_involved", false)} /> No

                                      </label>
                                    </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Contributing Factors</label>
                                    <div >
                                      <TextField type="text" fullWidth placeholder="Enter Contributing Factors"
                                        value={labOrSpicemenCollectionState?.special_additional_info?.contributing_factors}
                                        onChange={(e) => handleStateChange("contributing_factors", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"   >Immediate Actions Taken</label>
                                    <div >
                                      <TextField type="text" fullWidth placeholder="Enter Immediate Actions Taken"
                                        value={labOrSpicemenCollectionState?.special_additional_info?.immediate_actions_taken}
                                        onChange={(e) => handleStateChange("immediate_actions_taken", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group col-md-6" >
                                    <label for="exampleInputPassword1" >Reported Incident Severity <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          disabled={action == "view"}
                                          options={reportedIncidentSeverityList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select reported incident severity"
                                              fullWidth
                                            />
                                          )}
                                          value={reportedIncidentSeverityList.find(option => option.label === labOrSpicemenCollectionState.special_additional_info.reported_incident_severity) || null}
                                        />) : (<Autocomplete
                                          disablePortal
                                          options={reportedIncidentSeverityList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select reported incident severity"
                                              fullWidth
                                            />
                                          )}
                                        />)}
                                    </div>
                                    <div className="text-danger">{reportedIncidentError}</div>
                                  </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Incident Description - use SBAR (Situation,Background, Assessment,
                                      Recommendation )<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text" fullWidth placeholder="Enter Incident Description..."
                                        value={labOrSpicemenCollectionState?.special_additional_info?.incident_description}
                                        onChange={(e) => handleStateChange("incident_description", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                      <div className="text-danger">{incidentdescriptionError}</div>
                                    </div>
                                  </div>


                                  <div className="form-group col-md-6" style={{ marginTop: '5px' }}>
                                    <label htmlFor="exampleInputPassword1">Would you like feedback of changes made following analysis of this incident report?</label>
                                    <div style={{ marginTop: '10px' }}>
                                      <label className="col-md-3">
                                        <input type="radio" name="feedback" disabled={action == "view"} checked={labOrSpicemenCollectionState?.special_additional_info?.feedback === true} onChange={() => handleStateChange("feedback", true)} /> Yes
                                      </label>
                                      <label className="col-md-3">
                                        <input type="radio" name="feedback" disabled={action == "view"} checked={labOrSpicemenCollectionState?.special_additional_info?.feedback === false} onChange={() => handleStateChange("feedback", false)} /> No

                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group col-md-6">
                                 <label htmlFor="procedureDate" style={{ textAlign: "start" }}>File Upload</label>
                                  <div>
                                    <input type="file" disabled={action == "view"} style={{ marginTop: "10px", color: 'transparent' }} multiple onChange={handleFileUpload} />
                                    {files.length > 0 ? (
                                      <ol>
                                        {files.map((file, index) => (
                                          <li disabled={action === "view"} key={index}>
                                            {file.name}
                                            <IconButton disabled={action === "view"} onClick={() => handleDeleteFile(index)} aria-label="delete">
                                              <DeleteIcon />
                                            </IconButton>
                                          </li>
                                        ))}
                                      </ol>
                                    ) : (
                                      <p>No files chosen</p>
                                    )}

                                  </div>
                                </div>

                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginLeft: "45%" }}>
                {canSubmit && (
                  <Button
                    disabled={action === "view"}
                    variant="contained"
                    type="submit"
                    style={{ height: "35px", width: "auto", fontSize: "15px", background: "#22baa0" }}
                    onClick={handleSubmit}
                  >
                    {editIncidentData.incident_id ? "Update" : "Submit"}
                  </Button>
                  )}
                </div>
              </form>
              <Snackbar
                open={showSuccessPopup}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <MuiAlert onClose={handleCloseSnackbar}
                  severity="success"
                  variant="filled"
                  sx={{ width: '100%' }}
                  style={{ width: '300px', backgroundColor: "whitesmoke", color: "green", height: '45px', fontSize: '12px' }}>
                  {snackbarMessage}
                </MuiAlert>
              </Snackbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LabSpecimenCollectionForm;