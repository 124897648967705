import {createSlice} from "@reduxjs/toolkit"
import { createSelector } from '@reduxjs/toolkit';

const selectCommon = (state) => state.commonStore;

export const commonSelectors = {
  selectBaererToken: createSelector(
    [selectCommon],
    (common) => common.baererToken
  ),
  selectLoginUserId: createSelector(
    [selectCommon],
    (common) => common.loginUserId
  ),
  selectUserInfo: createSelector(
    [selectCommon],
    (common) => common.userInfo
  ),
 
};

const initialState = {
  "general_incident_info": {
    "incident_type": 0,
    "patient_type": "",
    "injury_incurred": "",
    "equipment_involved": ""
  },
  "incident_details": {
    "first_name": "",
    "last_name": "",
    "gender": "",
    "dob": null,
    "address": "",
    "street": "",
    "city": "",
    "state": "",
    "country": "",
    "zipcode": ""
  },
  "incident_details": {
    "incident_date": new Date(),
    "incident_time": "",
    "incident_state": "",
    "incident_site": "",
    "file_owner": "",
    "entered_date": new Date(),
    "entered_time": "",
    "entered_by": "",
    "witness_name": "",
    "witness_address": "",
    "witness_phone": ""
  },

  "special_additional_info": {
    "department_id": 0,
    "incident_date": new Date(),
    "hospitalized_on": new Date(),
    "specimen_type": "",
    "collection_site": "",
    "collector_name": "",
    "patient_reaction": 0,
    "outcome": "",
    "sequelae": 0,
    "relevant_history": "",
    "other_information": "",
    "physician_involved": false,
    "contributing_factors": "",
    "immediate_actions_taken": "",
    "reported_incident_severity": 0,
    "incident_description": "",
    "feedback": false,
    "attachment": ""
  }
}


const labSpicemenSlice = createSlice({
  // name: "common",
  name: "labSpicemen",
  initialState: initialState,
 
})

export const commonActions = labSpicemenSlice.actions
export default labSpicemenSlice
