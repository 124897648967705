// import React, { useState, useEffect } from 'react';
// import { Doughnut } from 'react-chartjs-2';
// import axios from 'axios';
// import { apis } from '../../configuration/configurationAPI';
// import DatePicker from "react-datepicker";
// import { useSelector } from "react-redux";
// import { commonSelectors } from '../../utils/commonstore';

// import store from "../../utils/index";

// const dispatch = store.dispatch;
// let commonStore = store.getState().commonStore;
// store.subscribe(function () {
//     commonStore = store.getState().commonStore;
// });
// const DoughnutChart = () => {



//     const baererToken = useSelector(commonSelectors.selectBaererToken);
//     const loggedinId = useSelector(commonSelectors.selectLoginUserId);
//     const userInfo = useSelector(commonSelectors.selectUserInfo);
//     const [fdata, setFetchdata] = useState([]);
//     const [cdata, cFdata] = useState([]);
//     const [showFilters, setShowFilters] = useState(false);

//     const [fromDate, setFromDate] = useState(new Date("01-01-2020"));
//     const [toDate, setToDate] = useState(new Date());
//     const [data, setData] = useState([]);

//     const [chartData, setChartData] = useState({
//         labels: [],
//         datasets: [
//             {
//                 data: [],
//                 backgroundColor: ['#f0e67d', '#8B8883', '#e86b84', '#f0ac78', '#78baf0', '#f3f3f3', '#3f4754', '#3e8745', '#383820', '#999918', '#380334', '#6925cf', '#18d9b5', '#e0dae8','#999918', '#380334', '#6925cf', '#18d9b5', '#e0dae8'],
//             },
//         ],
//     });

//     const handleChartClick = (event, elements) => {
//         if (elements.length > 0) {
//             const clickedLabel = chartData.labels[elements[0].index];
//             switch (clickedLabel) {
//                 case 'patientBehaviour':
//                     window.location.href = '/PatientBehaviourReport';

//                     break;
//                 case 'generalLiability':
//                     window.location.href = '/GeneralLiability';

//                     break;
//                 case 'medicalCare':
//                     window.location.href = '/MedicalCareandTreatment';
//                     break;
//                 case 'safetySecurityFacility':
//                     window.location.href = '/SafetySecurityFacility';
//                     break;
//                     case 'feedback':
//                     window.location.href = '/Feedback';
//                     break;
//                 case 'medicalDevice':
//                     window.location.href = '/MedicalDeviceReporting';
//                     break;
//                 case 'labSpecimenCollection':
//                     window.location.href = '/LabSpecimenCollection';
//                     break;
//                 case 'patientExperience':
//                     window.location.href = '/PatientExperience';
//                     break;
//                 case 'sugeryProcedureConsent':
//                     window.location.href = '/SurgeryProcedureConsent';
//                     break;
//                 case 'Medication/Chemotherapy':
//                     window.location.href = '/MedicationChemotherapy';
//                     break;
//                 case 'unprofessionalBehaviour':
//                     window.location.href = '/UnprofessionalBehaviorReport';
//                     break;
//                 case 'diagnosticImage':
//                     window.location.href = '/DiagnosticImagingReport';
//                     break;
//                 case 'obstetrics':
//                     window.location.href = '/ObstetricsFormReport';
//                     break;
//                 case 'patientPrivacyAuthority':
//                     window.location.href = '/PatientPrivacyAuthorization';
//                     break;
//                 case 'airwaymanagement':
//                     window.location.href = '/Airwaymanagement';
//                     break;
//                     case 'bloodandbloodproducts':
//                     window.location.href = '/Bloodandbloodproducts';
//                     break;
//                     case 'adversedrug':
//                         window.location.href = '/Adversedrug';
//                         break;
//                         case 'anesthesia':
//                         window.location.href = '/Anesthesia';
//                         break;
//                         case 'Codingcomplications':
//                             window.location.href = '/Codingcomplications';
//                             break;
//                             case 'Fall':
//                             window.location.href = '/Fall';
//                             break;
//                 default:
//                     break;
//             }
//         }
//     };

//     function formatDateToDDMMYYYY(date) {
//         var day = date.getDate();
//         var month = date.getMonth() + 1;
//         var year = date.getFullYear();
//         var paddedDay = day < 10 ? '0' + day : day;
//         var paddedMonth = month < 10 ? '0' + month : month;
//         var formattedDate = '' + paddedDay + paddedMonth + year;
//         return formattedDate;
//     }


//     const fetchDataAndDisplayChart = async () => {
//         try {
//             const response = await axios.post(apis.base + apis.GET_INCIDENT_FILTER_INCIDENT_COUNT_REPORT_API, {
//                 "start_date": formatDateToDDMMYYYY(fromDate),
//                 "to_date": formatDateToDDMMYYYY(toDate),
//                 "based_on": "incident_type",
//                 "is_report": false,
//             }, {
//                 headers: {
//                     userid: loggedinId,
//                     Authorization: "Bearer " + baererToken

//                 }
//             });
//             console.log("report_response:",response)
//             const responseData = response.data;
            

//             const resp_labels = responseData.map((el) => el.incident_type.toUpperCase());
//             console.log("report_labels:",resp_labels)
//             const resp_labels_count = responseData.map((el) => JSON.parse(el.count));

//             setChartData((prevChartData) => ({
//                 ...prevChartData,
//                 labels: resp_labels,
//                 datasets: [
//                     {
//                         ...prevChartData.datasets[0],
//                         data: resp_labels_count,
//                     },
//                 ],
//             }));
//         } catch (error) {
//             console.error('Error fetching real-time data:', error);
//         }
//     };


//     useEffect(() => {
//         fetchDataAndDisplayChart();
//     }, [fromDate, toDate]);

//     const handleFilterButtonClick = () => {
//         setShowFilters(!showFilters);
//         fetchDataAndDisplayChart();
//     };

//     const options = {
//         cutoutPercentage: 70,
//         maintainAspectRatio: false,
//         plugins: {
//             legend: {
//                 display: true,
//                 position: 'right',
//                 labels: {
//                     boxWidth: 15,

//                 },
//             },
//         },
//         onClick: handleChartClick,
//     };


//     return (
//         <div>
//         <div>
//                 <button type="button" className="btn btn-success" onClick={handleFilterButtonClick} style={{ position: "relative", bottom: "40px", left: "200px" }}>
//                     Filters
//                 </button>

//                 {showFilters && (
                    
//                         <div style={{ position: "relative", bottom: "102px", left: "280px", }}>
//                             <div className="col-md-3">
//                                 <label htmlFor="toDate" className="form-label">From Date</label>
//                                 <DatePicker
//                                     id="toDate"
//                                     className="form-control"
//                                     selected={fromDate}
//                                     isClearable
//                                     placeholderText="MM/DD/YYYY"
//                                     showYearDropdown
//                                     scrollableYearDropdown
//                                     onChange={(date) => setFromDate(date)}
//                                     dateFormat="MM/dd/yyyy"
//                                 />
//                             </div>
//                             <div className="col-md-3">
//                                 <label htmlFor="toDate" className="form-label">To Date</label>
//                                 <DatePicker
//                                     id="toDate"
//                                     className="form-control"
//                                     selected={toDate}
//                                     isClearable
//                                     placeholderText="MM/DD/YYYY"
//                                     showYearDropdown
//                                     scrollableYearDropdown
//                                     onChange={(date) => setToDate(date)}
//                                     dateFormat="MM/dd/yyyy"
//                                 />
//                             </div>
//                         </div>
                    
//                 )}
//                    </div>
//                    <div>
//             <div style={{ position: "relative", height: "450px" }}>
//                 <Doughnut data={chartData} options={options} />
//             </div>
//         </div>
//         </div>
//     );
// };

// export default DoughnutChart;

import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import store from "../../utils/index";

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
    commonStore = store.getState().commonStore;
});

const DoughnutChart = () => {
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loggedinId = useSelector(commonSelectors.selectLoginUserId);
    const userInfo = useSelector(commonSelectors.selectUserInfo);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: ['#f0e67d', '#8B8883', '#e86b84', '#f0ac78', '#78baf0', '#f3f3f3', '#3f4754', '#3e8745', '#383820', '#999918', '#380334', '#6925cf', '#18d9b5', '#e0dae8','#999918', '#380334', '#6925cf', '#18d9b5', '#e0dae8'],
            },
        ],
    });
    const [showFilters, setShowFilters] = useState(false);
    const [fromDate, setFromDate] = useState(new Date("01-01-2020"));
    const [toDate, setToDate] = useState(new Date());
    const [fetchData, setFetchData] = useState(false);

    const formatDateToDDMMYYYY = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const paddedDay = day < 10 ? '0' + day : day;
        const paddedMonth = month < 10 ? '0' + month : month;
        return `${paddedDay}${paddedMonth}${year}`;
    };

    useEffect(() => {
        fetchDataAndDisplayChart();
    }, []);


    const fetchDataAndDisplayChart = async () => {
        try {
            const response = await axios.post(apis.base + apis.GET_INCIDENT_FILTER_INCIDENT_COUNT_REPORT_API, {
                "start_date": formatDateToDDMMYYYY(fromDate),
                "to_date": formatDateToDDMMYYYY(toDate),
                "based_on": "incident_type",
                "is_report": false,
            }, {
                headers: {
                    userid: loggedinId,
                    Authorization: `Bearer ${baererToken}`,
                }
            });
            const responseData = response.data;
            const labels = responseData.map(el => el.incident_type.toUpperCase());
            const counts = responseData.map(el => JSON.parse(el.count));

            setChartData({
                labels,
                datasets: [{
                    data: counts,
                    backgroundColor: ['#f0e67d', '#8B8883', '#e86b84', '#f0ac78', '#78baf0', '#f3f3f3', '#3f4754', '#3e8745', '#383820', '#999918', '#380334', '#6925cf', '#18d9b5', '#e0dae8', '#999918', '#380334', '#6925cf', '#18d9b5', '#e0dae8'],
                }]
            });
        } catch (error) {
            console.error('Error fetching real-time data:', error);
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchDataAndDisplayChart();
            setFetchData(false);
        }
    }, [fetchData, fromDate, toDate]);

    const handleFilterButtonClick = () => {
        setFetchData(true);
        setShowFilters(!showFilters);
    };

    const options = {
        cutoutPercentage: 70,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    boxWidth: 15,
                },
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const clickedLabel = chartData.labels[elements[0].index];
                const routes = {
                    'patientBehaviour': '/PatientBehaviourReport',
                    'generalLiability': '/GeneralLiability',
                    'medicalCare': '/MedicalCareandTreatment',
                    'safetySecurityFacility': '/SafetySecurityFacility',
                    'feedback': '/Feedback',
                    'medicalDevice': '/MedicalDeviceReporting',
                    'labSpecimenCollection': '/LabSpecimenCollection',
                    'patientExperience': '/PatientExperience',
                    'sugeryProcedureConsent': '/SurgeryProcedureConsent',
                    'Medication/Chemotherapy': '/MedicationChemotherapy',
                    'unprofessionalBehaviour': '/UnprofessionalBehaviorReport',
                    'diagnosticImage': '/DiagnosticImagingReport',
                    'obstetrics': '/ObstetricsFormReport',
                    'patientPrivacyAuthority': '/PatientPrivacyAuthorization',
                    'airwaymanagement': '/Airwaymanagement',
                    'bloodandbloodproducts': '/Bloodandbloodproducts',
                    'adversedrug': '/Adversedrug',
                    'anesthesia': '/Anesthesia',
                    'Codingcomplications': '/Codingcomplications',
                    'Fall': '/Fall',
                };
                if (routes[clickedLabel]) {
                    window.location.href = routes[clickedLabel];
                }
            }
        }
    };

    return (
        <div>
            <button type="button" className="btn btn-success" onClick={handleFilterButtonClick} style={{ position: "relative", bottom: "12px", left: "280px" }}>
                Apply Filters
            </button>
            <div style={{ position: "relative", bottom: "113px", left: "180px" }}>
                <div className="col-md-4">
                    <label htmlFor="fromDate" className="form-label">From Date</label>
                    <DatePicker
                        id="fromDate"
                        className="form-control"
                        selected={fromDate}
                        // isClearable
                        placeholderText="MM/DD/YYYY"
                        showYearDropdown
                        scrollableYearDropdown
                        onChange={(date) => setFromDate(date)}
                        dateFormat="MM/dd/yyyy"
                    />
                </div>
                <div className="col-md-4">
                    <label htmlFor="toDate" className="form-label">To Date</label>
                    <DatePicker
                        id="toDate"
                        className="form-control"
                        selected={toDate}
                        // isClearable
                        placeholderText="MM/DD/YYYY"
                        showYearDropdown
                        scrollableYearDropdown
                        onChange={(date) => setToDate(date)}
                        dateFormat="MM/dd/yyyy"
                    />
                </div>
            </div>
            <div style={{ position: "relative", height: "450px" }}>
                <Doughnut data={chartData} options={options} />
            </div>
        </div>
    );
};

export default DoughnutChart;

