import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { apis } from "../../configuration/configurationAPI";
import { Snackbar, Alert, ClickAwayListener } from "@mui/material";
import { routers } from "../../configuration/configurationUI";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore'; 

function LocationEdit() {

    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId); 

    const { locationId } = useParams();
    // console.log("ssssss", locationId)
    const Navigate = useNavigate();

    const [location, setLocation] = useState({
        location_name: "",
        location_id: ""
    });

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success"
    });
    const [errors, setErrors] = useState({
        location_name: "",
        location_id: ""
    });

    useEffect(() => {
        if (locationId) {
            axios.get(apis.base + apis.GET_LOCATIONID_API + locationId,{
                headers: {
                    userid:loginUserId,
                    Authorization:"Bearer "+ baererToken
                }
            })
                .then((response) => {
                    setLocation(response.data);
                    console.log(response.data,"JJJJKKKKK")
                })
                .catch((error) => {
                    console.error("Error fetching location details:", error);
                    setSnackbar({
                        open: true,
                        message: "Failed to fetch location details",
                        severity: "error"
                    });
                });
    }
},[locationId]);
const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading,setLoading] = useState('')
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLocation({ ...location, [name]: value });
    };
    const validateForm = () => {
        let isValid = true;
        let errorsCopy = { ...errors };

        if (!location.location_name.match(/^[a-zA-Z]{3,}$/)) {
            errorsCopy.location_name = "location name must contain only alphabets and be at least 3 characters long";
            isValid = false;
        } else {
            errorsCopy.location_name = "";
        }

        if (!location.location_id) {
            errorsCopy.location_id = "Please select a location";
            isValid = false;
        } else {
            errorsCopy.location_id = "";
        }

        setErrors(errorsCopy);
        return isValid;
    };
    const callbackSubmit = (res) => {

            };
    const handleSubmit = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setLoading(true);

        let errors = {};
        let isValid = true;

        if (!location.location_name.trim()) {
            errors.location_name = 'Location name is required';
            isValid = false;
        } else if (!/^[A-Za-z\s]+$/.test(location.location_name)) {
            errors.location_name = 'Location name should contain only alphabets';
            isValid = false;
        }

        if (!location.address.trim()) {
            errors.address = 'Address is required';
            isValid = false;
        } else if (location.address.trim().length < 4) {
            errors.address = 'Address must be at least 4 letters long';
            isValid = false;
        }

        if (!isValid) {
            setErrors(errors);
            setLoading(false);
            return;
        }

        const updatedData = {
            location_name: location.location_name,
            address: location.address,
            state:location.state,
        };

        axios.put(apis.base + apis.PUT_LOCATION_API + locationId, updatedData,{
            headers: {
                userid:loginUserId,
                Authorization:"Bearer "+ baererToken
            }
        })
            .then(succresp => {
                callbackSubmit(succresp);
                setSnackbar({
                    open: true,
                    message: "Location Updated SucessFully",
                    severity: "success",
                });
            })
            .catch(errorresp => {
                console.error("Error updating location:", errorresp);
                setSnackbar({
                    open: true,
                    message: "Failed to update location",
                    severity: "error",
                });
            });
    };



    const handleSnackbarClose = () => {
        Navigate("/location/list")
        setSnackbar({ ...snackbar, open: false });
    };   

    return (
                <div className="page-content">
                    <div className="page-inner">
                        <div className="page-title">
                            <h3 style={{ color: "black" }}>Locations</h3>
                            <div className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li><Link to="/Dashboard">Home</Link></li>
                                    <li><Link to="">User management</Link></li>
                                    <li><Link to="/location/list">Locations</Link></li>
                                    <li>Locations Edit</li>
                                </ol>
                            </div>
                        </div>
                        <div className="panel panel-white" style={{ alignItems: 'center', marginLeft: '350px', maxWidth: '50%' }}>
                            <ClickAwayListener onClickAway={() => { }}>
                                <Snackbar
                                    open={snackbar.open}
                                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                                    style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                >
                                    <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}  style={{ width: '300px', color: "black", height: '45px', fontSize: '12px' }}>
                                        {snackbar.message}
                                    </Alert>
                                </Snackbar>
                            </ClickAwayListener>
        
                            <div style={{marginTop:"40px"}}>
                            <div className="panel-heading clearfix" style={{paddingTop:'46px'}} >
                                <h4 className="panel-title">Edit Location</h4>
                            </div>
                            <div className="panel-body" >
                                <div >
                                    <div className="form-group" style={{paddingBottom:'10px'}}>
                                        <label htmlFor="exampleInputEmail1" style={{marginLeft:'10px'}}>Location Name</label>
                                        <TextField
                                            type="text"
                                            className="form-control"
                                            placeholder="Location Name"
                                            value={location.location_name }
                                            onChange={(e) => setLocation({ ...location, location_name: e.target.value })}
                                        />
                                       
                                    </div>
                                    {errors.location_name && <p className="text-danger" style={{marginLeft:'10px'}}>{errors.location_name}</p>}
                                    <div className="form-group" style={{paddingBottom:'10px'}}>
                                        <label htmlFor="exampleInputEmail1" style={{marginLeft:'10px'}}>State</label>
                                        <TextField
                                            type="text"
                                            className="form-control"
                                            placeholder="State"
                                            value={location.state || ""}
                                            onChange={(e) => setLocation({ ...location, state: e.target.value })}
                                        />
                                       
                                    </div>
                                    {errors.state && <p className="text-danger" style={{marginLeft:'10px'}}>{errors.state}</p>}
        
        
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" style={{marginLeft:'10px'}}>Address</label>
                                        <TextField
                                            type="text"
                                            className="form-control"
                                            placeholder="Address"
                                            value={location.address || ""}
                                            onChange={(e) => setLocation({ ...location, address: e.target.value })}
                                        />
                                        
                                    </div>
                                    {errors.address && <p className="text-danger" style={{marginLeft:'1px',padding:'10px'}}>{errors.address}</p>}
                                    <div className="panel-body" style={{ display: 'flex',marginLeft:'150px'}}>
                                       <Button type="submit" className="btn btn-primary" style={{ marginLeft: '30px', width: '65px',background: '#22baa0', color: 'white',borderRadius:'5px',height:'40px',width:'70px' }} onClick={handleSubmit}>Submit</Button>
                                        {/* <Link to={routers.Locations}> <Button type="button" className="btn btn-success" style={{ marginLeft: '70px', borderRadius: '2px', background: '#34425a', color: 'white' }}>Cancel</Button></Link> */}
                                        <Link to={routers.Locations}><Button type="button" className="btn btn-success" style={{ marginLeft: '70px', background: '#34425a',borderRadius:'5px',height:'40px',width:'70px' }} >Cancel</Button></Link>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        


export default LocationEdit;
