import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { commonSelectors } from '../../utils/commonstore';

import store from "../../utils/index";
import { Padding } from '@mui/icons-material';

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
    commonStore = store.getState().commonStore;
});
const DoughnutChart = () => {
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loggedinId = useSelector(commonSelectors.selectLoginUserId);
    const userInfo = useSelector(commonSelectors.selectUserInfo);
    const [fdata, setFetchdata] = useState([]);
    const [cdata, cFdata] = useState([]);
    const [showFilters, setShowFilters] = useState(false);

    const [fromDate, setFromDate] = useState(new Date("01-01-2020"));
    const [toDate, setToDate] = useState(new Date());
    const [data, setData] = useState([]);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: ['#f0e67d', '#8B8883', '#e86b84', '#f0ac78', '#78baf0', '#f3f3f3', '#3f4754', '#3e8745', '#383820', '#999918', '#380334', '#6925cf', '#18d9b5', '#e0dae8','#33FF9C','#F1C2D1','#D1A8CC','#B3F6F8','#A8AF8F','#F7BD9D'],
            },
        ],
    });

    const handleChartClick = (event, elements) => {
        if (elements.length > 0) {
            const clickedLabel = chartData.labels[elements[0].index];
            switch (clickedLabel) {
                case 'patientBehaviour':
                    window.location.href = '/PatientBehaviourReport';

                    break;
                case 'generalLiability':
                    window.location.href = '/GeneralLiability';

                    break;
                case 'medicalCare':
                    window.location.href = '/MedicalCareandTreatment';
                    break;
                case 'safetySecurityFacility':
                    window.location.href = '/SafetySecurityFacility';
                    break;
                case 'medicalDevice':
                    window.location.href = '/MedicalDeviceReporting';
                    break;
                case 'labSpecimenCollection':
                    window.location.href = '/LabSpecimenCollection';
                    break;
                case 'patientExperience':
                    window.location.href = '/PatientExperience';
                    break;
                case 'sugeryProcedureConsent':
                    window.location.href = '/SurgeryProcedureConsent';
                    break;
                case 'Medication/Chemotherapy':
                    window.location.href = '/MedicationChemotherapy';
                    break;
                case 'unprofessionalBehaviour':
                    window.location.href = '/UnprofessionalBehaviorReport';
                    break;
                case 'diagnosticImage':
                    window.location.href = '/DiagnosticImagingReport';
                    break;
                case 'obstetrics':
                    window.location.href = '/ObstetricsFormReport';
                    break;
                case 'patientPrivacyAuthority':
                    window.location.href = '/PatientPrivacyAuthorization';
                    break;
                case 'airwaymanagement':
                    window.location.href = '/Airwaymanagement';
                    break;
                    case 'bloodandbloodproducts':
                    window.location.href = '/Bloodandbloodproducts';
                    break;
                    case 'adversedrug':
                        window.location.href = '/Adversedrug';
                        break;
                        case 'anesthesia':
                        window.location.href = '/Anesthesia';
                        break;
                        case 'Codingcomplications':
                            window.location.href = '/Codingcomplications';
                            break;
                            case 'Fall':
                            window.location.href = '/Fall';
                            break;
                            case 'feedback':
                    window.location.href = '/Feedback';
                    break;
                default:
                    break;
            }
        }
    };

    function formatDateToDDMMYYYY(date) {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        var paddedDay = day < 10 ? '0' + day : day;
        var paddedMonth = month < 10 ? '0' + month : month;

        var formattedDate = '' + paddedDay + paddedMonth + year;

        return formattedDate;
    }




    const fetchDataAndDisplayChart = async () => {
        try {
            const response = await axios.post(apis.base + apis.GET_INCIDENT_FILTER_INCIDENT_COUNT_REPORT_API, {
                "start_date": formatDateToDDMMYYYY(fromDate),
                "to_date": formatDateToDDMMYYYY(toDate),
                "based_on": "incident_type",
                "is_report": false,
            }, {
                headers: {
                    userid: loggedinId,
                    Authorization: "Bearer " + baererToken

                }
            });

            const responseData = response.data;

            const resp_labels = responseData.map((el) => el.incident_type.toUpperCase());
            const resp_labels_count = responseData.map((el) => JSON.parse(el.count));

            setChartData((prevChartData) => ({
                ...prevChartData,
                labels: resp_labels,
                datasets: [
                    {
                        ...prevChartData.datasets[0],
                        data: resp_labels_count,
                    },
                ],
            }));
        } catch (error) {
            console.error('Error fetching real-time data:', error);
        }
    };


    useEffect(() => {
        fetchDataAndDisplayChart();
    }, [fromDate, toDate]);

    const handleFilterButtonClick = () => {
        setShowFilters(!showFilters);
        fetchDataAndDisplayChart();
    };

    const options = {
        cutoutPercentage: 100,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'right',
                
                labels: {
                    padding:4,
                    boxWidth: 15,

                },
            },
        },
        onClick: handleChartClick,
    };


    return (
        <div>
            <div style={{height:"400px"}}>
                <Doughnut data={chartData} options={options} />
            </div>
        </div>
    );
};

export default DoughnutChart;
