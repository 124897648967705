import React from "react";
import { useNavigate} from "react-router";
import { Button } from '@mui/material';

const UnauthAcess = () => {
const navigate = useNavigate();

  const moveToDashboard = () => {
        navigate("/dashboard")
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999,
      }}
    >
      
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: "url('/assets/images/auth2.jpg')", 
          backgroundSize: "cover",
         
        }}
      />

      <div
        style={{
          position: "absolute",
          top: "68%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: "20px",
          borderRadius: "5px",
          textAlign: "center",
          zIndex: 9999,
          height:"50%"
        }}
      >
        <h2>Token Expired!</h2>
        <p style={{fontFamily:"fantasy"}}>Your session has expired. Please log in again or move to the dashboard screen.</p>
        <Button variant="contained" onClick={moveToDashboard}> Move to Dashboard </Button>
      </div>
    </div>
  );
};

export default UnauthAcess;
