import {createSlice} from "@reduxjs/toolkit"
import { createSelector } from '@reduxjs/toolkit';

const selectCommon = (state) => state.commonStore;

export const commonSelectors = {
  selectBaererToken: createSelector(
    [selectCommon],
    (common) => common.baererToken
  ),
  selectLoginUserId: createSelector(
    [selectCommon],
    (common) => common.loginUserId
  ),
  selectUserInfo: createSelector(
    [selectCommon],
    (common) => common.userInfo
  ),
 
};

const initialState = {
  "person_effected": {
    "patient_type": 0,
    "patient_last_name": "",
    "patient_first_name": "",
    "patient_mrn": "",
    "patient_gender": "",
    "patient_dob": null,
    "patient_address": "",
    "patient_city": "",
    "patient_state": "",
    "patient_zipcode": "",
    "patient_country": "",
    "patient_phone": "",
    "patient_work_phone": "",
    "patient_insurance": "",
    "patient_insurance_if_other": ""
  },
  "communicated_by": {
    "last_name": "",
    "first_name": "",
    "gender": "",
    "address": "",
    "city": "",
    "state": "",
    "zipcode": "",
    "country": "",
    "phone": "",
    "work_phone": "",
    "admission_date": new Date(),
    "insurance": 0,
    "insurance_if_other": "",
    "patient_relation": 0
  },
  "feedback_details": {
    "file_id": "",
    "department_id": 0,
    "entered_date": new Date(),
    "entered_time": "",
    "entered_by": "",
    "received_date": null,
    "method": 0,
    "reported_incident_severity": 0,
    "file_owner": "",
    "referral_Source": "",
    "desired_outcome": "",
    "issue_list": 0,
    "attachment": ""
  }
}


const feedBackSlice = createSlice({
  // name: "common",
  name: "feedBack",
  initialState: initialState,
 
})

export const commonActions = feedBackSlice.actions
export default feedBackSlice
