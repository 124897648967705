import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import { routers } from "../../configuration/configurationUI";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Button, TextField } from "@mui/material";
import { Modal, Snackbar, Alert } from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';

function DepartmentAdd() {
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);
    const [location, setLocation] = useState([])
    const [data, setData] = useState({
        "department_id": 0,
        "location_id": 0,
        "department_name": "",
        "location_name": "",
        "created_by":loginUserId,
        "updated_by":loginUserId,
    });
    const Navigate=useNavigate();
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success", // Can be "error", "warning", "info", "success"
    });
    const [errors, setErrors] = useState({
        department_name: '',
        location_name: ''
    });
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        axios.get(apis.base + apis.GET_LOCATION_API , {
            headers: {
                userid:loginUserId,
                Authorization:"Bearer "+ baererToken
            }
        })
            .then((response) => {
                setLocation(response.data);
            })
            .catch((error) => {
                console.log("Error fetching locations:", error);
            });
    }, []);


    const validateForm = () => {
        let valid = true;
        let errors = {};

        if (!data.department_name.trim()) {
            errors.department_name = "Department name is required";
            valid = false;
        } else if (!/^[a-zA-Z]+$/.test(data.department_name.trim())) {
            errors.department_name = "Department name can only contain alphabets and numbers";
            valid = false;
        }

        if (!data.location_name) {
            errors.location_name = "Location is required";
            valid = false;
        }

        setErrors(errors);
        return valid;
    };

    const handleSubmit = (e) => {
        e.preventDefault(); 

        if (!validateForm()) {
            return;
        }

        setLoading(true);
        setSuccessMessage("");


        axios.post(apis.base + apis.POST_DEPARTMENT_API, data, {
            headers: {
                userid:loginUserId,
                Authorization:"Bearer "+ baererToken
            }
        })
            .then((response) => {
                setData(response.data)
                // setSuccessMessage("Department added successfully!");
                setData({
                    "department_id": -1,
                    "location_id": -1,
                    "department_name": "",
                    "location_name": "",
                });
                setErrors({
                    department_name: '',
                    location_name: ''
                });
                setSnackbar({
                    open: true,
                    message: 'Department added successfully',
                    severity: 'success',
                });
                console.log('Department added:', response.data);
            })
            .catch((error) => {
                console.log(error);
                setSnackbar({
                    open: true,
                    message: 'Failed to added Department',
                    severity: 'error',
                });
                console.error("Error submit the Department:", error);

            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSnackbarClose = () => {
        Navigate("/department/list")
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <div>
            <div className="page-inner">
                <div className="page-title">
                    <h3 style={{ color: "black" }}>Departments</h3>
                    <div className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li><Link to="/Dashboard">Home</Link></li>
                            <li><Link to="/department/list">Departments</Link></li>
                            <li>Department Add</li>
                        </ol>
                    </div>
                </div>
                <div className="panel panel-white" style={{ alignItems: 'center', marginLeft: '400px', maxWidth: '90%' }}>
                    <div id="main-wrapper">
                        
                        <div className="col-md-8" >
                            <div className="panel panel-white">
                                <div className="panel-heading clearfix" style={{ marginBottom: "-30px" }}>
                                    <h4 className="panel-title">Add Department</h4>
                                </div>
                                <div className="panel-body">
                                    {/* {successMessage && <div className="alert alert-success">{successMessage}</div>} */}
                                    <ClickAwayListener onClickAway={() => { }}>
                                        <Snackbar
                                            open={snackbar.open}
                                            onClose={() => setSnackbar({ ...snackbar, open: false })}
                                            style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                        >
                                            <Alert onClose={handleSnackbarClose} severity={snackbar.severity}  sx={{ width: '100%' }} variant="filled"  style={{ width: '300px',color:"white", height: '45px', fontSize: '12px' }}>
                                                {snackbar.message}
                                            </Alert>
                                        </Snackbar>
                                    </ClickAwayListener>
                                    <form onSubmit={handleSubmit}>
                                        <div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1" style={{ paddingLeft: '10px' }}>Department Name</label>
                                                <TextField
                                                    onChange={(e) => {
                                                        setData({ ...data, department_name: e.target.value });
                                                    }}

                                                    className="form-control" placeholder="Department Name" />

                                            </div>
                                            {errors.department_name && <div className="text-danger" style={{marginLeft:'10px',padding:'10px'}}>{errors.department_name}</div>}
                                            <label htmlFor="exampleInputEmail1" style={{ padding: '10px' }}>Location</label>
                                            <div className="form-group" style={{ paddingLeft: '10px' }}>

                                                {/* <select
                                                    value={data.location_name}
                                                    onChange={(e) => {
                                                        const selectedLocation = location.find(loc => loc.location_name === e.target.value);
                                                        setData({ ...data, location_id: selectedLocation ? selectedLocation.location_id : -1, location_name: e.target.value });
                                                    }}
                                                    style={{ fontSize: "11px", border: "1px solid #ccc", width: '100%', height: '35px' }}
                                                >
                                                    <option value="">Select Location</option>
                                                    {location.map((location) => (
                                                        <option key={location.location_id} value={location.location_name}>{location.location_name}</option>
                                                    ))}
                                                </select> */}
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={data.location_name}
                                                        onChange={(e) => {
                                                            const selectedLocation = location.find(loc => loc.location_name === e.target.value);
                                                            setData({ ...data, location_id: selectedLocation ? selectedLocation.location_id : -1, location_name: e.target.value });
                                                        }}
                                                        style={{ width: '98%', height: '45px' }}
                                                    >
                                                        <MenuItem disabled>Select Location</MenuItem>
                                                        {location.map((location) => (
                                                            <MenuItem key={location.location_id} value={location.location_name}>{location.location_name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {errors.location_name && <div className="text-danger" style={{padding:'10px'}}>{errors.location_name}</div>}

                                            </div>

                                            <div className="panel-body" style={{ display: 'flex' }}>
                                                <div>
                                                    <Link to={routers.Departments}> <Button type="button" className="btn btn-primary" style={{ marginLeft: '30px', width: '65px', background: '#22baa0', color: 'white' }} onClick={handleSubmit}>Submit</Button></Link>
                                                </div>
                                                <div>
                                                    <Link to={routers.Departments}><Button type="button" className="btn btn-success" style={{ marginLeft: '70px', background: '#34425a', color: 'white' }}>Cancel</Button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DepartmentAdd;
