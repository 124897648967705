import { Router } from "./route/Routers";

function App() {
  return (
    <div>

      <Router />

    </div>
  );
}

export default App;
