import React from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import { apis } from '../../configuration/configurationAPI';


const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});
const LocationExportExcel = () => {
  const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);
  const exportData = async (format) => {
    try {

      const headers = {
        userid: loginUserId,
        Authorization:"Bearer "+ baererToken,
        format:"excel"

      };

      const response = await axios.get(apis.base + apis.GET_LOCATION_EXPORT_EXCEL, {
        headers: headers,
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Location.xlsx');
      document.body.appendChild(link);
      link.click();


      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  return (
 
      
      <div style={{paddingBottom:"4px"}}>
        <Button variant="contained" onClick={() => exportData('excel')}>Export as Excel</Button>
        
      </div>
   
  );
};

export default LocationExportExcel;
