import { configureStore } from "@reduxjs/toolkit"
import commonSlice from "./commonstore";
import adverseDrugSlice from "./AdverseDrugReaction";
import airwayManagementSlice from "./AirwayManagement";
import bloodProductsSlice from "./BloodAndBloodProducts";
import codingCompicationSlice from "./CodingComplication";
import diagnosticImagingSlice from "./DiagnosticImaging";
import fallSlice from "./Fall";
import feedBackSlice from "./Feedback";
import labSpicemenSlice from "./LabOrSpicemen";
import medicalCareSlice from "./MedicalCareAndTreatment";
import medicalDeviceReportingSlice from "./MedicalDeviceReporting";
import medicationChemoSlice from "./MedicationOrChemotherapy";
import obstetricsSlice from "./ObstetricsForm";
import patientBehaviourSlice from "./PatientBehaviour";
import patientPrivacyAuthSlice from "./PatientPrivacyAuthorization";
import restraintsSlice from "./Restraints";
import safetySecritySlice from "./SafetySecurityFacility";
import surgeryProcedureSlice from "./SurgeryProcedureConsent";
import unproffessionalbehaviourSlice from "./UnproffessionalBehaviour";


const store = configureStore({
    reducer: {
        commonStore : commonSlice.reducer,
        adverseDrug : adverseDrugSlice.reducer,
        airwayManagement : airwayManagementSlice.reducer,
        bloodAndBloodProducts : bloodProductsSlice.reducer,
        codingComplication : codingCompicationSlice.reducer,
        diagnosticImaging : diagnosticImagingSlice.reducer,
        fall : fallSlice.reducer,
        feedBack : feedBackSlice.reducer,
        labSpicemen : labSpicemenSlice.reducer,
        medicalCare : medicalCareSlice.reducer,
        medicalDeviceReporting : medicalDeviceReportingSlice.reducer,
        medicationChemo : medicationChemoSlice.reducer,
        obstetrics : obstetricsSlice.reducer,
        patientBehaviour : patientBehaviourSlice.reducer,
        patientPrivacyAuth : patientPrivacyAuthSlice.reducer,
        restraints : restraintsSlice.reducer,
        safetySecrity : safetySecritySlice.reducer,
        surgeryProcedure : surgeryProcedureSlice.reducer,
        unproffessionalbehaviour: unproffessionalbehaviourSlice.reducer

    }
})

export default store;