import React from "react";
import BarGraph1 from "../../Components/charts/bargraph";
import Linegraph from "../../Components/charts/Linegraph";
import DoughnutChart from "../../Components/charts/Doughnutchart";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import { useNavigate } from "react-router-dom";
import GroupedBarChart from "../../Components/charts/GroupedChart";
import { useSelector } from "react-redux";
import { commonSelectors } from '../../utils/commonstore';
import PieCharts1 from "../../Components/charts/piecharts1";
import Scatter1 from "../../Components/charts/scatter1";
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core'

function Reports() {
  
    const [selectedChart, setSelectedChart] = useState('Doughnut');
    const handleChartChange = (event) => {
        setSelectedChart(event.target.value);
    };
    const renderChart = () => {
        switch (selectedChart) {
            case 'Doughnut':
                return <DoughnutChart />;
            case 'Scatter':
                return <Scatter1 />;
            case 'PieCharts':
                return <PieCharts1 />;
            default:
                return null;
        }
    };

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [filterModel, setFilterModel] = useState({ items: [] });



    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const accessInfo = useSelector(commonSelectors.selectAccessInfo);

    const loginUserId = useSelector(commonSelectors.selectLoginUserId);

    const [data, setData] = useState([]);


    React.useEffect(() => {
        axios.get(apis.base + apis.GET_INCIDENTS_ALL_API, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            }
        }).then((el) => {
            setData(el.data)
        })
    }, [])

    React.useEffect(() => {
        axios.get(apis.base + apis.GET_INCIDENTS_ALL_API, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            }
        }).then((response) => {
            const incidentWithIds = response.data.map((incident, index) => ({
                ...incident,
                id: index + 1,
                incident_type: incident.spec.general_incident_info.incident_type || "", 
                last_name: incident.spec.demographics_info.last_name || "", 
                first_name: incident.spec.demographics_info.first_name || "",
                incident_date: incident.spec.incident_details.incident_date || "", 
                reported_incident_severity: incident.spec.special_additional_info.reported_incident_severity || "", 
            }));
            setRows(incidentWithIds);
            setLoading(false);
        }).catch((error) => {
            setError(error.message);
            setLoading(false);
        });
    }, []);

    const workflows=["1000","1001","1010","1011","1100","1101","1110","1111"]

    const columns = [
        {
            field: 'incident_id', headerName: <strong> Incident Id</strong>, width: 150,
            renderCell: (params) => (
                <div>
                    {workflows.includes(accessInfo?.workflow) &&(
                <Link to={`/workflowactions/${params.row.incident_id}`}>{params.row.incident_id}</Link>
                    )}
                </div>
            )
        },
        {
            field: 'last_name',
            headerName: <strong>Patient Name</strong>,
            width: 150,
            valueGetter: (params) => {
                const lastName = params.row.last_name || '';
                const firstName = params.row.first_name || '';
                return `${lastName}${firstName ? ' ' + firstName : ''}`;
            }
        },
        {
            field: ' incident_date',
            headerName: <strong>Incident Date</strong>,
            width: 200,
            valueGetter: (params) => new Date(params.row.updated_timestamp).toLocaleString('en-US')
        },

        { field: 'reported_incident_severity', headerName: <strong>Severity</strong>, width: 200 },
        { field: 'department_name', headerName: <strong>Department</strong>, width: 200 },
        { field: 'incident_type', headerName: <strong>Incident Type</strong>, width: 250 },
    ];

    const sortedData = [...rows].sort((a, b) => b.incident_id - a.incident_id);

    return (
        <div>
            <div className="page-content">
                <div className="page-inner">
                    <div className="panel panel-white">
                        <div id="main-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="col-md-6">
                                        <div className="visitors-chart">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">Incident by Type</h4>
                                                <div>
                                                    <div className="custom-radio-group col-md-3" style={{ position: "absolute", marginTop: "30px", marginLeft: "-60px", width: "270px" }}>
                                                        <RadioGroup
                                                            value={selectedChart}
                                                            onChange={handleChartChange}
                                                            style={{ width: "100%", flexDirection: 'row' }}
                                                        >
                                                            <FormControlLabel
                                                                value="Doughnut"
                                                                control={<Radio />}
                                                                label="Doughnut"
                                                                labelPlacement="end"
                                                                style={{ fontSize: "1.1rem" }}
                                                            />
                                                            <FormControlLabel
                                                                value="Scatter"
                                                                control={<Radio />}
                                                                label="Scatter"
                                                                labelPlacement="end"
                                                                style={{ fontSize: "1.1rem" }}
                                                            />
                                                            <FormControlLabel
                                                                value="PieCharts"
                                                                control={<Radio />}
                                                                label="Pie Charts"
                                                                labelPlacement="end"
                                                                style={{ fontSize: "1.1rem" }}
                                                            />
                                                        </RadioGroup>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="panel-body">
                                                <div id="flotchart1">{renderChart()}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="visitors-chart">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">Incident By Year</h4>
                                            </div>
                                            <div className="panel-body">
                                                <div id="flotchart1"><BarGraph1 /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group" >
                                        <div className="col-md-12" style={{ paddingTop: "100px" }}>
                                            <div className="col-sm-6">
                                                <div className="visitors-chart">
                                                    <div className="panel-heading">
                                                        <h4 className="panel-title">Incident By Department</h4>
                                                    </div>
                                                    <div className="panel-body">
                                                        <div id="flotchart1"><Linegraph /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="visitors-chart">
                                                    <div className="panel-heading">
                                                        <h4 className="panel-title">Incident By Severity</h4>
                                                    </div>
                                                    <div className="panel-body">
                                                        <div id="flotchart1">
                                                            <GroupedBarChart />
                                                            {/* <ScatterPlot /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="main-wrapper">
                        <div className="row m-t-md">
                            <div className="col-md-12">
                                <div className="mailbox-content" style={{ overflow: 'auto' }}>
                                    <div style={{ height: "100%", width: '100%', paddingTop: "20px" }}>
                                        {error && <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>{error}</p>}
                                        {loading && <p style={{ textAlign: "center", fontSize: "15px" }}>Loading...</p>}
                                        {!loading && !error && (
                                            <DataGrid
                                                rows={sortedData}
                                                columns={columns}
                                                pageSize={5}
                                                rowsPerPageOptions={[10, 20, 30]}
                                                classes={{
                                                    overlayWrapper: 'customOverlayWrapper'
                                                }}
                                                sx={{
                                                    "& .customOverlayWrapper ": {
                                                        height: "63px !important"
                                                    },
                                                    fontWeight: 'fontWeightLight',
                                                    mx: 0.5,
                                                    fontSize: 14, fontFamily: "sans-serif",
                                                    boxShadow: 2
                                                }}
                                                components={{
                                                    Toolbar: () => (
                                                        <GridToolbarQuickFilter
                                                            classes={{
                                                                toolbarQuickFilter: 'customtoolbarQuickFilter'
                                                            }}
                                                            sx={{
                                                                width: '35% !important',
                                                                position: 'absolute !important',
                                                                right: 400,
                                                                top: '-30px !important'
                                                            }}

                                                        />
                                                    ),
                                                    NoRowsOverlay: () => (
                                                        <div
                                                            style={{
                                                                position: 'sticky',
                                                                top: '50%',
                                                                transform: 'translate(-50%, 0%)',
                                                                textAlign: 'center',
                                                                marginLeft: '45%'
                                                            }}
                                                        >
                                                            No data found
                                                        </div>
                                                    ),
                                                }}
                                                filterModel={filterModel}
                                                onFilterModelChange={(newModel) => setFilterModel(newModel)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Reports;