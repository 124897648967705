import React from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <div className="page-content">
      <div className="page-inner">
        <div className="page-title">
          <div className="privacy-policy">

            <h3>Privacy & Policy</h3>
            <div className="page-breadcrumb">
              <ol className="breadcrumb">
                <li><Link to="/Dashboard">Home</Link></li>
                <li className="active">Privacy & Policy</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="card-inner" style={{ paddingLeft: "55px",backgroundColor:"#fff",paddingTop:"10px",paddingBottom:"10px"}}>
          <div>

            <h1 style={{ fontWeight: 200 }}>Welcome to Project M</h1>
            <p style={{ fontSize: "14px" }}>Our priority is your privacy and security. This privacy policy outlines how we collect, use, and protect your personal information.</p>

            <h2 style={{ fontWeight: 400 }}>Information We Collect:</h2>
            <ul>
              <li>When you use our app, we may collect personal information such as your name, contact details, medical history, and device information.</li>
              <li>We may also gather usage data, such as how you interact with the app and the services you use.</li>
            </ul>

            <h2 style={{ fontWeight: 400 }}>How We Use Your Information:</h2>
            <p style={{ fontSize: "14px" }}>We use your information to provide personalized medical services, improve our app, and enhance your user experience. Your data may also be used for research purposes, always ensuring your privacy and anonymity.</p>

            <h2 style={{ fontWeight: 400 }}>Data Security:</h2>
            <p style={{ fontSize: "14px" }}>We employ robust security measures to safeguard your data against unauthorized access, disclosure, or alteration. Our security protocols include encryption, access controls, and regular security audits to ensure compliance with industry standards.</p>

            <h2 style={{ fontWeight: 400 }}>Sharing of Information:</h2>
            <p style={{ fontSize: "14px" }}>We may share your information with trusted third parties such as healthcare providers or researchers, solely for the purpose of delivering our services or improving healthcare outcomes. We do not sell or rent your personal information to third parties.</p>

            <h2 style={{ fontWeight: 400 }}>User Rights:</h2>
            <p style={{ fontSize: "14px" }}>You have the right to access, correct, or delete your personal information. You can exercise these rights by contacting us through the app. We respect your preferences regarding data processing and will obtain your consent before sharing your information with third parties.</p>

            <h2 style={{ fontWeight: 400 }}>Compliance:</h2>
            <p style={{ fontSize: "14px" }}>We comply with applicable privacy laws and regulations, including HIPAA and GDPR, to ensure the confidentiality and integrity of your data. For any inquiries or concerns regarding your privacy, please contact our privacy team at [contact email].</p>

            <h2 style={{ fontWeight: 400 }}>Updates to the Privacy Policy:</h2>
            <p style={{ fontSize: "14px" }}>We may update this privacy policy to reflect changes in our practices or regulatory requirements. Any updates will be communicated to you through the app.</p>

            <p style={{ fontSize: "14px" }}>By using our app, you consent to the collection and use of your personal information as described in this privacy policy. If you do not agree with these terms, please refrain from using our services.</p>

            <p style={{ fontSize: "14px" }}>Thank you for trusting Project M with your healthcare needs. Your privacy is our priority.</p>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Privacy;
