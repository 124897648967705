import React, { useState, useEffect } from "react";
import { apis } from "../../configuration/configurationAPI";
import axios from "axios";
import "./ProfileList.css";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { FaEye, FaUserEdit, FaEdit, FaExchangeAlt, FaTrash } from 'react-icons/fa';
import { format } from 'date-fns';
import { commonSelectors } from '../../utils/commonstore';
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import ProfileExportExcel from "../../Components/ProfileExportButtons/ProfileExportExcel";
import ProfileExportPDF from "../../Components/ProfileExportButtons/ProfileExportPDF";

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});
function ProfilesList() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [rows, setRows] = useState([]);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loggedinId = useSelector(commonSelectors.selectLoginUserId);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);

    useEffect(() => {

        axios.get(apis.base + apis.GET_PROFILES_API, { headers: { userid: loginUserId, Authorization: "Bearer " + baererToken } })
         .then((response) => {
                const profilesdata = response.data.map((el)=>{
                    return ({...el,
                        id:el.profile_info.id,
                        profile_id:el.profile_info.profile_id,
                        profile_name:el.profile_info.profile_name,
                    })
                })
                setData(profilesdata);
                console.log("qqqqq", response.data)
                setLoading(false);
                setError(false);
            })
            .catch((error) => {
                console.error("Error fetching profiles:", error);
                setError("Error while fetching profiles. Please try again.");
                setLoading(false);
            });
    }, []);

    // const sortedData = [...data].sort((a, b) => b.id - a.id);
    const columns = [
        {field:'profile_id',headerName:<strong>Profile ID</strong>,width:400},
        { field: 'profile_name', headerName: <strong>Name</strong>, width: 400},
        {
            field: 'action', headerName: <strong> Action</strong>, width: 400,
            renderCell: (params) => (
                <div>
                    <Link to={`/viewprofile/${params.row.profile_info.profile_id}`} className="btn "><RemoveRedEyeIcon style={{color:"rgb(52 66 90)"}}/></Link>
                </div>
            )
        },
    ];
    return (
         <div className="page-content">

        <div className="page-inner">
            <div className="page-title">
                <h3 style={{color:"black"}}>Profile List</h3>
                <div className="page-breadcrumb">
                    <ol className="breadcrumb">
                        <li><Link to="/Dashboard">Home</Link></li>
                        <li>Profiles</li>
                    </ol>
                </div>
            </div>
            
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mailbox-content" style={{ overflow: 'auto' }}>
                                {/* {!error && (
                                        <div style={{ position: "relative", paddingLeft: "10px", paddingBottom: "4px" }}>
                                            <div style={{ display: "flex", position: "relative", gap: "20px", justifyContent: "end", paddingRight: "20px", top: "20px" }}>
                                               <ProfileExportExcel/>
                                               <ProfileExportPDF/>
                                            </div>
                                        </div>
                                    )} */}
                                    <div style={{ height: "100%", width: '100%', paddingTop:"20px"}}>
                                        {error && <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>{error}</p>}
                                        {loading && <p style={{ textAlign: "center", fontSize: "15px" }}>Loading...</p>}
                                        {!loading && !error && (
                                            <DataGrid
                                                rows={data}
                                                columns={columns}
                                                pageSize={5}
                                                rowsPerPageOptions={[10, 20, 30]}
                                                loading={loading}
                                                classes={{
                                                    overlayWrapper: 'customOverlayWrapper'
                                                }}
                                                sx={{
                                                    "& .customOverlayWrapper ": {
                                                        height: "63px !important"
                                                    },
                                                    fontWeight: 'fontWeightLight',
                                                    mx: 0.5,
                                                    fontSize: 14, fontFamily: "sans-serif",
                                                    boxShadow: 2
                                                }}
                                                components={{
                                                    Toolbar: () => (
                                                        <GridToolbarQuickFilter
                                                        classes={{
                                                            toolbarQuickFilter: 'customtoolbarQuickFilter'
                                                        }}
                                                        sx={{
                                                            width: '35% !important',
                                                            position: 'absolute !important',
                                                            right: 0,
                                                            top: '-30px !important'
                                                        }}
                                                            filterModel={filterModel}
                                                            onFilterModelChange={(newModel) => setFilterModel(newModel)}
                                                        />
                                                    ),
                                                    NoRowsOverlay: () => (
                                                        <div
                                                            style={{
                                                                position: 'sticky',
                                                                top: '50%',
                                                                transform: 'translate(-50%, 0%)',
                                                                textAlign: 'center',
                                                                marginLeft: '45%'
                                                            }}
                                                        >
                                                            No data found
                                                        </div>
                                                    ),

                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </div></div>
    );
}

export default ProfilesList;
