import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { commonActions } from "../../utils/commonstore";
import Notifications from "../Notifications/notifications";
import Chatbot from "../footer/chatbot";

function Header(props) {
    const [inp, setInp] = useState('');
    const dispatch = useDispatch();
    const searchKeyword = useSelector((state) => state.commonStore.searchKeyword);

    const navigate = useNavigate();
    const handleInput = (event) => {
        const inputValue = event.target.value;
        setInp(inputValue);
    };

    const handleSearch = () => {
        dispatch(commonActions.setSearchKeyword(inp));
        localStorage.setItem('bzSearchKeyword', searchKeyword);
        navigate(`/search`);
    };

    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [fixedHeader, setFixedHeader] = useState(false);
    const [fixedSidebar, setFixedSidebar] = useState(false);
    const [horizontalBar, setHorizontalBar] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const fixedHeaderCheck = useRef(null);
    const fixedSidebarCheck = useRef(null);
    const horizontalBarCheck = useRef(null);
    const toggleSidebarCheck = useRef(null);
    const [str, setStr] = useState('');
    const [smTxt, setSmTxt] = useState('');

    const isVisitor = JSON.parse(sessionStorage.getItem('rl6User'))?.isVisitor || false;

    const sidebarAndContentHeight = () => {
    };

    const collapseSidebar = () => {
        const body = document.body;
        const logoBoxSpan = document.querySelector('.navbar .logo-box a span');
        if (toggleSidebar) {
            logoBoxSpan.textContent = "";
        } else {
            if (logoBoxSpan.textContent === "") {
                logoBoxSpan.textContent = smTxt;
            }
        }

        body.classList.toggle("small-sidebar");
        sidebarAndContentHeight();
    };

    const handleFixedHeaderChange = (checked) => {
        const body = document.body;
        if (body.classList.contains('page-horizontal-bar') &&
            body.classList.contains('page-sidebar-fixed') &&
            body.classList.contains('page-header-fixed')) {
        }
        body.classList.toggle('page-header-fixed');
        sidebarAndContentHeight();
        setFixedHeader(checked);
    };

    const handleFixedSidebarChange = () => {
        setFixedSidebar(prevFixedSidebar => !prevFixedSidebar);
        const body = document.body;
        const sidebar = document.querySelector('.sidebar');
        if (!fixedSidebar) {
            sidebar.classList.add('sidebar-fixed');
            body.classList.add('page-sidebar-fixed');
        } else {
            sidebar.classList.remove('sidebar-fixed');
            body.classList.remove('page-sidebar-fixed');
        }
        sidebarAndContentHeight();
    };

    useEffect(() => {
        const body = document.body;
        body.classList.remove("page-sidebar-fixed");
        sidebarAndContentHeight();
    }, []);

    useEffect(() => {
        sidebarAndContentHeight();
    }, []);

    const handleHorizontalBarChange = () => {
        setHorizontalBar(!horizontalBar);

        const body = document.body;
        const sidebar = document.querySelector('.sidebar');

        sidebar.classList.toggle('horizontal-bar');
        sidebar.classList.toggle('page-sidebar');
        body.classList.toggle('page-horizontal-bar');

        if (body.classList.contains('page-sidebar-fixed') && !body.classList.contains('page-header-fixed')) {
            if (fixedHeaderCheck.current) {
                fixedHeaderCheck?.current?.click();
            }
        }
        sidebarAndContentHeight();
    };

    useEffect(() => {

        if (fixedSidebarCheck.current && !fixedSidebar && fixedSidebarCheck.current.checked) {
            setFixedSidebar(true);
        }

        if (fixedSidebarCheck.current && fixedSidebar && !fixedSidebarCheck.current.checked) {
            setFixedSidebar(false);
        }

        if (fixedHeaderCheck.current && !fixedHeader && fixedHeaderCheck.current.checked) {
            setFixedHeader(true);
        }

        if (fixedHeaderCheck.current && fixedHeader && !fixedHeaderCheck.current.checked) {
            setFixedHeader(false);
        }

        if (horizontalBarCheck.current && !horizontalBar && horizontalBarCheck.current.checked) {
            setHorizontalBar(true);
        }

        if (horizontalBarCheck.current && horizontalBar && !horizontalBarCheck.current.checked) {
            setHorizontalBar(false);
        }

        if (toggleSidebarCheck.current && !toggleSidebar && toggleSidebarCheck.current.checked) {
            setToggleSidebar(true);
        }

        if (toggleSidebarCheck.current && toggleSidebar && !toggleSidebarCheck.current.checked) {
            setToggleSidebar(false);
        }


    }, [fixedSidebar, fixedHeader, horizontalBar, toggleSidebar]);

    useEffect(() => {
        if (toggleSidebar) {
            document.body.classList.add("small-sidebar");
        } else {
            document.body.classList.remove("small-sidebar");
        }
        sidebarAndContentHeight();
    }, [toggleSidebar]);

    const handleToggleSidebarChange = (checked) => {
        setToggleSidebar(((prevToggleSidebar) => !prevToggleSidebar))
        collapseSidebar();
    };

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };

    const closeSearch = () => {
        setIsSearchOpen(false);
    };

    const toggleFullscreen = () => {
        if (!isFullscreen) {

            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen();
            }
        } else {

            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }

        setIsFullscreen(!isFullscreen);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        dispatch(commonActions.logout());
        navigate('/login');
        props.setLoggedIn(false);
    };

    const toggleNotifications = () => {
        setIsNotificationsOpen(!isNotificationsOpen);
    };


    return (
        <div >
            <div className={`navbar ${fixedHeader ? 'fixed-header' : ''}`}>
                <div className="navbar-inner">
                    <div className="sidebar-pusher" >
                        <ul>
                            <li>
                            </li>
                            <li className="dropdown">
                                <Link to="/Profile">
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="/">
                                    <span><i className="fa fa-sign-out m-r-xs"></i>Log out</span>
                                </Link>
                            </li> */}
                            <li>
                            </li>
                        </ul>
                    </div>
                    <div className="logo-box">
                        <div className="logo-text" style={{color:"white"}}><span>PROJECT M</span></div>
                    </div>
                    <div className="search-button">
                        <a href="javascript:void(0);" className="waves-effect waves-button waves-classic show-search"><i className="fa fa-search"></i></a>
                    </div>
                    <div className="topmenu-outer" >
                        <div className="top-menu" >
                            <ul className="nav navbar-nav navbar-left navbar-scrollable">

                                {/* <li>
                                    <a href="javascript:void(0);" className="waves-effect waves-button waves-classic toggle-fullscreen" onClick={toggleFullscreen}>
                                        <i className={`fa ${isFullscreen ? 'fa-compress' : 'fa-expand'}`}></i>
                                    </a>
                                </li> */}
                                {/* <li class="dropdown">
                                    <a href="#" class="dropdown-toggle waves-effect waves-button waves-classic" data-toggle="dropdown" >
                                        <i class="fa fa-cogs"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-md dropdown-list theme-settings" role="menu" >
                                        <li class="li-group" >
                                            <ul class="list-unstyled">
                                                <li class="no-link" role="presentation">
                                                    Fixed Header
                                                    <Switch
                                                        className="js-switch pull-right fixed-sidebar-check"
                                                        checked={fixedHeader}
                                                        onChange={handleFixedHeaderChange}
                                                    />
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="li-group">
                                            <ul class="list-unstyled">
                                                <li class="no-link" role="presentation">
                                                    Fixed Sidebar
                                                    <div class="ios-switch pull-right switch-md">
                                                        <Switch class="js-switch pull-right fixed-sidebar-check"
                                                            checked={fixedSidebar}
                                                            onChange={handleFixedSidebarChange}
                                                        />
                                                    </div>
                                                </li>
                                                <li class="no-link" role="presentation">
                                                    Horizontal bar
                                                    <div class="ios-switch pull-right switch-md">
                                                        <Switch class="js-switch pull-right horizontal-bar-check"
                                                            checked={horizontalBar} onChange={handleHorizontalBarChange} />
                                                    </div>
                                                </li>
                                                <li class="no-link" role="presentation">
                                                    Toggle Sidebar
                                                    <div class="ios-switch pull-right switch-md">
                                                        <Switch class="js-switch pull-right toggle-sidebar-check"
                                                            checked={toggleSidebar} onChange={handleToggleSidebarChange} />
                                                    </div>
                                                </li>     
                                            </ul>
                                        </li>
                                    </ul>
                                </li> */}


                            </ul>
                            <ul className="nav navbar-nav navbar-right">

                                {/* <li>
                                    <a href="javascript:void(0);" className="waves-effect waves-button waves-classic toggle-fullscreen" onClick={toggleFullscreen}>
                                        <i className={`fa ${isFullscreen ? 'fa-compress' : 'fa-expand'}`}></i>
                                    </a>
                                </li> */}

                                {/* <li>
                                    <a href="javascript:void(0);" className="waves-effect waves-button waves-classic show-search" onClick={toggleSearch}>
                                        <i className={`fa ${isSearchOpen ? 'fa-search' : 'fa-search'}`}></i>
                                    </a>
                                    {isSearchOpen && (
                                        <div className="search-form" >
                                            <div className="input-group">
                                                <input onInput={(event) => {
                                                    handleInput(event);
                                                }}
                                                    onKeyPress={(event) => {
                                                        if (event.key === 'Enter') {
                                                            handleSearch();
                                                        }
                                                    }} type="text" name="search" className="form-control search-input" placeholder="Search..." style={{ width: "200px" }} />
                                                <button className="btn btn-default close-search waves-effect waves-button waves-classic" type="button" onClick={closeSearch}>
                                                    <i className="fa fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </li>
                                */}
                               
                                <li style={{ alignContent: "space-around", paddingTop: "5px", paddingRight: "10px" }}>  
                                   <Chatbot/>
                                </li> 
                                {!isVisitor && (
                                    <li style={{ alignContent: "space-around", paddingTop: "13px", paddingRight: "10px" }}>
                                        <span onClick={toggleNotifications} style={{ cursor: 'pointer' }}>
                                            <i className="fa fa-bell"></i>
                                            <span className="badge badge-success pull-right"></span>
                                        </span>
                                    </li>
                                )}
                                {/* <li className="dropdown">
                                    <Link to="/Profile"></Link>
                                </li> */}
                                <li>
                                    <Link to="/login" onClick={handleLogout}>
                                        <span><i className="fa fa-sign-out m-r-xs"></i>Log out</span>
                                    </Link>
                                </li>
                                
                                <li>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {isNotificationsOpen && (
                    <Notifications
                        isVisible={isNotificationsOpen}
                        onClose={toggleNotifications}
                    />
                )}
            </div>
        </div>
    )
}
export default Header;