import React, { useState } from "react";
import './helpscreen.css';
import { Link } from "react-router-dom";


const faqs = [
  {
    question: "What is the purpose of the Incident Reporting System for hospitals?",
    answer: "The system is designed to allow hospital personnel and patient attendees to report incidents promptly, facilitating quick resolution and helping hospital management manage incidents efficiently.",
  },
  {
    question: "Who can register an incident in the system?",
    answer: "Any hospital personel or attendee accompanying a patient has the provision to register an incident in one of the provided categories..",
  },
  {
    question: "What types of incidents can be reported using the system?",
    answer: "The system allows users to report incidents falling under various categories, such as Airway Management,Coding Complication,Anesthesia or sedation,Restraints,Patient Behavior,Fall,Lab/Specimen Collection,Obstetrics,Adverse Drug Reaction,Surgery/Procedure consent,Blood and Blood Products, Medication/Chemotherapy,Medical Care and Treatment,Unprofessional Behavior,Safety/Security/Facility,Diagnostic Imaging,Medical Device Reporting,Patient Privacy,Feedback.",
  },
  {
    question: "Who has the ability to process a generated incident in the system?",
    answer: "Only the Risk Manager (RM) has the authority to process a generated incident.",
  },
  {
    question: "How are concerned authorities notified when an incident is reported?",
    answer: "Notifications are sent to the concerned authorities based on the severity of the incident. If the severity is 2 or lower, notifications go to the Chief Medical Officer (CMO), Risk Manager (RM), and Quality Manager (QM). If the severity is 3 or higher, the notification includes the President/CEO of the hospital.",
  },
  {
    question: "What are the different states an incident can go through in the system?",
    answer: "Incidents can go through the following states: Created, In Progress (automatic when assigned to Director of Department - DOD), Complete (updated by RM), Cancelled (if the ticket is invalid), Deleted (can be put to this state only by RM).",
  },
  {
    question: "How is an incident assigned and moved to the In Progress state?",
    answer: "The incident is assigned to the RM by default, and the RM then assigns it to the Director of Department (DOD), moving the incident to the In Progress state.",
  },
  {
    question: "What happens after an incident is marked as complete?",
    answer: "Once marked as complete, the RM puts the ticket in the Complete state and sends notifications to all concerned parties.",
  },
  {
    question: "How are notifications sent to concerned parties?",
    answer: "Notifications are sent only via email.",
  },
  {
    question: "Can a closed incident be reopened?",
    answer: "Yes, any incident that is not closed properly can be reopened by the RM.",
  },
  {
    question: "What actions can the Super Admin perform in the system?",
    answer: "The Super Admin has complete access to the application, including the ability to create, view, edit, and delete roles on all sections. They can also create, assign, and delete users. Additionally, the Super Admin can delete any incidents, update the Risk Manager, and generate and export reports.",
  },
  {
    question: "What permissions does the Admin have in the system?",
    answer: "The Admin has complete access to the application, similar to the Super Admin, including creating, viewing, editing, and deleting roles on all sections. They can delete any incidents and generate and export reports.",
  },
  {
    question: "What are the responsibilities of the Sub Admin? ",
    answer: "The Sub Admin can assign tickets, add comments, and is associated with the Quality Manager (QM) role",
  },
  {
    question: "What access does the Incident Manager profile have?",
    answer: "The Incident Manager has access to view and add comments to incidents and can view users. This role is associated with the Director of Department (DOD).",
  },
  {
    question: "What are the privileges of the View profile?",
    answer: "The View profile has access to view created incidents and view existing users. This role is associated with the Chief Medical Officer (CMO). ",
  },
  {
    question:"Who is responsible for reviewing and approving incident reports before they are processed within the system?",
    answer:"The risk manager in the healthcare is in charge of checking and approving incident reports to make sure they are accurate and comprehensive before they are processed further.",
  }
 
];

const  FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  return (
    <div>
      <div className="page-content">
      <div className="page-inner">
      <div className="page-title">
                <h3>Dashboard</h3>
                <div className="page-breadcrumb">
                    <ol className="breadcrumb">
                        <li><Link to="/Dashboard">Home</Link></li>
                        <li className="active">Help</li>
                    </ol>
                </div>
            </div>
        <div className="card-inner" style={{ paddingLeft: "55px",backgroundColor:"#fff",paddingBottom:"10px"}}>
       
        <h2 style={{ fontSize: "24px", marginBottom: "20px",height:"30px" }}>
            <img src="assets\images\Icons\faq.jpg" alt="Logo" style={{ height: "53px"}} />
            Frequently Asked Questions
          </h2> 
         
          <div style={{ marginTop: "40px" }}> {/* Add some margin-top to create space */}
            <ul className="faq-list">
              {faqs.map((faq, index) => (
                <li key={index}>
                  <div
                    style={{
                      fontSize:"16px",
                      fontWeight: "bold",
                      margin: "10px 0",
                      cursor: "pointer",
                      color: expandedIndex === index ? "#337ab7" : "black",
                    }}
                    onClick={() => setExpandedIndex(expandedIndex === index ? -1 : index)}
                  >
                    {faq.question}
                  </div>
                  {expandedIndex === index && (
                    <div
                      style={{   fontSize: "16px",
                        display: "block",
                        margin: "10px 0",
                      }}
                    >
                      {faq.answer}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default FAQ;