import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { Button, TextField } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const Section1Page1 = () => {
  const [typeofprofessional, setTypeOfProfessional] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [maidenName, setMaidenName] = useState("");
  const [otherName, setOtherName] = useState("");
  const [homePhoneNumber, setHomePhoneNumber] = useState("");
  const [socialSecurityNumber, setSocialSecurityNumber] = useState("");
  const [homeMailingAddress, setHomeMailingAddress] = useState("");
  const [gender, setGender] = useState("");
  const [dob,setDOB] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [city1, setCity1] = useState("");
  const [state1, setState1] = useState("");
  const [city2, setCity2] = useState("");
  const [state2, setState2] = useState("");
  const [city3, setCity3] = useState("");
  const [state3, setState3] = useState("");
  const [YesNo, setYesNo] = useState("");
  const [YesNo1, setYesNo1] = useState("");
  const [YesNo2, setYesNo2] = useState("");
  const [correspondenceAddress, setCorrespondenceAddress] = useState("");
  const [address, setAddress] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalCode1, setPostalCode1] = useState("");
  const [postalCode2, setPostalCode2] = useState("");
  const [postalCode3, setPostalCode3] = useState("");
  const [postalCode4, setPostalCode4] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [email, setEmail] = useState("");
  const [POB, setPOB] = useState("");
  const [from, setFrom] = useState("");
  const [from1, setFrom1] = useState("");
  const [from2, setFrom2] = useState("");
  const [from3, setFrom3] = useState("");
  const [to, setTO] = useState("");
  const [to1, setTo1] = useState("");
  const [to2, setTo2] = useState("");
  const [to3, setTo3] = useState("");
  const [BOS, setBOS] = useState("");
  const [lastLocation, setLastLocation] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [institution, setInstitution] = useState("");
  const [institutionAddress, setInstitutionAddress] = useState("");
  const [institutionAddress1, setInstitutionAddress1] = useState("");
  const [degree, setDegree] = useState("");
  const [PGEducation, setPGEducation] = useState("");
  const [PGEducation1, setPGEducation1] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [speciality1, setSpeciality1] = useState("");
  const [programDirector, setProgramDirector] = useState("");
  const [programDirector1, setProgramDirector1] = useState("");
  const [currentProgramDirector, setCurrentProgramDirector] = useState("");
  const [currentProgramDirector1, setCurrentProgramDirector1] = useState("");
  const [visaAndStatus, setVisaAndStatus] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [checked1, setChecked1] = React.useState(false);
  

//States for ERROR 

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [middleNameError, setMiddleNameError] = useState("");
  const [maidenNameError, setMaidenNameError] = useState("");
  const [otherNameError, setOtherNameError] = useState("");
  const [homePhoneNumberError, setHomePhoneNumberError] = useState("");
  const [socialSecurityNumberError, setSocialSecurityNumberError] = useState("");
  const [genderError, SetGenderError] = useState("");
  const [cityError, setCityError] = useState("");
  const [dobError, setdobError] = useState("");
  const [addressError, setaddressError] = useState("");
  const [addressError1, setAddressError1] = useState("");
  const [addressError2, setAddressError2] = useState("");
  const [cityError1, setCityError1] = useState(""); 
  const [cityError2, setCityError2] = useState("");
  const [cityError3, setCityError3] = useState("");
  const [stateError, setStateError] = useState("");
  const [stateError1, setStateError1] = useState("");
  const [stateError2, setStateError2] = useState("");
  const [stateError3, setStateError3] = useState("");
  const [emailError, setEmailError] = useState("");
  const [YesNoError, setYesNoError] = useState("");
  const [YesNoError1, setYesNoError1] = useState("");
  const [YesNoError2, setYesNoError2] = useState("");
  const [postalCodeError, setpostalCodeError] = useState("");
  const [postalCodeError1, setpostalCodeError1] = useState("");
  const [postalCodeError2, setpostalCodeError2] = useState("");
  const [postalCodeError3, setpostalCodeError3] = useState("");
  const [postalCodeError4, setpostalCodeError4] = useState("");
  const [programDirectorError, setProgramDirectorError] = useState("");
  const [programDirectorError1, setProgramDirectorError1] = useState("");
  const [typeofprofessionalerror, setTypeOfProfessionalerror] = useState("");
  const [homeMailingAddressError, setHomeMailingAddressError] = useState("");
  const [correspondenceAddressError, setCorrespondenceAddressError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [faxNumberError, setFaxNumberError] = useState("");
  const [POBError, setPOBError] = useState("");
  const [fromError, setFromError] = useState("");
  const [fromError1, setFromError1] = useState("");
  const [fromError2, setFromError2] = useState("");
  const [fromError3, setFromError3] = useState("");
  const [toError, setToError] = useState("");
  const [toError1, setToError1] = useState("");
  const [toError2, setToError2] = useState("");
  const [toError3, setToError3] = useState("");
  const [BOSError, setBOSError] = useState("");
  const [PGEducationError, setPGEducationError] = useState("");
  const [PGEducationError1, setPGEducationError1] = useState("");
  const [institutionError, setInstitutionError] = useState("");
  const [institutionAddressError, setInstitutionAddressError] = useState("");
  const [institutionAddressError1, setInstitutionAddressError1] = useState("");
  const [degreeError, setDegreeError] = useState("");
  const [lastLocationError, setLastLocationError] = useState("");
  const [specialityError, setSpecialityError] = useState("");
  const [specialityError1, setSpecialityError1] = useState("");
  const [citizenshipError, setCitizenshipError] = useState("");
  const [visaAndStatusError,setVisaAndStatusError] = useState("");
  const [currentProgramDirectorError, setCurrentProgramDirectorError] = useState("");
  const [currentProgramDirectorError1, setCurrentProgramDirectorError1] = useState("");


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!typeofprofessional.trim()) {
      setTypeOfProfessionalerror("Please Select Type Of Professional.");
    } else {
      setTypeOfProfessionalerror("");
    }
    
    if (!firstName.trim()) {
      setFirstNameError("Please Enter FirstName.");
    } else {
      setFirstNameError("");
    }

    if (!lastName.trim()) {
      setLastNameError("Please Enter LastName.");
    } else {
      setLastNameError("");
    }

    if (!middleName.trim()) {
      setMiddleNameError("Please Enter MiddleName.");
    } else {
      setMiddleNameError("");
    }
    if (!maidenName.trim()) {
      setMaidenNameError("Please Enter Maiden Name.");
    } else {
      setMaidenNameError("");
    }
    if (!otherName.trim()) {
      setOtherNameError("Please Enter Other Name.");
    } else {
      setOtherNameError("");
    }
    if (!homeMailingAddress.trim()) {
      setHomeMailingAddressError("Please Enter Home Mailing Address.");
    } else {
      setHomeMailingAddressError("");
    }
    if (!homePhoneNumber.trim()) {
      setHomePhoneNumberError("Please Enter Home Phone Number.");
    } else {
      setHomePhoneNumberError("");
    }
    if (!socialSecurityNumber.trim()) {
      setSocialSecurityNumberError("Please Enter Social Security Number.");
    } else {
      setSocialSecurityNumberError("");
    }
    if (!gender.trim()) {
      SetGenderError("Please Select Gender.");
    } else {
      SetGenderError("");
    }
    if (!email.trim()) {
      setEmailError("Please Enter E-Mail.");
    } else {
      setEmailError("");
    }
    if (!citizenship.trim()) {
      setCitizenshipError("Please Enter Citizenship.");
    } else {
      setCitizenshipError("");
    }
    if (!city.trim()) {
      setCityError("Please Enter City.");
    } else {
      setCityError("");
    }
    if (!city1.trim()) {
      setCityError1("Please Enter City.");
    } else {
      setCityError1("");
    }
    if (!city2.trim()) {
      setCityError2("Please Enter City.");
    } else {
      setCityError2("");
    }
    if (!city3.trim()) {
      setCityError3("Please Enter City.");
    } else {
      setCityError3("");
    }
    if (!state.trim()) {
      setStateError("Please Enter State.");
    } else {
      setStateError("");
    }
    if (!state1.trim()) {
      setStateError1("Please Enter State.");
    } else {
      setStateError1("");
    }
    if (!state2.trim()) {
      setStateError2("Please Enter State.");
    } else {
      setStateError2("");
    }
    if (!state3.trim()) {
      setStateError3("Please Enter State.");
    } else {
      setStateError3("");
    }

    if (!dob.trim()) {
      setdobError("Please Enter Date of Birth.");
    } else {
      setdobError("");
    }
    if (!correspondenceAddress.trim()) {
      setCorrespondenceAddressError("Please Enter Correspondence Address.");
    } else {
      setCorrespondenceAddressError("");
    }

    if (!address.trim()) {
      setaddressError("Please Enter Address.");
    } else {
      setaddressError("");
    }
    if (!address1.trim()) {
      setAddressError1("Please Enter Address.");
    } else {
      setAddressError1("");
    }
    if (!address2.trim()) {
      setAddressError2("Please Enter Address.");
    } else {
      setAddressError2("");
    }

    if (!city1.trim()) {
      setCityError("Please Enter City.");
    } else {
      setCityError("");
    }

    if (!state.trim()) {
      setStateError("Please Enter State.");
    } else {
      setStateError("");
    }
    if (!state1.trim()) {
      setStateError1("Please Enter State.");
    } else {
      setStateError1("");
    }
    if (!state2.trim()) {
      setStateError2("Please Enter State.");
    } else {
      setStateError2("");
    }

    if (!postalCode.trim()) {
      setpostalCodeError("Please Enter Postal Code.");
    } else {
      setpostalCodeError("");
    }
    if (!postalCode1.trim()) {
      setpostalCodeError1("Please Enter Postal Code.");
    } else {
      setpostalCodeError1("");
    }
    if (!postalCode2.trim()) {
      setpostalCodeError2("Please Enter Postal Code.");
    } else {
      setpostalCodeError2("");
    }
    if (!postalCode3.trim()) {
      setpostalCodeError3("Please Enter Postal Code.");
    } else {
      setpostalCodeError3("");
    }
    if (!postalCode4.trim()) {
      setpostalCodeError4("Please Enter Postal Code.");
    } else {
      setpostalCodeError4("");
    }
    
    if (!phoneNumber.trim()) {
      setPhoneNumberError("Please Enter Phone Number.");
    } else {
      setPhoneNumberError("");
    }
    if (!faxNumber.trim()) {
      setFaxNumberError("Please Enter Fax Number.");
    } else {
      setFaxNumberError("");
    }
    if (!POB.trim()) {
      setPOBError("Please Enter City.");
    } else {
      setPOBError("");
    }
    if (!visaAndStatus.trim()) {
      setVisaAndStatusError("Please Enter Visa And Status.");
    } else {
      setVisaAndStatusError("");
    }
    if (!YesNo.trim()) {
      setYesNoError("Please Enter City.");
    } else {
      setYesNoError("");
    }
    if (!YesNo1.trim()) {
      setYesNoError1("Please Enter City.");
    } else {
      setYesNoError1("");
    }
    if (!YesNo2.trim()) {
      setYesNoError2("Please Enter City.");
    } else {
      setYesNoError2("");
    }
    if (!from.trim()) {
      setFromError("Please Enter From Date.");
    } else {
      setFromError("");
    }
    if (!from1.trim()) {
      setFromError1("Please Enter From Date.");
    } else {
      setFromError1("");
    }
    if (!from2.trim()) {
      setFromError2("Please Enter From Date.");
    } else {
      setFromError2("");
    }
    if (!from3.trim()) {
      setFromError3("Please Enter From Date.");
    } else {
      setFromError3("");
    }
    if (!to.trim()) {
      setToError("Please Enter To Date.");
    } else {
      setToError("");
    }
    if (!to1.trim()) {
      setToError1("Please Enter To Date.");
    } else {
      setToError1("");
    }
    if (!to2.trim()) {
      setToError2("Please Enter To Date.");
    } else {
      setToError2("");
    }
    if (!to3.trim()) {
      setToError3("Please Enter To Date.");
    } else {
      setToError3("");
    }
    if (!lastLocation.trim()) {
      setLastLocationError("Please Enter Last Location.");
    } else {
      setLastLocationError("");
    }
    if (!BOS.trim()) {
      setBOSError("Please Enter BOS.");
    } else {
      setBOSError("");
    }
    if (!institution.trim()) {
      setInstitutionError("Please Enter Institution.");
    } else {
      setInstitutionError("");
    }
    if (!degree.trim()) {
      setDegreeError("Please Enter Degree.");
    } else {
      setDegreeError("");
    }
    if (!PGEducation.trim()) {
      setPGEducationError("Please Select Post Graduation Education.");
    } else {
      setPGEducationError("");
    }
    if (!PGEducation1.trim()) {
      setPGEducationError1("Please Select Post Graduation Education.");
    } else {
      setPGEducationError1("");
    }
    if (!institutionAddress.trim()) {
      setInstitutionAddressError("Please Enter Institution Address.");
    } else {
      setInstitutionAddressError("");
    }
    if (!institutionAddress1.trim()) {
      setInstitutionAddressError1("Please Enter Institution Address.");
    } else {
      setInstitutionAddressError1("");
    }
    if (!speciality.trim()) {
      setSpecialityError("Please Enter Speciality.");
    } else {
      setSpecialityError("");
    }
    if (!speciality1.trim()) {
      setSpecialityError1("Please Enter Speciality.");
    } else {
      setSpecialityError1("");
    }
    if (!programDirector.trim()) {
      setProgramDirectorError("Please Enter Program Director.");
    } else {
      setProgramDirectorError("");
    }
    if (!programDirector1.trim()) {
      setProgramDirectorError1("Please Enter Program Director.");
    } else {
      setProgramDirectorError1("");
    }
    if (!currentProgramDirector.trim()) {
      setCurrentProgramDirectorError("Please Enter Current Program Director.");
    } else {
      setCurrentProgramDirectorError("");
    }
    if (!currentProgramDirector1.trim()) {
      setCurrentProgramDirectorError1("Please Enter Current Program Director.");
    } else {
      setCurrentProgramDirectorError1("");
    }
  };

  const validateInputs = () => {
    let valid = true;

    const namePattern = /^[a-zA-Z]+$/;
    const postalCodePattern = /^\d{6}$/;
    const mobileNumberPattern = /^\d{10}$/;

    if (!firstName.match(namePattern)) {
      setFirstNameError("First name should only contain alphabets.");
      valid = false;
    } else {
      setFirstNameError("");
    }

    if (!lastName.match(namePattern)) {
      setLastNameError("Last name should only contain alphabets.");
      valid = false;
    } else {
      setLastNameError("");
    }
    if (!middleName.match(namePattern)) {
      setMiddleNameError("Middle name should only contain alphabets.");
      valid = false;
    } else {
      setMiddleNameError("");
    }
    if (!maidenName.match(namePattern)) {
      setMaidenNameError("Maiden name should only contain alphabets.");
      valid = false;
    } else {
      setMaidenNameError("");
    }
    if (!otherName.match(namePattern)) {
      setOtherNameError("Other name should only contain alphabets.");
      valid = false;
    } else {
      setOtherNameError("");
    }

    if (!postalCode.match(postalCodePattern)) {
      setpostalCodeError("Zip code should be a 6-digit number.");
      valid = false;
    } else {
      setpostalCodeError("");
    }
    if (!homePhoneNumber.match(mobileNumberPattern)) {
      setHomePhoneNumberError("Home Phone Number should be a 10-digit number.");
      valid = false;
    } else {
      setHomePhoneNumberError("");
    }
    if (!socialSecurityNumber.match(mobileNumberPattern)) {
      setSocialSecurityNumberError("Social Security Number should be a 10-digit number.");
      valid = false;
    } else {
      setSocialSecurityNumberError("");
    }
    // If there are no errors, you can proceed with form submission
    // Otherwise, stay on the form page and display errors
    if (!typeofprofessionalerror && !homeMailingAddressError
      && !firstNameError && !lastNameError && !genderError && !dobError && !addressError && !cityError
      && !stateError && !postalCodeError) {
    }
    return valid;
  }

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };
  const handleYesNoChange = (e) => {
    setYesNo(e.target.value);
  };
  const handleYesNoChange1 = (e) => {
    setYesNo1(e.target.value);
  };
  const handleYesNoChange2 = (e) => {
    setYesNo2(e.target.value);
  };
  const handlePGEducationChange = (e) => {
    setPGEducation(e.target.value);
  };
  const handlePGEducationChange1 = (e) => {
    setPGEducation1(e.target.value);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChange1 = (event) => {
    setChecked1(event.target.checked);
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <div>
      <div className="page-inner">
        <div className="page-title">
          <h3>Application Form</h3>
          <div className="page-breadcrumb">
            <ol className="breadcrumb">
              <li><Link to="/Dashboard">Home</Link></li>
              <li><Link to="/Icons">Forms</Link></li>
              <li className="active">Section I</li>
            </ol>
          </div>
        </div>

        <div class="col-md-12 ">
          <div class="col-md-12">
            {/* General Incident Type */}
            <form onSubmit={handleSubmit}>
              <div id="main-wrapper">
                <div class="row ">
                  <div >
                    <div class="panel panel-white">
                      <h4 class="panel-title" style={{ padding: "10px" }}>Section I-Individual Information</h4>
                      <div class="panel-body">
                        <div class="form-horizontal"  >
                          <div class="form-group"  >
                            <label for="surgeryType" class="col-sm-2 control-label " style={{ textAlign: "start", fontWeight: "600" }} >TYPE OF PROFESSIONAL <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                            <div>
                              <Box className="col-sm-10" >
                                <Select fullWidth
                                  value={typeofprofessional}
                                  onChange={(e) => setTypeOfProfessional(e.target.value)}>
                                  {/* <MenuItem value="">Please Select</MenuItem> */}
                                  <MenuItem value="Doctors">Doctor</MenuItem>
                                  <MenuItem value="Physiotherapists">Physiotherapist</MenuItem>
                                  <MenuItem value="Nurses">Nurse</MenuItem>
                                  <MenuItem value="Occupational therapists">Occupational therapist</MenuItem>
                                  <MenuItem value="Dietitians">Dietitian</MenuItem>
                                  <MenuItem value="Social Workers">Social Worker</MenuItem>
                                </Select>
                                <div className="text-danger ">{typeofprofessionalerror}</div>
                              </Box>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>LAST NAME<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                              <div className="text-danger">{lastNameError}</div>
                            </div>

                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>FIRST NAME<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                              <div className="text-danger">{firstNameError}</div>
                            </div>
                            <div class="col-md-4">
                              <label style={{ fontWeight: "600" }}>MIDDLE NAME<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="Middle Name" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
                              <div className="text-danger">{middleNameError}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-6">
                              <label style={{ fontWeight: "600" }}>MAIDEN NAME<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="MAIDEN NAME" value={maidenName} onChange={(e) => setMaidenName(e.target.value)} />
                              <div className="text-danger">{maidenNameError}</div>
                            </div>
                            <div class="col-md-6">
                              <label style={{ fontWeight: "600" }}>OTHER NAME<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="OTHER NAME" value={otherName} onChange={(e) => setOtherName(e.target.value)} />
                              <div className="text-danger">{otherNameError}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-3">
                              <label style={{ fontWeight: "600" }}>HOME PHONE NUMBER<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="HOME PHONE NUMBER" value={homePhoneNumber} onChange={(e) => setHomePhoneNumber(e.target.value)} />
                              <div className="text-danger">{homePhoneNumberError}</div>
                            </div>

                            <div class=" col-md-3">
                              <label style={{ fontWeight: "600" }}>SOCIAL SECURITY NUMBER<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="SOCIAL SECURITY NUMBER" value={socialSecurityNumber} onChange={(e) => setSocialSecurityNumber(e.target.value)} />
                              <div className="text-danger">{socialSecurityNumberError}</div>
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="exampleInputName2" style={{ fontWeight: "600" }}>Gender<span style={{ fontWeight: "bold", color: "red" }}>*</span></label>
                              <div>
                                <label className="col-md-3">
                                  <input type="radio" name="gender" value="Male" checked={gender === "Male"} onChange={handleGenderChange} /> Male
                                </label>
                                <label className="col-md-3">
                                  <input type="radio" name="gender" value="Female" checked={gender === "Female"} onChange={handleGenderChange} /> Female
                                </label>
                                <label className="col-md-3">
                                  <input type="radio" name="gender" value="Others" checked={gender === "Others"} onChange={handleGenderChange} /> Others
                                </label>
                                <div className="text-danger col-md-6 ">{genderError}</div>
                              </div>

                            </div>
                          </div>
                          <div class="form-group">
                            <label for="procedureDate" class="col-sm-2 control-label " style={{ textAlign: "start", fontWeight: "600" }}>HOME MAILING ADDRESS<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                            <div class="col-sm-10">
                              <TextField type="text"
                                fullWidth
                                placeholder="HOME MAILING ADDRESS"
                                value={homeMailingAddress}
                                onChange={(e) => setHomeMailingAddress(e.target.value)}
                              />
                              <div className="text-danger">{homeMailingAddressError}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>CITY<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="CITY" value={city} onChange={(e) => setCity(e.target.value)} />
                              <div className="text-danger">{cityError}</div>
                            </div>

                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>STATE/COUNTRY<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="STATE/COUNTRY" value={state} onChange={(e) => setState(e.target.value)} />
                              <div className="text-danger">{stateError}</div>
                            </div>
                            <div class="col-md-4">
                              <label style={{ fontWeight: "600" }} >POSTAL CODE<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="POSTAL CODE" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                              <div className="text-danger">{postalCodeError}</div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="procedureDate" class="col-sm-2 control-label " style={{ textAlign: "start", fontWeight: "600" }}>CORRESPONDENCE ADDRESS<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                            <div class="col-sm-10">
                              <TextField type="text"
                                fullWidth
                                placeholder="CORRESPONDENCE ADDRESS"
                                value={correspondenceAddress}
                                onChange={(e) => setCorrespondenceAddress(e.target.value)}
                              />
                              <div className="text-danger">{correspondenceAddressError}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>CITY<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="CITY" value={city1} onChange={(e) => setCity1(e.target.value)} />
                              <div className="text-danger">{cityError1}</div>
                            </div>

                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>STATE/COUNTRY<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="STATE/COUNTRY" value={state1} onChange={(e) => setState1(e.target.value)} />
                              <div className="text-danger">{stateError1}</div>
                            </div>
                            <div class="col-md-4">
                              <label style={{ fontWeight: "600" }} >POSTAL CODE<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="POSTAL CODE" value={postalCode1} onChange={(e) => setPostalCode1(e.target.value)} />
                              <div className="text-danger">{postalCodeError1}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>PHONE NUMBER<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="PHONE NUMBER" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                              <div className="text-danger">{phoneNumberError}</div>
                            </div>

                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>FAX NUMBER<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="FAX NUMBER" value={faxNumber} onChange={(e) => setFaxNumber(e.target.value)} />
                              <div className="text-danger">{faxNumberError}</div>
                            </div>
                            <div class="col-md-4">
                              <label style={{ fontWeight: "600" }} >E-MAIL<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="E-MAIL" value={email} onChange={(e) => setEmail(e.target.value)} />
                              <div className="text-danger">{emailError}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>DATE OF BIRTH <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="MM/DD/YYYY" value={dob} onChange={(e) => setDOB(e.target.value)} />
                              <div className="text-danger">{dobError}</div>
                            </div>

                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>PLACE OF BIRTH<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="PLACE OF BIRTH" value={POB} onChange={(e) => setPOB(e.target.value)} />
                              <div className="text-danger">{POBError}</div>
                            </div>
                            <div class="col-md-4">
                              <label style={{ fontWeight: "600" }} >CITIZENSHIP<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="CITIZENSHIP" value={citizenship} onChange={(e) => setCitizenship(e.target.value)} />
                              <div className="text-danger">{citizenshipError}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-8">
                              <label style={{ fontWeight: "600" }}>IF NOT AMERICAN CITIZEN, VISA NUMBER & STATUS <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="IF NOT AMERICAN CITIZEN, VISA NUMBER & STATUS" value={visaAndStatus} onChange={(e) => setVisaAndStatus(e.target.value)} />
                              <div className="text-danger">{visaAndStatusError}</div>
                            </div>

                            <div className="col-md-4" style={{ top: "20px" }}>
                              <label htmlFor="exampleInputName2" style={{ fontWeight: "600" }}>ARE YOU ELIGIBLE TO WORK IN THE UNITED STATES?<span style={{ fontWeight: "bold", color: "red" }}>*</span></label>
                              <div>
                                <label className="col-md-3">
                                  <input type="radio" name="Yes" value="Yes" checked={YesNo === "Yes"} onChange={handleYesNoChange} /> YES
                                </label>
                                <label className="col-md-3">
                                  <input type="radio" name="No" value="No" checked={YesNo === "No"} onChange={handleYesNoChange} /> NO
                                </label>
                                <div className="text-danger col-md-6 ">{YesNoError}</div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div className="col-md-4" style={{ top: "10px" }}>
                              <label htmlFor="exampleInputName2" style={{ fontWeight: "600" }}>U.S.MILITARY SERVICE/PUBLIC HEALTH<span style={{ fontWeight: "bold", color: "red" }}>*</span></label>
                              <div>
                                <label className="col-md-3" >
                                  <input type="radio" name="Yes" value="Yes" checked={YesNo1 === "Yes"} onChange={handleYesNoChange1} /> YES
                                </label>
                                <label className="col-md-3">
                                  <input type="radio" name="No" value="No" checked={YesNo1 === "No"} onChange={handleYesNoChange1} /> NO
                                </label>
                                <div className="text-danger col-md-6 ">{YesNoError1}</div>
                              </div>
                            </div>

                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>DATES OF SERVICE <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <TextField type="text" className='col-sm-6' placeholder="MM/DD/YYYY" value={from} onChange={(e) => setFrom(e.target.value)} />
                                <div className="text-danger">{fromError}</div>
                                <h4 className="" style={{ padding: "5px" }}>TO</h4>
                                <TextField type="text" className='col-sm-6' placeholder="MM/DD/YYYY" value={to} onChange={(e) => setTO(e.target.value)} />
                                <div className="text-danger">{toError}</div>
                              </div>

                            </div>
                            <div class="col-md-4">
                              <label style={{ fontWeight: "600" }} >LAST LOCATION<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="LAST LOCATION" value={lastLocation} onChange={(e) => setLastLocation(e.target.value)} />
                              <div className="text-danger">{lastLocationError}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class="col-md-8">
                              <label style={{ fontWeight: "600" }} >BRANCH OF SERVICE<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="BRANCH OF SERVICE" value={BOS} onChange={(e) => setBOS(e.target.value)} />
                              <div className="text-danger">{BOSError}</div>
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="exampleInputName2" style={{ fontWeight: "600" }}>ARE YOU CURRENTLY ON ACTIVE OR RESERVE MILITARY DUTY?<span style={{ fontWeight: "bold", color: "red" }}>*</span></label>
                              <div>
                                <label className="col-md-3">
                                  <input type="radio" name="Yes" value="Yes" checked={YesNo2 === "Yes"} onChange={handleYesNoChange2} /> YES
                                </label>
                                <label className="col-md-3">
                                  <input type="radio" name="No" value="No" checked={YesNo2 === "No"} onChange={handleYesNoChange2} /> NO
                                </label>
                                <div className="text-danger col-md-6 ">{YesNoError2}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h4 class="panel-title" style={{ padding: "10px" }}>EDUCATION</h4>
                      <div class="panel-body">
                        <div class="form-horizontal">
                          <div class="form-group" >
                            <div class="col-md-12">
                              <label style={{ fontWeight: "600" }} >PROFESSIONAL DEGREE (MEDICAL, DENTAL, CHIROPRACTIC, ETC.)Issuing Institution:<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="PROFESSIONAL DEGREE" value={institution} onChange={(e) => setInstitution(e.target.value)} />
                              <div className="text-danger">{institutionError}</div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="procedureDate" class="col-sm-1 control-label " style={{ textAlign: "start", fontWeight: "600" }}>ADDRESS<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                            <div class="col-sm-11">
                              <TextField type="text"
                                fullWidth
                                placeholder="ADDRESS"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                              <div className="text-danger">{addressError}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>CITY<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="CITY" value={city2} onChange={(e) => setCity2(e.target.value)} />
                              <div className="text-danger">{cityError2}</div>
                            </div>

                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>STATE/COUNTRY<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="STATE/COUNTRY" value={state2} onChange={(e) => setState2(e.target.value)} />
                              <div className="text-danger">{stateError2}</div>
                            </div>
                            <div class="col-md-4">
                              <label style={{ fontWeight: "600" }} >POSTAL CODE<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="POSTAL CODE" value={postalCode2} onChange={(e) => setPostalCode2(e.target.value)} />
                              <div className="text-danger">{postalCodeError2}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-6">
                              <label style={{ fontWeight: "600" }}>DEGREE<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="DEGREE" value={degree} onChange={(e) => setDegree(e.target.value)} />
                              <div className="text-danger">{degreeError}</div>
                            </div>
                            <div class=" col-md-6">
                              <label style={{ fontWeight: "600" }}>ATTENDANCE DATES <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <TextField type="text" className='col-sm-6' placeholder="MM/DD/YYYY" value={from1} onChange={(e) => setFrom1(e.target.value)} />
                                <div className="text-danger">{fromError1}</div>
                                <h4 className="" style={{ padding: "5px" }}>TO</h4>
                                <TextField type="text" className='col-sm-6' placeholder="MM/DD/YYYY" value={to1} onChange={(e) => setTo1(e.target.value)} />
                                <div className="text-danger">{toError1}</div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div className="col-md-2">
                              <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                              {checked && <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                              >
                                Upload file
                                <VisuallyHiddenInput type="file" />
                              </Button>}
                            </div>
                            <div class=" col-md-10">
                              <h4>Please check this box and complete and submit Attachment A if you received other professional degrees.</h4>
                            </div>
                          </div>
                          <div class="form-group" style={{ paddingTop: "30px" }}>
                            <div className="col-md-8">
                              <label htmlFor="exampleInputName2" style={{ fontWeight: "600" }}>POST-GRADUATE EDUCATION<span style={{ fontWeight: "bold", color: "red" }}>*</span></label>
                              <div>
                                <label className="col-md-3">
                                  <input type="radio" name="Internship" PGEducation="Internship" checked={PGEducation === "Internship"} onChange={handlePGEducationChange} /> Internship
                                </label>
                                <label className="col-md-3">
                                  <input type="radio" name="Residency" PGEducation="Residency" checked={PGEducation === "Residency"} onChange={handlePGEducationChange} /> Residency
                                </label>
                                <label className="col-md-3">
                                  <input type="radio" name="Fellowship" PGEducation="Fellowship" checked={PGEducation === "Fellowship"} onChange={handlePGEducationChange} /> Fellowship
                                </label>
                                <label className="col-md-3">
                                  <input type="radio" name="Teaching Appointment" PGEducation="Teaching Appointment" checked={PGEducation === "Teaching Appointment"} onChange={handlePGEducationChange} /> Teaching Appointment
                                </label>
                                <div className="text-danger col-md-6 ">{PGEducationError}</div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <label style={{ fontWeight: "600" }} >SPECIALTY<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="SPECIALTY" value={speciality} onChange={(e) => setSpeciality(e.target.value)} />
                              <div className="text-danger">{specialityError}</div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="procedureDate" class="col-sm-1 control-label " style={{ textAlign: "start", fontWeight: "600" }}>INSTITUTION<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                            <div class="col-sm-11">
                              <TextField type="text"
                                fullWidth
                                placeholder="INSTITUTION"
                                value={institutionAddress}
                                onChange={(e) => setInstitutionAddress(e.target.value)}
                              />
                              <div className="text-danger">{institutionAddressError}</div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="procedureDate" class="col-sm-1 control-label " style={{ textAlign: "start", fontWeight: "600" }}>ADDRESS<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                            <div class="col-sm-11">
                              <TextField type="text"
                                fullWidth
                                placeholder="ADDRESS"
                                value={address1}
                                onChange={(e) => setAddress1(e.target.value)}
                              />
                              <div className="text-danger">{addressError1}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>CITY<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="CITY" value={city3} onChange={(e) => setCity3(e.target.value)} />
                              <div className="text-danger">{cityError3}</div>
                            </div>

                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>STATE/COUNTRY<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="STATE/COUNTRY" value={state3} onChange={(e) => setState3(e.target.value)} />
                              <div className="text-danger">{stateError3}</div>
                            </div>
                            <div class="col-md-4">
                              <label style={{ fontWeight: "600" }} >POSTAL CODE<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="POSTAL CODE" value={postalCode3} onChange={(e) => setPostalCode3(e.target.value)} />
                              <div className="text-danger">{postalCodeError3}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div >
                              <div className="col-md-1" style={{ top: "30px" }}>
                                <Checkbox
                                  checked={checked1}
                                  onChange={handleChange1}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              </div>
                              <div class=" col-md-3" style={{ top: "28px" }}>
                                <h4>Program successfully completed.</h4>
                              </div>
                            </div>
                            <div class=" col-md-8">
                              <label style={{ fontWeight: "600" }}>ATTENDANCE DATES <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <TextField type="text" className='col-sm-6' placeholder="MM/DD/YYYY" value={from2} onChange={(e) => setFrom2(e.target.value)} />
                                <div className="text-danger">{fromError2}</div>
                                <h4 className="" style={{ padding: "5px" }}>TO</h4>
                                <TextField type="text" className='col-sm-6' placeholder="MM/DD/YYYY" value={to2} onChange={(e) => setTo2(e.target.value)} />
                                <div className="text-danger">{toError2}</div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-6">
                              <label style={{ fontWeight: "600" }}>PROGRAM DIRECTOR<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="PROGRAM DIRECTOR" value={programDirector} onChange={(e) => setProgramDirector(e.target.value)} />
                              <div className="text-danger">{programDirectorError}</div>
                            </div>

                            <div class=" col-md-6">
                              <label style={{ fontWeight: "600" }}>CURRENT PROGRAM DIRECTOR (IF KNOWN)<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="CURRENT PROGRAM DIRECTOR" value={currentProgramDirector} onChange={(e) => setCurrentProgramDirector(e.target.value)} />
                              <div className="text-danger">{currentProgramDirectorError}</div>
                            </div>
                          </div>
                          <div class="form-group" style={{ paddingTop: "30px" }}>
                            <div className="col-md-8">
                              <label htmlFor="exampleInputName2" style={{ fontWeight: "600" }}>POST-GRADUATE EDUCATION<span style={{ fontWeight: "bold", color: "red" }}>*</span></label>
                              <div>
                                <label className="col-md-2">
                                  <input type="radio" name="Internship" PGEducation1="Internship" checked={PGEducation1 === "Internship"} onChange={handlePGEducationChange1} /> Internship
                                </label>
                                <label className="col-md-2">
                                  <input type="radio" name="Residency" PGEducation1="Residency" checked={PGEducation1 === "Residency"} onChange={handlePGEducationChange1} /> Residency
                                </label>
                                <label className="col-md-2">
                                  <input type="radio" name="Fellowship" PGEducation1="Fellowship" checked={PGEducation1 === "Fellowship"} onChange={handlePGEducationChange1} /> Fellowship
                                </label>
                                <label className="col-md-4">
                                  <input type="radio" name="Teaching Appointment" PGEducation1="Teaching Appointment" checked={PGEducation1 === "Teaching Appointment"} onChange={handlePGEducationChange1} /> Teaching Appointment
                                </label>
                                <div className="text-danger col-md-6 ">{PGEducationError1}</div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <label style={{ fontWeight: "600" }} >SPECIALTY<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="SPECIALTY" value={speciality1} onChange={(e) => setSpeciality1(e.target.value)} />
                              <div className="text-danger">{specialityError1}</div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="procedureDate" class="col-sm-1 control-label " style={{fontWeight: "600" }}>INSTITUTION<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                            <div class="col-sm-11">
                              <TextField type="text"
                                fullWidth
                                placeholder="INSTITUTION"
                                value={institutionAddress1}
                                onChange={(e) => setInstitutionAddress1(e.target.value)}
                              />
                              <div className="text-danger">{institutionAddressError1}</div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="procedureDate" class="col-sm-1 control-label " style={{fontWeight: "600" }}>ADDRESS<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                            <div class="col-sm-11">
                              <TextField type="text"
                                fullWidth
                                placeholder="ADDRESS"
                                value={address2}
                                onChange={(e) => setAddress2(e.target.value)}
                              />
                              <div className="text-danger">{addressError2}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>CITY<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="CITY" value={city3} onChange={(e) => setCity3(e.target.value)} />
                              <div className="text-danger">{cityError3}</div>
                            </div>

                            <div class=" col-md-4">
                              <label style={{ fontWeight: "600" }}>STATE/COUNTRY<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="STATE/COUNTRY" value={state3} onChange={(e) => setState3(e.target.value)} />
                              <div className="text-danger">{stateError3}</div>
                            </div>
                            <div class="col-md-4">
                              <label style={{ fontWeight: "600" }} >POSTAL CODE<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="POSTAL CODE" value={postalCode4} onChange={(e) => setPostalCode4(e.target.value)} />
                              <div className="text-danger">{postalCodeError4}</div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div >
                              <div className="col-md-1" style={{ top: "30px" }}>
                                <Checkbox
                                  checked={checked1}
                                  onChange={handleChange1}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              </div>
                              <div class=" col-md-3" style={{ top: "28px" }}>
                                <h4>Program successfully completed.</h4>
                              </div>
                            </div>
                            <div class=" col-md-8">
                              <label style={{ fontWeight: "600" }}>ATTENDANCE DATES <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <TextField type="text" className='col-sm-6' placeholder="MM/DD/YYYY" value={from3} onChange={(e) => setFrom3(e.target.value)} />
                                <div className="text-danger">{fromError3}</div>
                                <h4 className="" style={{ padding: "5px" }}>TO</h4>
                                <TextField type="text" className='col-sm-6' placeholder="MM/DD/YYYY" value={to3} onChange={(e) => setTo3(e.target.value)} />
                                <div className="text-danger">{toError3}</div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group" >
                            <div class=" col-md-6">
                              <label style={{ fontWeight: "600" }}>PROGRAM DIRECTOR<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="PROGRAM DIRECTOR" value={programDirector1} onChange={(e) => setProgramDirector1(e.target.value)} />
                              <div className="text-danger">{programDirectorError1}</div>
                            </div>

                            <div class=" col-md-6">
                              <label style={{ fontWeight: "600" }}>CURRENT PROGRAM DIRECTOR (IF KNOWN)<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField type="text" className='col-sm-12' placeholder="CURRENT PROGRAM DIRECTOR" value={currentProgramDirector1} onChange={(e) => setCurrentProgramDirector1(e.target.value)} />
                              <div className="text-danger">{currentProgramDirectorError1}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                <div class=" col-md-10">
                <Stack spacing={2}>
                  <Pagination count={10} shape="rounded" style={{ display: "flex", justifyContent: "center" }} />
                  {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}
                </Stack>
                </div>
                <div class=" col-md-2" style={{ display: "flex", justifyContent:"space-evenly" }}>
                <button className='btn btn-success' style={{borderRadius:"3px"}}>Save</button>
                <button className='btn btn-primary' style={{borderRadius:"3px"}}>Next</button>
                </div>
                </div>
              </div>
              
              
              
              
              
              {/* <div id="main-wrapper">
                <div class="row">
                  <div>
                    <div class="panel panel-white">
                      <Accordion >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >

                          <h4 class="panel-title"  >Demographics</h4>
                        </AccordionSummary>
                        <AccordionDetails>

                          <div class="panel-body">
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label >First Name<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <TextField type="text" className='col-sm-12' placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                <div className="text-danger">{firstNameError}</div>
                              </div>

                              <div class="form-group col-md-6">
                                <label >Last Name<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <TextField type="text" className='col-sm-12' placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                <div className="text-danger">{lastNameError}</div>
                              </div>

                              <div className="form-group col-md-6">
                                <label htmlFor="exampleInputName2">Sex<span style={{ fontWeight: "bold", color: "red" }}>*</span></label>
                                <div>
                                  <label className="col-md-3">
                                    <input type="radio" name="gender" value="Male" checked={gender === "Male"} onChange={handleGenderChange} /> Male
                                  </label>
                                  <label className="col-md-3">
                                    <input type="radio" name="gender" value="Female" checked={gender === "Female"} onChange={handleGenderChange} /> Female
                                  </label>
                                  <label className="col-md-3">
                                    <input type="radio" name="gender" value="Others" checked={gender === "Others"} onChange={handleGenderChange} /> Others
                                  </label>
                                  <div className="text-danger col-md-6 ">{genderError}</div>
                                </div>

                              </div>

                              < div class=" col-md-6 ">
                                <label for="exampleInputName">DOB<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <TextField type="date" value={dob} onChange={(e) => setdob(e.target.value)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        inputProps: {
                                          max: getCurrentDate() // Set the max attribute to the current date
                                        }
                                      }} />
                                  </FormControl>
                                  <div className="text-danger">{dobError}</div>
                                </Box>
                              </div>
                            </div>

                            <div className='row'>
                              <div className=" form-group col-md-6">
                                <label >Address<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <div>
                                  <TextField type="text" className='col-sm-12' placeholder="Address" value={address} onChange={(e) => setaddress(e.target.value)} />
                                  <div className="text-danger">{addressError}</div>
                                </div>
                              </div>

                              <div class="form-group col-md-6">
                                <label >Street<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <div>
                                  <TextField type="text" className='col-sm-12' placeholder="Street" value={street} onChange={(e) => setstreet(e.target.value)} />
                                  <div className="text-danger">{streetError}</div>
                                </div>
                              </div>

                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label for="exampleInputName">City<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <TextField type="text" className='col-sm-12' placeholder="City" value={city} onChange={(e) => setcity(e.target.value)} />
                                <div className="text-danger">{cityError}</div>
                              </div>


                              <div class="form-group  col-md-6">
                                <label for="exampleInputName2">State/Region<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <TextField type="text" className=" col-md-12" placeholder="State/Region" value={state} onChange={(e) => setstate(e.target.value)} />
                                <div className="text-danger">{stateError}</div>
                              </div>
                            </div>



                            <div className='row'>
                              <div class="form-group col-md-6">
                                <label for="exampleInputName">Country<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <TextField type="text" className=" col-md-12" placeholder="Country" value={country} onChange={(e) => setcountry(e.target.value)} />
                                <div className="text-danger">{countryError}</div>
                              </div>
                              <div class="form-group  col-md-6">
                                <label for="exampleInputName2">Zip code<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <TextField type="text" className='col-sm-12' placeholder="Zip code" value={zipCode} onChange={(e) => {
                                  const inputZip = e.target.value;
                                  // Ensure only up to 6 digits are allowed for zip code
                                  if (inputZip.length <= 6 && /^\d*$/.test(inputZip)) {
                                    setzipCode(inputZip);
                                  }
                                }} />
                                <div className="text-danger">{zipCodeError}</div>
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                  </div>
                </div>
              </div> */}

              {/* <div id="main-wrapper">
                <div class="row">
                  <div
                  >
                    <div class="panel panel-white">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >

                          <h4 class="panel-title"  >Incident  Details</h4>
                        </AccordionSummary>
                        <AccordionDetails>

                          <div class="panel-body">
                            <div class="form-horizontal">

                              <div class="form-group">
                                <label for="surgeryType" class="col-sm-2 control-label " style={{ textAlign: "start" }}>Incident Date <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <div className="col-md-10">
                                  <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                      <TextField type="date" value={incidentdate} onChange={(e) => setIncidentdate(e.target.value)}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        InputProps={{
                                          inputProps: {
                                            max: getCurrentDate() 
                                          }
                                        }} />
                                    </FormControl>
                                    <div className="text-danger">{incidentdateError}</div>
                                  </Box>
                                </div>


                              </div>

                              <div class="form-group">
                                <label for="surgeryType" class="col-sm-2 control-label " style={{ textAlign: "start" }}> Incident Time(Military Time) </label>
                                <div class="col-sm-10">
                                  <TextField type="text" fullWidth placeholder="Incident time" value={incidentTime} onChange={(e) => setIncidentTime(e.target.value)} />
                                </div>
                              </div>

                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label " style={{ textAlign: "start" }}>State Where Incident Occurred at<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <div class="col-sm-10">
                                  <TextField type="text" fullWidth placeholder="State Where Incident Occurred" value={stateincidentoccured} onChange={(e) => setStateIncidentOccured(e.target.value)} />
                                  <div className="text-danger">{stateincidentoccuredError}</div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label " style={{ textAlign: "start" }}>Site Where Incident Occured <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <div class="col-sm-10">
                                  <TextField type="text" fullWidth placeholder="Site Where Incident Occured" value={siteincidentoccured} onChange={(e) => setSiteIncidentOccured(e.target.value)} />
                                  <div className="text-danger">{siteincidentoccuredError}</div>
                                </div>
                              </div>


                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label " style={{ textAlign: "start" }}>Department Where Incident Occured <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <div class="col-sm-10">
                                  <TextField type="text" fullWidth placeholder='Department Where Incident Occured' value={deptincidentoccured} onChange={(e) => setDeptIncidentOccured(e.target.value)} />
                                  <div className="text-danger">{deptincidentoccuredError}</div>
                                </div>
                              </div>


                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label " style={{ textAlign: "start" }}>File Owner </label>
                                <div class="col-sm-10">
                                  <TextField type="text" placeholder='File Owner' fullWidth value={fileOwner} onChange={(e) => setFileOwner(e.target.value)} />
                                  <div className="text-danger">{fileOwnerError}</div>
                                </div>
                              </div>


                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label " style={{ textAlign: "start" }}>Entered Date</label>
                                <div className="col-md-10">
                                  <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                      <label >From date </label>
                                      <TextField placeholder='Entered Date' style={{ color: "black" }} type="date" value={entereddate} onChange={(e) => setEntereddate(e.target.value)}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        InputProps={{
                                          inputProps: {
                                            max: getCurrentDate() 
                                          }
                                        }} />
                                    </FormControl>
                                  </Box>
                                </div>
                                <div className="text-danger">{enteredDateError}</div>
                              </div>


                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label " style={{ textAlign: "start" }}>Entered Time (Estern Time Zone)</label>
                                <div class="col-sm-10">
                                  <TextField type="text" placeholder='Entered Time' fullWidth value={enteredTime} onChange={(e) => setEnteredTime(e.target.value)} />
                                  <div className="text-danger">{enteredTimeError}</div>
                                </div>
                              </div>


                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label " style={{ textAlign: "start" }}>Entered By</label>
                                <div class="col-sm-10">
                                  <TextField type="text" placeholder='Entered By' fullWidth value={enteredBy} onChange={(e) => setEnteredBy(e.target.value)} />
                                  <div className="text-danger">{enteredByError}</div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label " style={{ textAlign: "start" }}>Wittness Name</label>
                                <div class="col-sm-10">
                                  <TextField type="text" placeholder='Wittness Name' fullWidth value={wittnessName} onChange={(e) => setWittnessName(e.target.value)} />
                                  <div className="text-danger">{wittnessNameError}</div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label " style={{ textAlign: "start" }}>Wittness Address </label>
                                <div class="col-sm-10">
                                  <TextField type="text" placeholder='Wittness Address' fullWidth value={wittnessAddress} onChange={(e) => setWittnessAddress(e.target.value)} />
                                  <div className="text-danger">{wittnessAddressError}</div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label " style={{ textAlign: "start" }}>Wittness Phone</label>
                                <div class="col-sm-10">
                                  <TextField type="text" placeholder='Wittness Phone' fullWidth value={wittnessPhone} onChange={(e) => setWittnessPhone(e.target.value)} />
                                  <div className="text-danger">{wittnessPhoneError}</div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label " style={{ textAlign: "start" }}>Attachment</label>
                                <div class="col-sm-10">
                                  <input type="file" fullWidth style={{ marginTop: "10px" }} />
                                  <FileUploadButton />
                                </div>
                              </div>

                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                  </div>
                </div>
              </div> */}
              {/* <div id="main-wrapper">
                <div class="row">
                  <div
                  
                  >
                    <div class="panel panel-white">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >

                          <h4 class="panel-title">Special Incident Information</h4>
                        </AccordionSummary>
                        <AccordionDetails>

                          <div class="panel-body">
                            <div class="form-horizontal">


                              <div class="form-group">
                                <label for="exampleInputPassword1" class="col-sm-2 control-label " >Department</label>
                                <div >
                                  <Autocomplete className="col-sm-10"
                                    disablePortal
                                    options={departmentsList}
                                    renderInput={(params) => <TextField fullWidth {...params} />} />
                                </div>
                              </div>

                              <div class="form-group" >
                                <label for="exampleInputPassword1" class="col-sm-2 control-label " >Medication or/Fluid Details</label>
                                <div>
                                  <Box className="col-sm-10" >
                                    <Select fullWidth placeholder="Medication or/Fluid Details" value={medicationDetails} onChange={(e) => setMedicationDetails(e.target.value)}>
                                      <MenuItem>Please Select</MenuItem>
                                      <MenuItem value="Anesthesiology">error</MenuItem>
                                      <MenuItem value="Dermatology">no error</MenuItem>
                                      <MenuItem value="Neurology">error,no harm</MenuItem>
                                      <MenuItem value="gynecology">error,harm</MenuItem>
                                      <MenuItem value="Obstetrics">error,death</MenuItem>
                                    </Select>
                                  </Box>
                                </div>
                              </div>

                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label">Patient Outcome</label>
                                <div class="col-sm-10">
                                  <TextField type="text" placeholder='Patient Outcome' fullWidth value={patientOutcome} onChange={(e) => setPatientOutcome(e.target.value)} />
                                </div>
                              </div>

                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label">Was a physician directly involved in the...</label>
                                <div class="col-sm-10">
                                  <TextField type="text" placeholder='Was a physician directly involved in the...' fullWidth value={physicianDirectlyInvolved} onChange={(e) => setPhysicianDirectlyInvolved(e.target.value)} />
                                </div>
                              </div>

                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label">Contributing Factors</label>
                                <div class="col-sm-10">
                                  <TextField type="text" placeholder='Contributing Factors' fullWidth value={contributingFactors} onChange={(e) => setContributingFactors(e.target.value)} />
                                </div>
                              </div>

                              <div class="form-group" >
                                <label for="exampleInputPassword1" class="col-sm-2 control-label " >Reported Incident Severity </label>
                                <div>
                                  <Box className="col-sm-10">
                                    <Select fullWidth placeholder='Reported Incident Severity' value={reportedIncidentSeverity} onChange={(e) => setReportedIncidentSeverity(e.target.value)}>
                                      <option >Please Select</option>
                                      <MenuItem value="Anesthesiology">Severity Level 0- Non-Safety related complaint</MenuItem>
                                      <MenuItem value="Dermatology">Severity Level 1 - Minor</MenuItem>
                                      <MenuItem value="Neurology">Severity Level 2 - Moderate </MenuItem>
                                      <MenuItem value="gynecology">Severity Level 3 - Major</MenuItem>
                                      <MenuItem value="Obstetrics">Severity Level P3 - Potential Major</MenuItem>
                                      <MenuItem value="Pathology">Severity Level 4 - Catastrophic</MenuItem>
                                      <MenuItem value="Ophthalmology">Severity Level P4-Potential Catastrophic </MenuItem>
                                    </Select>
                                  </Box>
                                </div>
                              </div>

                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label">where in the process did the incident FIR</label>
                                <div class="col-sm-10">
                                  <TextField type="text" placeholder='where in the process did the incident FIR' fullWidth value={whereInTheProcessDidTheIncidentFIR} onChange={(e) => setWhereInTheProcessDidTheIncidentFIR(e.target.value)} />
                                </div>
                              </div>

                              <div class="form-group">
                                <label for="procedureDate" class="col-sm-2 control-label " >Incident Description - use SBAR <span style={{ fontweight: "bold", color: "red" }} >*</span>(Situation,Background, Assessment,
                                  Recommendation )</label>
                                <div class="col-sm-10">
                                  <TextField type="text" placeholder='Incident Description' value={incidentdescription} onChange={(e) => setIncidentDescription(e.target.value)} fullWidth />
                                  <div className="text-danger">{incidentdescriptionError}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div style={{ marginLeft: "45%" }}>
                <Link to="/Alerts">
                <Button type="submit" class="btn btn-success">Submit</Button>
                <Button variant="contained" type='submit' style={{ height: "35px", width: "auto", fontSize: "15px", background: "#22baa0", }}> Submit </Button>

                </Link>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Section1Page1;
