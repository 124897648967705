export const routers = {
    login: "/login",
    forgot: "/forgot",
    dashboard:"/dashboard",
    Alerts:"/Alerts",
    Forms:"/Icons",
    SubmitedFiles:"/submittedfiles",
    formslist:"/forms", 
    FallEvent:"/fallevent",
    MedicalDeviceReportingEvent:"/medicaldevicereportingevent",
    TodayEvents:"/todayevents",
    MonthlyEvents:"/monthlyevents",
    AdverseDrug:"/adversedrugreactionForm",
    AirwayManagement:"/airwaysmanagementform",
    Anesthesia:"/anesthesiaform",
    CodingComplication:"/codingcomplicationsform",
    DiagnosticImaging:"/diagnosticImageForm",
    Fall:"/fallForm",
    Feedback:"/feedbackForm",
    BloodAndBloodProducts:"/bloodAndBloodProductsForm",
    LabOrSpecimen:"/labSpecimenCollectionForm",
    MedicalCareTreatment:"/medicalCareTreatmentForm",
    MedicalDeviceReporting:"/medicalDeviceReportingForm",
    MedicationOrChemotherpy:"/medicationorChemotherpyForm",
    Obstetrics:"/obstetricsForm",
    PatientBehaviour:"/patientBehaviorForm",
    PatientPrivacy:"/patientPrivacyAuthorizationForm",
    Restraints:"/restraintsForm",
    SafetySecurityFacility:"/safetySecurityFacilityForm",
    SurgeryProcedure:"/surgeryProcedureConsentForm",
    UnprofessionalBehaviour:"/unprofessionalBehaviorForm",
    UserManagement:"/usermanagement",
    Section1Page1:"/page1",
    UsersList:"/userslist",
    CreateUser:"/createuser",
    ViewPermissionSet:"/viewpermissionset/:profile_id",
    UpdateuserPermission:"/updateuserpermission/:profileId",
    EditPermissions:"/editpermissions/:profileId",
    AddPermissions:"/addpermissions/:userId",
    ProfileList:"/profilelist",
    CreateProfile:"/createprofile",
    ViewProfile:"/viewprofile/:profile_id",
    FileUploadButton:"/fileupload",
    IncidentExportExcel:"/incidentexportexcel",
    IncidentExportPDF:"/incidentexportpdf",
    AudittExportExcel:"/Aduitexportexcel",
    AduitExportPDF:"/Aduitexportpdf",
    Users:"/userlist",
    CreateUser:"/createuser",
    EditUser:"/edituser/:userId",
    Departments:"/department/list",
    departmentsadd:"/department/add",
    Departmentsedit:"/departmentsEdit/:departmentId",
    Locations:"/location/list",
    LocationsAdd:"/location/add",
    Locationsedit:"/locationEdit/:locationId",
    Roles:"/roles/list",
    PermissionsetList:"/permissionsetlist",
    WorkflowActions:"/workflowactions/:incident_id", 
    WorkflowActionsNotifications:"/workflowactions/",
    ViewPermissionSet:"/viewpermissionset/:profile_id",
    ReassignPermissionsSet:"/reassignpermissionsset/:profileId",
    IncidentAudit :"/incidentaudit",
    ObstetricsForm:"/obstetricsform",
    UserAudit:"/user-audit",
    LocationAudit:"/location-audit",
    DepartmentAudit:"/department-audit",
    PermissionsetAudit:"/permissionset-audit",
    IncidentsAudit:"/incidents-audit",
    UnauthAcess:"/unauthorized-access",
    AirwayManagementEdit: "/airwayManagementEdit/:incidentId",
    Faq:"/faq",
    Reports:"/reports",
    Privacy:"/privacy",
    Notifications:"/notifications",
    ADDPERMISSIONS:"/ADDPERMISSIONS"
};

export const languages = ["English", "Bulgarian", "Hindi", "Chinese"];

export const tabs = {
    navigation: {
        [languages[0]]: "Navigation",
        [languages[1]]: "Навигация",
        [languages[2]]: "नेविगेशन",
        [languages[3]]: "Dashboard"
    },
    dashboard: {
        [languages[0]]: "Dashboard",
        [languages[1]]: "Табло за управление",
        [languages[2]]: "Dashboard",
        [languages[3]]: "Dashboard"
    },
    // Alerts: {
    //     [languages[0]]: "Alerts",
    //     [languages[1]]: "Alerts ",
    //     [languages[2]]: " Alerts",
    //     [languages[3]]: " Alerts"
    // },
    Forms: {
        [languages[0]]: "Forms",
        [languages[1]]: "Forms ",
        [languages[2]]: "Forms",
        [languages[3]]: "Forms"
    },
    SubmitedFiles: {
        [languages[0]]: "Submitted Forms",
        [languages[1]]: "Submitted Forms ",
        [languages[2]]: " Submitted Forms",
        [languages[3]]: " Submitted Forms"
    },
    UserManagement: {
        [languages[0]]: "User Management",
        [languages[1]]: "User Management ",
        [languages[2]]: "User Management",
        [languages[3]]: "User Management"
    },
    Roles: {
        [languages[0]]: "Roles",
        [languages[1]]: "Roles",
        [languages[2]]: "Roles",
        [languages[3]]: "Roles"

    },
    Users: {
        [languages[0]]: "Users",
        [languages[1]]: "Users",
        [languages[2]]: "Users",
        [languages[3]]:"Users"

    },
    
    
    Profile: {
        [languages[0]]: "Profile",
        [languages[1]]: "Profile",
        [languages[2]]: "Profile",
        [languages[3]]: "Profile"

    },
    Locations: {
        [languages[0]]: "Locations",
        [languages[1]]: "Locations",
        [languages[2]]: "Locations",
        [languages[3]]: "Locations"

    },
    Departments: {
        [languages[0]]: "Departments",
        [languages[1]]: "Departments",
        [languages[2]]: "Departments",
        [languages[3]]: "Departments"

    },
    PermissionsetList: {
        [languages[0]]: "Permissionset List",
        [languages[1]]: "Permissionset List ",
        [languages[2]]: "Permissionset List",
        [languages[3]]: "Permissionset List"

    },
    ViewPermissionSet: {
        [languages[0]]: "ViewPermissionset",
        [languages[1]]: "ViewPermissionset ",
        [languages[2]]: "ViewPermissionset",
        [languages[3]]: "ViewPermissionset"

    },
    AddPermissions:{
        [languages[0]]: "AddPermissions",
        [languages[1]]: "AddPermissions",
        [languages[2]]: "AddPermissions",
        [languages[3]]: "AddPermissions"
    },
    EditPermissions: {
        [languages[0]]: "EditPermissions",
        [languages[1]]: "EditPermissions",
        [languages[2]]: "EditPermissions",
        [languages[3]]: "EditPermissions"

    },
    UpdateuserPermission: {
        [languages[0]]: "UpdateuserPermission",
        [languages[1]]: "UpdateuserPermission ",
        [languages[2]]: "UpdateuserPermission",
        [languages[3]]: "UpdateuserPermission"

    },
   
    IncidentAudit: {
        [languages[0]]: "IncidentAudit",
        [languages[1]]: " IncidentAudit",
        [languages[2]]: " IncidentAudit",
        [languages[3]]: " IncidentAudit"
    },

    Reports: {
        [languages[0]]: "Reports",
        [languages[1]]: " Reports",
        [languages[2]]: " Reports",
        [languages[3]]: " Reports"
    },
    Audit: {
        [languages[0]]: "Audit",
        [languages[1]]: "Audit",
        [languages[2]]: "Audit",
        [languages[3]]: "Audit"
    },
    User: {
        [languages[0]]: "User",
        [languages[1]]: "User",
        [languages[2]]: "User",
        [languages[3]]: "User"
    },
    Location: {
        [languages[0]]: "Location",
        [languages[1]]: "Location",
        [languages[2]]: "Location",
        [languages[3]]: "Location"
    },
    Department: {
        [languages[0]]: "Department",
        [languages[1]]: "Department",
        [languages[2]]: "Department",
        [languages[3]]: "Department"
    },
    Permissionset: {
        [languages[0]]: "Permissionset",
        [languages[1]]: "Permissionset",
        [languages[2]]: "Permissionset",
        [languages[3]]: "Permissionset"
    },
    Incidents: {
        [languages[0]]: "Incidents",
        [languages[1]]: "Incidents",
        [languages[2]]: "Incidents",
        [languages[3]]: "Incidents"
    },
    

  
};



