import { BrowserRouter, Routes, Route} from "react-router-dom";
import LoginForm from "../Screens/LoginForm/LoginForm";
import AirwayManagementForm from "../Screens/submissionForms/AirwayManagement";
import AdverseDrugReactionForm from "../Screens/submissionForms/AdverseDrug";
import AnesthesiaForm from "../Screens/submissionForms/Anesthesia";
import CodingComplicationsForm from "../Screens/submissionForms/CodingComplications";
import DiagnosticImageForm from "../Screens/submissionForms/DiagnosticImaging";
import FallForm from "../Screens/submissionForms/Fall";
import UnprofessionalBehaviorForm from "../Screens/submissionForms/UnprofessionalBehavior";
import FeedbackForm from "../Screens/submissionForms/Feedback";
import BloodAndBloodProductsForm from "../Screens/submissionForms/BloodAndBloodProducts.js";
import LabSpecimenCollectionForm from "../Screens/submissionForms/LaborSpecimen";
import MedicalCareTreatmentForm from "../Screens/submissionForms/MedicalCareTreatment";
import MedicalDeviceReportingForm from "../Screens/submissionForms/MedicalDeviceReportingRecall";
import MedicationorChemotherpyForm from "../Screens/submissionForms/MedicationorChemotherpy";
import ObstetricsForm from "../Screens/submissionForms/Obstetrics";
import PatientBehaviorForm from "../Screens/submissionForms/PatientBehavior";
import PatientPrivacyAuthorizationForm from "../Screens/submissionForms/PatientPrivacy";
import RestraintsForm from "../Screens/submissionForms/Restraints";
import SafetySecurityFacilityForm from "../Screens/submissionForms/SafetySecurityFacility";
import SurgeryProcedureConsentForm from "../Screens/submissionForms/SurgeryorProcedure";
import NavigateToForms from "../Screens/nagivagateForms/navigateForms";
import AuthRouter from "../utils/AuthRouter";
import AddPermissions from "../Screens/Permissions/AddPermissions";
import { routers } from "../configuration/configurationUI";
import DashboardScreen from "../Screens/Dashboard/DashboardScreen";
import SubmittedFiles from "../Screens/SubmittedFiles/SubmittedFiles";
import PermissionSetList from "../Screens/Permissions/PermissionSetList";
import UserList from "../Screens/Users/UserList";
import CreateUser from "../Screens/Users/CreateUser";
import ProfilesList from "../Screens/Profile/ProfileList";
import CreateProfile from "../Screens/Profile/CreateProfile";
import ViewProfile from "../Screens/Profile/ViewProfile";
import FileUploadButton from "../Components/FileSubmitButton/FileUploadButton";
import ViewPermissionSet from "../Screens/Permissions/ViewPermissionSet";
import DepartmentList from "../Screens/Departments/DepartmentList";
import DepartmentAdd from "../Screens/Departments/DepartmentAdd";
import Roles from "../Screens/Roles/RolesList";
import LocationList from "../Screens/Locations/LocationList";
import LocationAdd from "../Screens/Locations/LocationAdd";
import DepartmentEdit from "../Screens/Departments/DepartmentEdit";
import UpdateuserPermission from "../Screens/Permissions/UpdateuserPermission";
import EditPermissions from "../Screens/Permissions/EditPermissions";
import ReassignPermissionsSet from "../Screens/Permissions/ReassignPermissionsSet";
import EditUser from "../Screens/Users/EditUser.js";
import LocationEdit from "../Screens/Locations/LocationEdit.js";
import Section1Page1 from "../Screens/CredentiallingApp/Section1Page1.js";
import IncidentAudit from "../Screens/Audit/IncidentAudit.js";
import WorkflowActions from "../Screens/WorkFlow/WorkFlowActions.js";
import UsersAudit from "../Screens/Audit/UserAudit.js";
import LocationsAudit from "../Screens/Audit/LocationAudit.js";
import DepartmentsAudit from "../Screens/Audit/DepartmentAudit.js";
import PermissionSetAudit from "../Screens/Audit/PermissionsetAudit.js";
import IncidentExportExcel from "../Components/IncidentExportButtons/IncidentExportExcel.js";
import IncidentExportPDF from "../Components/IncidentExportButtons/IncidentExportPDF.js";
import AuditExportExcel from "../Components/AuditExportButtons/AuditExportExcel.js";
import AuditExportPDF from "../Components/AuditExportButtons/AuditExportPDF.js";
import { commonActions, commonSelectors } from "../utils/commonstore.js";
import { apis } from "../configuration/configurationAPI.js";
import axios from "axios";
import store from "../utils/index.js";
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import AuthVerify from "../auth/auth-verify.js";
import UnauthAcess from "../Screens/UnauthorizedAccess/unauthaccess.js";
import loadingGif from "../../src/utils/loading.gif"
import FAQ from "../Screens/helpscreen/helpscreen.js";
import Reports from "../Screens/report/report.js";
import Privacy from "../Screens/Privacy/privacy.js";
import MonthlyEvents from "../Components/DashboardEvents/MonthlyEvent.js";
import TodayEvents from "../Components/DashboardEvents/TodayEvents.js";
import FallEvent from "../Components/DashboardEvents/FallEvent.js";
import MedicalDeviceReportingEvent from "../Components/DashboardEvents/MedicalDeviceReportingEvent.js";
import Notifications from "../Components/Notifications/notifications.js";


const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});



export const Router = (props) => {
    
    const [loading, setLoading] = useState(true);
    const userInfo = sessionStorage.getItem("retrievedUser")
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                if (userInfo) {
                    dispatch(commonActions.setUserInfo(sessionStorage.getItem("retrievedUser")));
                    dispatch(commonActions.setLoginUserId(sessionStorage.getItem("user_id")));
                    dispatch(commonActions.setUserPermissionsetId(sessionStorage.getItem("permission_set_id")));
                    dispatch(commonActions.setUserProfileId(sessionStorage.getItem("profile_id")));
                    dispatch(commonActions.setBaererToken(sessionStorage.getItem("token")));
                    dispatch(commonActions.setAccessInfo(sessionStorage.getItem("accessInfo")));
                } else {
                    dispatch(commonActions.setBaererToken(""));
                }
                setLoading(false);
            } catch (error) {
                console.error("Error fetching user data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
    
        return (
            <div style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
              }}>
                <img src={loadingGif} alt="Loading..." style={{ width: '50px', height: '50px' }} />
                <p style={{ marginTop: '10px', fontFamily: 'Arial, sans-serif', fontSize: '14px', fontWeight: 'bold' }}>Loading ProjectM</p>
              </div>
          );
      }

    return(
    <BrowserRouter>
    {/* <AuthVerify /> */}
        <Routes>
            <Route path={"/"} element={<LoginForm language={props.language} />} />
            <Route path={routers.dashboard} element={<AuthRouter><DashboardScreen language={props.language} /> </AuthRouter>} />
            <Route path={routers.PermissionsetList} element={<AuthRouter><PermissionSetList language={props.language} /> </AuthRouter>} />
            <Route path={routers.Locations} element={<AuthRouter><LocationList language={props.language} /> </AuthRouter>} />
            <Route path={routers.LocationsAdd} element={<AuthRouter><LocationAdd language={props.language} /> </AuthRouter>} />
            <Route path={routers.Locationsedit} element={<AuthRouter><LocationEdit language={props.language} /> </AuthRouter>} />
            <Route path={routers.Departments} element={<AuthRouter><DepartmentList language={props.language} /> </AuthRouter>} />
            <Route path={routers.Departmentsedit} element={<AuthRouter><DepartmentEdit language={props.language} /> </AuthRouter>} />
            <Route path={routers.departmentsadd} element={<AuthRouter><DepartmentAdd language={props.language} /> </AuthRouter>} />
            <Route path={routers.Roles} element={<AuthRouter><Roles language={props.language} /> </AuthRouter>} />
            <Route path={routers.IncidentExportExcel} element={<AuthRouter><IncidentExportExcel language={props.language} /> </AuthRouter>} />
            <Route path={routers.IncidentExportPDF} element={<AuthRouter><IncidentExportPDF language={props.language} /> </AuthRouter>} />
            <Route path={routers.Reports} element={<AuthRouter><Reports language={props.language} /> </AuthRouter>} />
            <Route path={routers.formslist} element={<AuthRouter><NavigateToForms language={props.language} /> </AuthRouter>} />
            <Route path={routers.Notifications} element={<AuthRouter><Notifications language={props.language} /> </AuthRouter>} />
            <Route path={routers.ADDPERMISSIONS} element={<AuthRouter><AddPermissions language={props.language} /> </AuthRouter>} />
            <Route path={routers.login} element={<AuthRouter><LoginForm language={props.language} /> </AuthRouter>} />
            <Route path={routers.formslist} element={<AuthRouter><NavigateToForms language={props.language} /> </AuthRouter>} />


{/*...... forms....... */}
           {/* SubmissionForms */}
         <Route path={routers.AirwayManagement+ "/:incident_id/:action"} element={<AuthRouter><AirwayManagementForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.AdverseDrug+ "/:incident_id/:action"} element={<AuthRouter><AdverseDrugReactionForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.Anesthesia+ "/:incident_id/:action"} element={<AuthRouter><AnesthesiaForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.CodingComplication+ "/:incident_id/:action"} element={<AuthRouter><CodingComplicationsForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.DiagnosticImaging+ "/:incident_id/:action"} element={<AuthRouter><DiagnosticImageForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.Fall+ "/:incident_id/:action"} element={<AuthRouter><FallForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.Feedback+ "/:incident_id/:action"} element={<AuthRouter><FeedbackForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.BloodAndBloodProducts+ "/:incident_id/:action"} element={<AuthRouter><BloodAndBloodProductsForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.LabOrSpecimen+ "/:incident_id/:action"} element={<AuthRouter><LabSpecimenCollectionForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.MedicalCareTreatment+ "/:incident_id/:action"} element={<AuthRouter><MedicalCareTreatmentForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.MedicalDeviceReporting+ "/:incident_id/:action"} element={<AuthRouter><MedicalDeviceReportingForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.MedicationOrChemotherpy+ "/:incident_id/:action"} element={<AuthRouter><MedicationorChemotherpyForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.Obstetrics+ "/:incident_id/:action"} element={<AuthRouter><ObstetricsForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.PatientBehaviour+ "/:incident_id/:action"} element={<AuthRouter><PatientBehaviorForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.PatientPrivacy+ "/:incident_id/:action"} element={<AuthRouter><PatientPrivacyAuthorizationForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.Restraints+ "/:incident_id/:action"} element={<AuthRouter><RestraintsForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.SafetySecurityFacility+ "/:incident_id/:action"} element={<AuthRouter><SafetySecurityFacilityForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.SurgeryProcedure+ "/:incident_id/:action"} element={<AuthRouter><SurgeryProcedureConsentForm language={props.language} /> </AuthRouter>} />
         <Route path={routers.UnprofessionalBehaviour+ "/:incident_id/:action"} element={<AuthRouter><UnprofessionalBehaviorForm language={props.language} /> </AuthRouter>} />
            <Route path={routers.dashboard} element={<AuthRouter><DashboardScreen language={props.language} /> </AuthRouter>} />
            <Route path={routers.SubmitedFiles} element={<AuthRouter><SubmittedFiles language={props.language} /> </AuthRouter>} />
            <Route path={routers.ProfileList} element={<AuthRouter><ProfilesList language={props.language} /> </AuthRouter>} />
            <Route path={routers.CreateProfile} element={<AuthRouter><CreateProfile language={props.language} /> </AuthRouter>} />
            <Route path={routers.ViewProfile} element={<AuthRouter><ViewProfile language={props.language} /> </AuthRouter>} />
            <Route path={routers.AddPermissions} element={<AuthRouter><AddPermissions language={props.language} /> </AuthRouter>} />
         <Route path={routers.FileUploadButton} element={<AuthRouter><FileUploadButton language={props.language} /> </AuthRouter>} />
         <Route path={routers.ViewPermissionSet} element={<AuthRouter><ViewPermissionSet language={props.language} /> </AuthRouter>} />
         <Route path={routers.UsersList} element={<AuthRouter><UserList language={props.language} /> </AuthRouter>} />
         <Route path={routers.CreateUser} element={<AuthRouter><CreateUser language={props.language} /> </AuthRouter>} />
         <Route path={routers.EditUser} element={<AuthRouter><EditUser language={props.language} /> </AuthRouter>} />
         <Route path={routers.UpdateuserPermission} element={<AuthRouter><UpdateuserPermission language={props.language} /> </AuthRouter>} />
         <Route path={routers.EditPermissions} element={<AuthRouter><EditPermissions language={props.language} /> </AuthRouter>} />
         <Route path={routers.ReassignPermissionsSet} element={<AuthRouter><ReassignPermissionsSet language={props.language} /> </AuthRouter>} />         
         <Route path={routers.WorkflowActions} element={<AuthRouter><WorkflowActions language={props.language}/></AuthRouter>}/>
         <Route path={routers.Section1Page1} element={<AuthRouter><Section1Page1 language={props.language} /> </AuthRouter>} />
          <Route path={routers.ObstetricsForm} element={<AuthRouter><ObstetricsForm language={props.language}/></AuthRouter>}/>
          <Route path={routers.UserAudit} element={<AuthRouter><UsersAudit language={props.language}/></AuthRouter>}/>
          <Route path={routers.IncidentsAudit} element={<AuthRouter><IncidentAudit language={props.language}/></AuthRouter>}/>
          <Route path={routers.LocationAudit} element={<AuthRouter><LocationsAudit language={props.language}/></AuthRouter>}/>
          <Route path={routers.DepartmentAudit} element={<AuthRouter><DepartmentsAudit language={props.language}/></AuthRouter>}/>
          <Route path={routers.PermissionsetAudit} element={<AuthRouter><PermissionSetAudit language={props.language}/></AuthRouter>}/>
          <Route path={routers.UnauthAcess} element={<AuthRouter><UnauthAcess language={props.language}/></AuthRouter>}/>          
          <Route path={routers.Faq} element={<AuthRouter><FAQ language={props.language} /></AuthRouter>} />
          <Route path={routers.Privacy} element={<AuthRouter><Privacy language={props.language} /></AuthRouter>} />
          <Route path={routers.MonthlyEvents} element={<AuthRouter><MonthlyEvents language={props.language}/></AuthRouter>}/>
          <Route path={routers.TodayEvents} element={<AuthRouter><TodayEvents language={props.language}/></AuthRouter>}/>
          <Route path={routers.FallEvent} element={<AuthRouter><FallEvent language={props.language}/></AuthRouter>}/>
          <Route path={routers.MedicalDeviceReportingEvent} element={<AuthRouter><MedicalDeviceReportingEvent language={props.language}/></AuthRouter>}/>
    </Routes>

    </BrowserRouter>
)}
