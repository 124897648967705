import {createSlice} from "@reduxjs/toolkit"
import { createSelector } from '@reduxjs/toolkit';

const selectCommon = (state) => state.commonStore;

export const commonSelectors = {
  selectBaererToken: createSelector(
    [selectCommon],
    (common) => common.baererToken
  ),
  selectLoginUserId: createSelector(
    [selectCommon],
    (common) => common.loginUserId
  ),
  selectUserInfo: createSelector(
    [selectCommon],
    (common) => common.userInfo
  ),
 
};

const initialState = {
  "general_incident_info": {
    "incident_type": 0,
    "patient_type": "",
    "injury_incurred": "",
    "equipment_involved": ""
  },
  "demographics_info": {
    "first_name": "",
    "last_name": "",
    "gender": 0,
    "dob": "",
    "address": "",
    "street": "",
    "city": "",
    "state": "",
    "country": "",
    "zipcode": ""
  },
  "incident_details": {
    "incident_date": "",
    "incident_time": "",
    "incident_state": "",
    "incident_site": "",
    "file_owner": "",
    "entered_date": "",
    "entered_time": "",
    "entered_by": "",
    "witness_name": "",
    "witness_phone": "",
    "witness_address":"",
  },
  "special_additional_info": {
    "department_id": 0,
    "specific_incident_type":0,
    "restraints_devices_type": "",
    "restraints_start_time": "",
    "restraints_end_time": "",
    "decision_to_use_restraint": "",
    "restraint_was": 0,
    "physician_involved": 0,
    "contributing_factors": "",
    "immediate_actions_taken": "",
    "reported_incident_severity": 0,
    "incident_description":"",
    "attachment": ""
  }
}


const restraintsSlice = createSlice({
  // name: "common",
  name: "restraints",
  initialState: initialState,
 
})

export const commonActions = restraintsSlice.actions
export default restraintsSlice
