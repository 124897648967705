import {createSlice} from "@reduxjs/toolkit"
import { createSelector } from '@reduxjs/toolkit';

const selectCommon = (state) => state.commonStore;

export const commonSelectors = {
  selectBaererToken: createSelector(
    [selectCommon],
    (common) => common.baererToken
  ),
  selectLoginUserId: createSelector(
    [selectCommon],
    (common) => common.loginUserId
  ),
  selectUserInfo: createSelector(
    [selectCommon],
    (common) => common.userInfo
  ),
 
};

const initialState = {
  "general_incident_info": {
    "incident_type": 0,
    "patient_type": "",
    "injury_incurred": "",
    "equipment_involved": ""
  },
  "demographics_info": {
    "first_name": "",
    "last_name": "",
    "gender": 0,
    "dob": null,
    "address": "",
    "street": "",
    "city": "",
    "state": "",
    "country": "",
    "zipcode": ""
  },
  "incident_details": {
    "incident_date": new Date(),
    "incident_time": "",
    "incident_state": "",
    "incident_site": "",
    "file_owner": "",
    "entered_date":new Date(),
    "entered_time": "",
    "entered_by": "",
    "witness_name": "",
    "witness_phone": ""
  },
  "special_additional_info": {
    "department": 0,
    "disclosure_purpose": "",
    "incident_date": new Date(),
    "hospitalized_On": "",
    "disclosed_info_types": "",
    "authorization_duration": "",
    "patient_reaction": 0,
    "outcome": "",
    "sequelae": "",
    "relevant_history": "",
    "other_information": "",
    "physician_involved": false,
    "contributing_factors": "",
    "immediate_actions_taken": "",
    "reported_incident_severity": "",
    "attachment": ""
  }
}


const patientPrivacyAuthSlice = createSlice({
  // name: "common",
  name: "patientPrivacyAuth",
  initialState: initialState,
 
})

export const commonActions = patientPrivacyAuthSlice.actions
export default patientPrivacyAuthSlice
