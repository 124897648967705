import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apis } from '../../configuration/configurationAPI';
import { routers } from '../../configuration/configurationUI';
import { useSelector } from "react-redux";
import { commonSelectors } from "../../utils/commonstore.js";

const Notifications = ({ isVisible, onClose }) => {
    const navigate = useNavigate();
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loggedinId = useSelector(commonSelectors.selectLoginUserId);
    const accessInfo = useSelector(commonSelectors.selectAccessInfo);
    const [notifications, setNotifications] = useState([]);
    const [viewNotifications, setViewNotifications] = useState('all');

    useEffect(() => {
        if (isVisible) {
            axios.get(apis.base + apis.GET_ALL_NOTIFICATIONS, {
                headers: {
                    userId: loggedinId,
                    Authorization: `Bearer ${baererToken}`,
                },
            }).then((response) => {
                const sortedNotifications = response.data.sort((a, b) => b.incident_activity_id - a.incident_activity_id);
                setNotifications(sortedNotifications);
            });
        }
    }, [isVisible, loggedinId, baererToken]);

    const getNotificationTitle = (notification) => {
        switch (notification.action_type) {
            case "incident_reported":
                return "New Incident Reported -" + notification.incident_id;
            case "assigned_to_rm":
                return "Incident Assigned to Risk Manager -" + notification.incident_id;
            case "assign_primary_department":
                return `Assigned to Primary Department - ${notification.incident_id}`;
            case "assign_secondary_department":
                return `Assigned Secondary Department - ${notification.incident_id}`;
            case "assign_doctor":
                return `Assigned to Doctor - ${notification.incident_id}`;
            case "assign_tertiary_department":
                return `Assigned Secondary Tertiary Department - ${notification.incident_id}`;
            case "status_change":
                return `Status Changed - ${notification.incident_id}`;
            case "comment":
                return `Comment Added to - ${notification.incident_id}`;        
            default:
                return "";
        }
    };

    const getnotificationNavigation = (notification) => {
        switch (notification.action_type) {
          case "incident_reported": return [routers.WorkflowActionsNotifications + notification.incident_id];
          case "assigned_to_rm": return [routers.WorkflowActionsNotifications + notification.incident_id];
          case "assign_primary_department": return [routers.WorkflowActionsNotifications + notification.incident_id];
          case "assign_secondary_department": return [routers.WorkflowActionsNotifications + notification.incident_id];
          case "assign_tertiary_department": return [routers.WorkflowActionsNotifications + notification.incident_id];
          case "assign_doctor": return [routers.WorkflowActionsNotifications + notification.incident_id];
          case "status_change": return [routers.WorkflowActionsNotifications + notification.incident_id];
          case "comment": return [routers.WorkflowActionsNotifications + notification.incident_id];
          default: return ""
        }
      }

      const workflows=["1000","1001","1010","1011","1100","1101","1110","1111"]

    const AllNotifications = ({ notifications }) => (
        <div>
            {notifications.map((notification, index) => (
                <div key={index} style={styles.notificationItem}>
                    <div style={styles.notificationTextContainer}>
                        <span
                            onClick={() => {
                                axios.put(apis.base + apis.PUT_NOTIFICATIONS_BY_INCIDENT_ACTIVITY_ID + notification.incident_activity_id, null, {
                                    headers: {
                                        userId: loggedinId,
                                        Authorization: `Bearer ${baererToken}`,
                                    },
                                });
                                setNotifications(prevNotifications => 
                                    prevNotifications.map(n =>
                                        n.incident_activity_id === notification.incident_activity_id
                                            ? { ...n, view_status: true }
                                            : n
                                    )
                                );
                                {workflows.includes(accessInfo?.workflow) &&(
                                navigate(...getnotificationNavigation(notification))
                            )}
                                onClose();
                            }}
                        >
                            <span style={{ ...styles.itemTitle, color: notification.view_status ? "rgb(134 175 210)" : "rgb(28 130 216)" }}>
                                {getNotificationTitle(notification)}
                            </span>
                        </span><br/>
                        <span style={styles.itemMessage}>{notification.notification_subject}</span>
                    </div>
                </div>
            ))}
        </div>
    );

    if (!isVisible) return null;

    return (
        <div style={styles.overlay}>
            <div style={styles.container}>
                <div style={styles.header}>
                    <span style={styles.headerText}>Notifications</span>
                    <span onClick={onClose} style={{ cursor: "pointer",fontWeight:"bold" }}>X</span>
                </div>

                <div style={styles.tabContainer}>
                    <span onClick={() => setViewNotifications("all")}
                        style={viewNotifications !== 'all' ? styles.tab : { ...styles.tab, ...styles.activeTab }}>
                        All
                    </span>
                    <span
                        onClick={() => setViewNotifications("unread")}
                        style={viewNotifications === 'all' ? styles.tab : { ...styles.tab, ...styles.activeTab }}
                    >
                        Unread
                    </span>
                </div>
                <div style={styles.divider} />

                <div style={styles.scrollableContent}>
                    {notifications.length === 0 ? (
                        <div style={styles.emptyContainer}>
                            <img src="https://cdn-icons-png.flaticon.com/512/6828/6828737.png" style={styles.bellImage} alt="bell" />
                            <span style={styles.emptyText}>No notification yet</span>
                            <span style={styles.subText}>You'll see notifications here when they are available</span>
                        </div>
                    ) : (
                        viewNotifications === 'all' ? <AllNotifications notifications={notifications} />
                            : <AllNotifications notifications={notifications.filter(n => !n.view_status)} />
                    )}
                </div>
            </div>
        </div>
    );
};

const styles = {
    scrollableContent: {
        flex: 1,
        overflowY: 'auto',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    container: {
        position: 'relative',
        width: '80%',
        height:"100vh",
        maxWidth: 400,
        backgroundColor: '#fff',
        padding: 20,
        boxShadow: '0 5px 10px rgba(0,0,0,0.3)',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.3,
        shadowRadius: 10,
        elevation: 10,
        zIndex: 1001,
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-end',
        
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    headerText: {
        fontSize: 20,
        fontWeight: 'bold',
        letterSpacing: 1,
    },
    tabContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
    },
    tab: {
        marginRight: 20,
        fontSize: 16,
        color: '#aaa',
        cursor: 'pointer',
    },
    activeTab: {
        color: '#000',
    },
    divider: {
        width: '100%',
        // height: 2,
        border:"1px solid",
        backgroundColor: '#ddd',
        marginBottom: 20,
    },
    emptyContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        
    },
    bellImage: {
        width: 50,
        height: 50,
        marginBottom: 20,
    },
    emptyText: {
        fontSize: 18,
        marginBottom: 10,
    },
    subText: {
        fontSize: 14,
        color: '#aaa',
    },
    notificationItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 15,
    },
    notificationTextContainer: {
        flex: 1,
        maxHeight: '100vh',
    overflowY: 'auto',
    },
    itemTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    itemMessage: {
        fontSize: 11,
    },
};

export default Notifications;
