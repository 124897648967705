import React, { useState } from "react";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Link } from "react-router-dom";

function CreateProfile(props) {
    const initialPermissions = {
        dashboard: "00000",
        alerts: "00000",
        forms: "00000",
        submittedFiles: "00000",
        reports: "00000"
    };

    const [data, setData] = useState({
        type: "profile",
        profile_name: "",
        ...initialPermissions
    });

    const permissionsArray = Object.keys(initialPermissions);

    const handleSubmit = () => {
        axios.post(apis.base + apis.POST_PROFILE_API, data)
            .then((res) => {
                setShowPopup(true);
                callback(res);
                console.log("wwwwwwwww", res)
            })
            
            .catch((err) => console.log(err));
    };

    const callback = (res) => {
    };

    const [showPopup, setShowPopup] = useState(false);

    const closePopup = () => {
        setShowPopup(false);
    };

    const setEnable = (key, permission) => {
        setData(prevData => {
            const newData = { ...prevData };
            const str = newData[key];
            const strArray = str.split('');
            strArray[permission] = '1';
            newData[key] = strArray.join('');
            return newData;
        });
    };

    return (
        <div>
            <div className="page-inner">
                <div className="panel panel-white">
                    <div className="panel-heading clearfix">
                        <h4 className="panel-title" style={{ fontWeight: "bold", marginLeft: "10px" }}>Set Up Profile</h4>
                    </div>
                    <div style={{ marginLeft: '25px' }}>
                        <label style={{ fontWeight: 'bold' }}>Profile Name</label>
                    </div>
                    <div style={{ marginLeft: "25px" }}>
                        <input type="text" value={data.profile_name} onChange={(event) => setData({ ...data, profile_name: event.target.value })} />
                    </div>

                    <div className="panel-body">
                        <div className="table-responsive">
                            <table id="example" className="display table" style={{ width: "100%" }}>
                                <thead style={{ backgroundColor: "#00cc99" }}>
                                    <tr>
                                        <th> </th>
                                        <th>TAB</th>
                                        <th>READ</th>
                                        <th>CREATE</th>
                                        <th>EDIT</th>
                                        <th>DELETE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {permissionsArray.map((permissionKey, index) => (
                                        <tr key={index}>
                                            <td>{permissionKey}</td>
                                            {[0, 1, 2, 3, 4].map((permission, index) => (
                                                <td key={index}>
                                                    <FormControlLabel
                                                        control={<Checkbox onChange={() => setEnable(permissionKey, permission)} checked={data[permissionKey][permission] === '1'} />}
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Link to="/ProfileList"><button type="button" className="btn btn-success" onClick={handleSubmit}>Submit</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateProfile;