import {createSlice} from "@reduxjs/toolkit"
import { createSelector } from '@reduxjs/toolkit';

const selectCommon = (state) => state.commonStore;

export const commonSelectors = {
  selectBaererToken: createSelector(
    [selectCommon],
    (common) => common.baererToken
  ),
  selectLoginUserId: createSelector(
    [selectCommon],
    (common) => common.loginUserId
  ),
  selectUserInfo: createSelector(
    [selectCommon],
    (common) => common.userInfo
  ),
 
};

const initialState = {
  "general_incident_info": {
    "incident_type": 0,
    "patient_type": 0,
    "injury_incurred": "",
    "equipment_involved": ""
  },
  "demographics_info": {
    "first_name": "",
    "last_name": "",
    "gender": 0,
    "dob": null,
    "address": "",
    "street": "",
    "city": "",
    "state": "",
    "country": "",
    "zipcode": ""
  },
  "incident_details": {
    "incident_date": new Date(),
    "incident_time": "",
    "incident_state": 0,
    "incident_site": 0,
    "file_owner": "",
    "entered_date": new Date(),
    "entered_time": "",
    "entered_by": "",
    "witness_name": "",
    "witness_phone": "",
    "witness_address":"",
  },
  "special_incident_info": {
    "department_id": 0,
    "specific_incident_type": 0,
    "reaction_type": "",
    "medication_details": "",
    "patient_weight": "",
    "treatment_of_reaction": 0,
    "outcome": "",
    "present_on_admission": "",
    "adr_reported_externally":false,
    "adr_preventable": false,
    "physician_involved": false,
    "contributing_factors": "",
    "immediate_actions_taken": "",
    "reported_incident_severity": 0,
    "casuality_or_probability": "",
    "incident_description": "",
    "attachment": ""
  }
    
}

const adverseDrugSlice = createSlice({
    // name: "common",
    name: "adverseDrug",
    initialState: initialState,
   
})

export const commonActions = adverseDrugSlice.actions
export default adverseDrugSlice
