import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const ChatIcon = ({ onClick }) => {
    return (
        <div style={styles.icon} onClick={onClick}>
            <FontAwesomeIcon icon={faRobot} style={{ color: 'gray', marginRight: '10px' }} />
        </div>
    );
};

const styles = {
    icon: {
        // position: 'fixed',
        top: '5px',
        right: '180px',
        fontSize: '30px',
        cursor: 'pointer'
    }
};

export default ChatIcon;