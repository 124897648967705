import React from "react";
import { Link, NavLink } from "react-router-dom";

export default function MyNavItem(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = (el) => {
    setAnchorEl(el);
    props.setOpenIndex(true);
  };

  const openOrClose = (el) => {
    setAnchorEl(el);
    props.setOpenIndex(!props.open);
  };

  const close = (el) => {
    setAnchorEl(null);
    props.setOpenIndex(false);
  };

  const paperStyle = {
    position: "absolute",
    zIndex: 1,
    marginTop: "8px",
  };

  const menuLinkStyle = {
    display: "grid",
    alignItems: "center",
    cursor: "pointer",
    // marginLeft:'24px',
    color:'#899dc1',
    textAlign:'center',
  };

  const menuText = {
    // marginRight: "8px",
    // marginLeft:'43px',
  };

  const menuItemStyle = {
    marginBottom: "8px",
  };

  if (Object.keys(props.tabs).length > 0) {
    if (props.isSimpleNav) {
      return (
        <div className="menu-item" style={menuItemStyle}>
          <div
            className="menu-link"
            onMouseEnter={(event) => open(event.currentTarget)}
            style={menuLinkStyle}
          >
            <span className="menu-icon" style={{ marginRight: "8px" }}>
              <i className={props.icon} />
            </span>
            <span className="menu-caret">
              <b className="fa fa-caret-down" />
            </span>
          </div>
          {props.open && (
            <div style={paperStyle}>
              {Object.keys(props.tabs).map((key, index) => (

                <NavLink

                  key={index}
                  onClick={(event) => close(event.currentTarget)}
                  to={props.tabs[key]}
                  // className="dropdown-item d-flex align-items-center"
                  activeClassName="active"
                >
                  <span className="menu-text">{key}</span>
                </NavLink>
              ))}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="menu-item" style={menuItemStyle}>
          <div
            className="menu-link"
            onClick={(event) => openOrClose(event.currentTarget)}
            style={menuLinkStyle}
          >
            <span className="menu-icon" style={{fontSize:'18px'}}>
              <i className={props.icon} />
            </span>
            <br></br>
            <span className="menu-text" style={menuText}>
              {props.title}
              <i class="fa fa-angle-right" style={{fontSize:'20px',marginLeft:'10px'}}></i>
            </span>
          
          </div>
          {props.open && (
            <div className="collapse-group">
              {Object.keys(props.tabs).map((val, index) => (
                <NavLink
                  key={index}
                  to={props.tabs[Object.keys(props.tabs)[index]]}
                  className="menu-link"
                  // activeclassname="active"
                >
                  <span className="menu-text">{Object.keys(props.tabs)[index]}</span>
                </NavLink>
              ))}
            </div>
          )}
        </div>
      );
    }
  } else {
    return (
      <div className="menu-item" style={menuItemStyle}>
        <NavLink
          to={props.mainTab}
          className="menu-link"
          onClick={(event) => close(event.currentTarget)}
          style={menuLinkStyle}
        >
          <span className="menu-icon" >
            <i className={props.icon} />
          </span>
          {props.isSimpleNav ? "" : <span className="menu-text">{props.title}</span>}
        </NavLink>
      </div>
    );
  }
}


