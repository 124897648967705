import {createSlice} from "@reduxjs/toolkit"
import { createSelector } from '@reduxjs/toolkit';

const selectAirwayManagement = (state) => state.airwayManagementStore;

export const airwayManagementSelectors = {
  selectAirwatManagement: createSelector(
    [selectAirwayManagement],
    (airwayManagement) => airwayManagement.airwayManagement
  ),
  
 
};

const initialState = {
  "general_incident_info": {
    "incident_type": 0,
    "patient_type": 0,
    "injury_incurred": "",
    "equipment_involved": ""
  },
  "demographics_info": {
    "first_name": "",
    "last_name": "",
    "gender": "",
    "dob": "",
    "address": "",
    "street": "",
    "city": "",
    "state": "",
    "country": "",
    "zipcode": ""
  },
  "incident_details": {
    "incident_date": new Date(),
    "incident_time": "",
    "incident_state": 0,
    "incident_site": 0,
    "file_owner": "",
    "entered_date": new Date(),
    "entered_time": "",
    "entered_by": "",
    "witness_name": "",
    "witness_address": "",
    "witness_phone": ""
  },

  "special_additional_info": {
    "specific_incident_type": 0,
    "department_id": 0,
    "type_of_lineOrtube": 0,
    "type_of_restraints": 0,
    "physician_involved": false,
    "contributing_factors": "",
    "immediate_actions_taken": "",
    "reported_incident_severity": 0,
    "incident_description": "",
    "feedback": false,
    "attachment": ""
  }
}

const airwayManagementSlice = createSlice({
  // name: "common",
  name: "airwayManagement",
  initialState: initialState,
 
})

export const commonActions = airwayManagementSlice.actions
export default airwayManagementSlice
