import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { commonSelectors } from '../../utils/commonstore';
import store from "../../utils/index";

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});
const BarGraph1 = () => {
  const [barData, setBarData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Bar Graph',
        data: [],
        backgroundColor: ['#f0e67d', '#8B8883', '#e86b84', '#f0ac78', '#78baf0', '#f3f3f3', '#3f4754', '#3e8745', '#383820', '#999918', '#380334'],
      },
    ],
  });
  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loggedinId = useSelector(commonSelectors.selectLoginUserId);
  const userInfo = useSelector(commonSelectors.selectUserInfo);



  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);
  const fetchData = async () => {
    try {
      const response = await axios.post(
        apis.base + apis.GET_INCIDENT_FILTER_INCIDENT_COUNT_REPORT_API,
        {
          "is_report": true
        },
        {
          headers: {
            userid: loggedinId,
            Authorization: "Bearer " + baererToken
          }
        }
      );

      const data = response.data;

      
      const years = data.map((el) => Object.keys(el)[0]);
      const counts = data.map((el) => Object.values(el)[0].length);

      
      years.sort((a, b) => a - b);

      
      const filteredCounts = years.map((year) => {
        const index = data.findIndex((el) => Object.keys(el)[0] === year);
        return index !== -1 ? Object.values(data[index])[0].length : 0;
      });

     
      setBarData((prevBarData) => ({
        ...prevBarData,
        labels: years,
        datasets: [
          {
            ...prevBarData.datasets[0],
            data: filteredCounts,
          },
        ],
      }));
    } catch (error) {
      console.error('Error fetching real-time data:', error);
    }
  };


  const handleFilterButtonClick = () => {
    setShowFilters(!showFilters);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, 
  };
  
  return (

    <div>
      <div style={{ textAlign: 'center' }}>
      </div>
     <div style={{ position: "relative", height: "300px", width: "100%",top:"100px"}}>
      <Bar data={barData} options={options}/>
    </div>
    </div>
  );
};

export default BarGraph1;

