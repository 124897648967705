import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import {useSelector} from "react-redux";
import { commonSelectors } from '../../utils/commonstore';
import store from "../../utils/index";

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});


const GroupedBarChart = () => {
  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loggedinId = useSelector(commonSelectors.selectLoginUserId);
  const [barData, setBarData] = useState({
    labels: ['2020', '2021', '2022', '2023', '2024'],
    datasets: [
      {
        label: 'LOW',
        data: [],
        backgroundColor: 'rgba(255, 99, 132, 0.7)',
        barPercentage: 0.8,
      },
      {
        label: 'MEDIUM',
        data: [],
        backgroundColor: 'rgba(54, 162, 235, 0.7)',
        barPercentage: 0.8,
      },
      {
        label: 'HIGH',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.7)',
        barPercentage: 0.8,
      },
    ],
  });


  React.useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await axios.post(
          apis.base + apis.GET_INCIDENT_FILTER_INCIDENT_COUNT_REPORT_API,
          {
            "based_on": "severity",
            "is_report": false
          },
          {
            headers: {
              userid: loggedinId,
              Authorization:"Bearer "+ baererToken
          }
          }
        );
  
        var years = response.data.map(el => Object.keys(el)[0]);
        years.sort((a, b) => a - b);
        
        var curr_year = new Date().getFullYear();
        var itr = 1;
        var my_years = [];
      
        while (itr <= 5) {
        my_years.push(curr_year);
        itr++;
        curr_year--;
         }
      
       my_years.reverse(); 
      
      var reqsets = [];
        
        

       
        for (var rt of response.data) {
          mydata = rt.info.filter((gh) => gh.incident_year == curr_year);
        
          const colors = [];


          var mydata = [];
          for (var my_year of my_years) {
            var temp_data = rt.info.filter((sd) => sd.incident_year == my_year);
            if (temp_data.length > 0) { 
              mydata.push(temp_data.length);
              
            }
            mydata.push(temp_data.length);
           
          }
          if (mydata.length > 0) { 
          var colorIndex = reqsets.length % colors.length; 
          var myOb = {
            label: rt.incident_severity,
            data: mydata,
            backgroundColor: colors[colorIndex],
            barPercentage: 0.8, 
          };
        }
          reqsets.push(myOb);
        }

        setBarData((prevBarData) => ({
                  ...prevBarData,
                  labels:my_years,
                  datasets:reqsets
                }));

      } catch (error) {
        console.error('Error fetching real-time data:', error);
      }
    };

    fetchData();

  },[])





  const options = {
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false, 
  };


  return (
    <div style={{ position: "relative", height: "250px", width: "100%" }}>
      <Bar data={barData} options={options} />
    </div>
  );
};

export default GroupedBarChart;
