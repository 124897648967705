import {createSlice} from "@reduxjs/toolkit"
import { createSelector } from '@reduxjs/toolkit';

const selectCommon = (state) => state.commonStore;

export const commonSelectors = {
  selectBaererToken: createSelector(
    [selectCommon],
    (common) => common.baererToken
  ),
  selectLoginUserId: createSelector(
    [selectCommon],
    (common) => common.loginUserId
  ),
  selectUserProfileId: createSelector(
    [selectCommon],
    (common) => common.userProfileId
  ),
  selectUserPermissionsetId: createSelector(
    [selectCommon],
    (common) => common.userPermissionsetId
  ),
  selectUserInfo: createSelector(
    [selectCommon],
    (common) => common.userInfo
  ),
  selectAccessInfo: createSelector(
    [selectCommon],
    (common) => common.accessInfo
  ),

  selectEditIncidentData: createSelector(
    [selectCommon],
    (common) => common.editIncidentData
  ),

  selectViewIncidentData: createSelector(
    [selectCommon],
    (common) => common.viewIncidentData
  ),

};

const initialState = {
    searchKeyword:"", 
    userInfo:{},
    accessInfo:{},
    baererToken:"",
    loginUserId:0,
    userProfileId:0,
    userPermissionsetId:0,
    editIncidentData:{},
    viewIncidentData:{}
    
}

const commonSlice = createSlice({
    name: "common",
    initialState: initialState,
    reducers: {
        setSearchKeyword: (state, action) => {
            state.searchKeyword=action.payload
        },
        setUserInfo: (state, action) => {
          state.userInfo=action.payload
         } ,
        setAccessInfo: (state, action) => {
        state.accessInfo=action.payload
        } ,
        setBaererToken: (state, action) => {
            state.baererToken=action.payload
        } ,
        
        setLoginUserId: (state, action) => {
          state.loginUserId=action.payload
        } ,
        setUserProfileId: (state, action) => {
        state.userProfileId=action.payload
         } ,
         setUserPermissionsetId: (state, action) => {
          state.userPermissionsetId=action.payload
           } ,
        setEditIncidentData: (state, action) => {
          state.editIncidentData=action.payload
      } ,
      setViewIncidentData: (state, action) => {
        state.viewIncidentData=action.payload
    } ,

       
    }
})

export const commonActions = commonSlice.actions
export default commonSlice