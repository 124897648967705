import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import { routers } from "../../configuration/configurationUI";
import { Modal, Snackbar, Alert, TextField } from "@mui/material";
import { ClickAwayListener } from "@material-ui/core";
import { Button } from "react-scroll";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';


function LocationAdd() {

    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);

    const [data, setData] = useState({

        "location_name": "",
        "address": "",
        "state": "",
        "is_deleted": false,
        "created_by": loginUserId,
        "updated_by": loginUserId,
        "created_timestamp": "",
        "updated_timestamp": ""

    });

    const Navigate = useNavigate();
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success", // Can be "error", "warning", "info", "success"
    });
    const [errors, setErrors] = useState({
        location_name: '',
        address: '',
        state: "",
    });
    const [successMessage, setSuccessMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {

        let errors = {};
        let isValid = true;

        if (!data.location_name.trim()) {
            errors.location_name = 'Location name is required';
            isValid = false;
        } else if (!/^[A-Za-z\s]+$/.test(data.location_name)) {
            errors.location_name = 'Location name should contain only alphabets';
            isValid = false;
        }

        if (!data.address.trim()) {
            errors.address = 'Address is required';
            isValid = false;
        } else if (data.address.trim().length < 4) {
            errors.address = 'Address must be at least 4 letters long';
            isValid = false;
        }

        if (!data.state.trim()) {
            errors.state = 'State name is required';
            isValid = false;
        } else if (!/^[A-Za-z\s]+$/.test(data.state)) {
            errors.state = 'Location name should contain only alphabets';
            isValid = false;
        }
        if (!isValid) {
            setErrors(errors);
            setLoading(false);
            return;
        }

        axios.post(apis.base + apis.POST_LOCATIONS_API, data, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            }
        })
            .then((response) => {
                setData(response.data)
                // setSuccessMessage('Location added successfully');
                // console.log('Location add', response.data);
                setSnackbar({
                    open: true,
                    message: 'Location added successfully',
                    severity: 'success',
                });
                console.log('Location added:', response.data);

            })
            .catch((error) => {

                setSnackbar({
                    open: true,
                    message: 'Failed to added Location',
                    severity: 'error',
                });
                console.error("Error submit the Location:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    const handleSnackbarClose = () => {
        Navigate("/location/list")
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <div>
            <div className="page-inner">
                <div className="page-title">
                    <h3 style={{ color: "black" }}>Locations</h3>
                    <div className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li><Link to="/Dashboard">Home</Link></li>
                            <li><Link to="">User management</Link></li>
                            <li><Link to="/location/list">Locations</Link></li>
                            <li>Location Add</li>
                        </ol>
                    </div>
                </div>
                <div className="panel panel-white" style={{ alignItems: 'center', marginLeft: '350px', maxWidth: '90%' }}>
                    <div id="main-wrapper">
                        <ClickAwayListener onClickAway={() => { }}>
                            <Snackbar
                                open={snackbar.open}
                                onClose={() => setSnackbar({ ...snackbar, open: false })}
                                style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
                            >
                                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }} variant="filled" style={{ width: '300px', color: "white", height: '45px', fontSize: '12px' }}>
                                    {snackbar.message}
                                </Alert>
                            </Snackbar>
                        </ClickAwayListener>
                        <div className="col-md-8">
                            <div className="panel panel-white">
                                <div className="panel-heading clearfix" style={{ marginBottom: "-30px" }}>
                                    <h4 className="panel-title" >Add Location</h4>
                                </div>
                                <div className="panel-body">
                                    <div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1" style={{ marginLeft: '10px' }}>Location Name</label>
                                            <TextField
                                                onChange={(e) => {
                                                    setData({ ...data, location_name: e.target.value });
                                                    setErrors({ ...errors, location_name: '' });
                                                }}
                                                type="text" className="form-control" placeholder="Location Name" />

                                        </div>
                                        {errors.location_name && <p className="text-danger" style={{ padding: "5px 0px 0px 13px" }} >{errors.location_name}</p>}
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1" style={{ padding: "5px 0px 0px 13px" }}>State</label>
                                            <TextField
                                                onChange={(e) => {
                                                    setData({ ...data, state: e.target.value });
                                                    setErrors({ ...errors, state: '' });
                                                }}
                                                type="text" className="form-control" placeholder="Enter State" />

                                        </div>
                                        {errors.state && <p className="text-danger" style={{ padding: "5px 0px 0px 13px" }}>{errors.state}</p>}

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1" style={{ padding: "5px 0px 0px 13px" }}>Address</label>
                                            <TextField
                                                onChange={(e) => {
                                                    setData({ ...data, address: e.target.value });
                                                    setErrors({ ...errors, address: '' });
                                                }}
                                                type="text" className="form-control" placeholder="Address" />

                                        </div>

                                        {errors.address && <p className="text-danger" style={{ padding: "5px 0px 0px 13px" }}>{errors.address}</p>}

                                        <div className="panel-body" style={{ display: 'flex', justifyContent: 'center', alignItems:'center' }}>

                                            <div>

                                                <Button type="button" className="btn btn-primary" style={{  width: '65px', background: '#22baa0', borderRadius: '5px', height: '40px', width: '70px' }} onClick={handleSubmit}>Submit</Button>
                                            </div>
                                            <div>
                                                <Link to={routers.Locations}><button type="button" className="btn btn-success" style={{ marginLeft: '70px', background: '#34425a', borderRadius: '5px', height: '40px', width: '70px' }} >Cancel</button></Link>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LocationAdd;
