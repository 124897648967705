import React from 'react'
import { Navigate, useLocation } from "react-router-dom"
import { sendRequest } from "../api/sendRequest";
import { useSelector } from "react-redux";
import { apis } from '../configuration/configurationAPI';
import Scroll from "react-scroll";
import Header from "../Components/Header/Header";
import Footer from '../Components/footer/footer';
import MyNavigation from '../Components/Navigation/MyNavigation';
// import ContentHeader from "../components/ContentHeader";
// import Footer from "../components/Footer";
// import { useSelector } from "react-redux";
// import store from "../utils";
// import { commonActions } from "../utils/commonstore";

// const dispatch = store.dispatch;
// let commonStore = store.getState().commonStore;
// store.subscribe(function () {
//   commonStore = store.getState().commonStore;
// });

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

const AuthRouter = ({ children, path }) => {
    // console.log('Received path:', path);
    // const profileInfo = useSelector((state) => state.commonStore.profileInfo);
    // const user = useSelector((state) => state.user);
    let location = useLocation();
    const isLoginPage = location.pathname === '/login';
    const [data, setData] = React.useState({
        isSimpleNav: false,
        language: "English"
    });
    console.log(data, "data")

    const [logo, setLogo] = React.useState({
        favicon: "",
        footer_text: "",
        icon: "",
        last_loc: "",
        live_chat: "",
        name: "",
        number_limit: 0,
        set_id: 0
    });

    const languages = ["English", "Bulgarian", "Hindi", "Chinese"];

    const setLanguage = (lang) => {
        setData({ ...data, language: lang });
    };


    // React.useEffect(() => {
    //     setData({...data, language: languages[0]});
    //     let body = {
    //         method: "get",
    //     };
    //     let formData = new FormData();
    //     formData.append("payload", JSON.stringify(body));
    //     const res1 = sendRequest(apis.base + apis.logo, formData, ((res) => callback(res)), ((err) => console.log(err)));
    // }, []);

    const callback = (res) => {
        setLogo(res.data[0])
    };

    const Scroll = require('react-scroll');
    const scroll = Scroll.animateScroll;

    const scrollToTop = () => {
        scroll.scrollToTop();
    };




    if (isLoginPage) {
        // If it's the login page, only render the children
        return <div>{children}</div>;
    }
    // const rawUser = sessionStorage.getItem("user");
    // if (rawUser) {
    //     const user = JSON.parse(sessionStorage.getItem("user"));

    //     if (user) {
    //         const decodedJwt = parseJwt(user.remember_token);
    //         console.log("decodedJwt:", decodedJwt);

    //         if (decodedJwt.exp * 1000 < Date.now()) {
    //             console.log("return login page");
    //             return <Navigate to="/login" state={{from: location}} replace/>
    //         } else {
    //             return (

    //                 <div className={data.isSimpleNav ? "app app-sidebar-minified" : "app"}>
    //                     <div className="app-header">
    //                         <Header
    //                             setSimpleNavState={() => setData({...data, isSimpleNav: !data.isSimpleNav})}
    //                             language={data.language}
    //                             logo={logo}
    //                         />
    //                     </div>
    //                     <div className="app-sidebar">
    //                         <Navigation
    //                             isSimpleNav={data.isSimpleNav}
    //                             language={data.language}
    //                         />
    //                     </div>
    //                     <div className="app-content">
    //                         <div>
    //                             <ContentHeader
    //                                 logo={logo}
    //                                 languages={languages}
    //                                 setLanguage={(lang) => setLanguage(lang)}
    //                             />
    //                         </div>
    //                         <div className="container-fluid">
    //                             {children}
    //                         </div>
    //                     </div>
    //                     <div className="app app-footer-fixed">
    //                         <Footer
    //                             logo={logo}
    //                             language={data.language}
    //                         />
    //                     </div>
    //                     <div onClick={scrollToTop} className="btn-scroll-top">
    //                         <i className="fa fa-arrow-up"/>
    //                     </div>
    //                 </div>
    //             )
    //         }
    //     } else {
    //         console.log("return login page");
    //         return <Navigate to="/login" state={{from: location}} replace/>
    //     }
    // } else {
    //     console.log("return login page");
    //     return <Navigate to="/login" state={{from: location}} replace/>
    // }
    // if (rawUser) {
    // get role  from store  
    // props.path
    // if (roleInfo && roleInfo[path] === 1) {
    return (

        <div className={data.isSimpleNav ? "app app-sidebar-minified" : "app"}>
            <div className="app-header">
                <Header
                    setSimpleNavState={() => setData({ ...data, isSimpleNav: !data.isSimpleNav })}
                    language={data.language}
                    logo={logo}
                />
            </div>
            <div className="app-sidebar">
                <MyNavigation
                    isSimpleNav={data.isSimpleNav}
                    language={data.language}
                />
            </div>
            <div className="app-content" style={{}} >
                {/* <div>
                    <ContentHeader
                        logo={logo}
                        languages={languages}
                        setLanguage={(lang) => setLanguage(lang)}
                    />
                </div> */}
                {/* <div className="container-fluid"> */}
               
                <div>
                    {children}
                </div>
               
                <div className="app-footer" style={{
                    position: "fixed",
                    bottom: "0",
                    width: "100%",
                    backgroundColor: "#fff",
                    marginLeft: "160px",
                    paddingLeft:"20px",
                    // zIndex: "1000",
                    
                }}>
                    <Footer
                        logo={logo}
                        language={data.language}
                    />
               </div>
            </div>


            {/* <div onClick={scrollToTop} className="btn-scroll-top">
                <i className="fa fa-arrow-up"/>
            </div> */}
        </div>
    );

};

export default AuthRouter;