import {createSlice} from "@reduxjs/toolkit"
import { createSelector } from '@reduxjs/toolkit';

const selectCommon = (state) => state.commonStore;

export const commonSelectors = {
  selectBaererToken: createSelector(
    [selectCommon],
    (common) => common.baererToken
  ),
  selectLoginUserId: createSelector(
    [selectCommon],
    (common) => common.loginUserId
  ),
  selectUserInfo: createSelector(
    [selectCommon],
    (common) => common.userInfo
  ),
 
};

const initialState = {
  "general_incident_info": {
    "incident_type": 0,
    "patient_type": "",
    "injury_incurred": "",
    "equipment_involved": ""
  },
  "demographics_info": {
    "first_name": "",
    "last_name": "",
    "gender": 0,
    "dob": "",
    "address": "",
    "street": "",
    "city": "",
    "state": "",
    "country": "",
    "zipcode": ""
  },
  "incident_details": {
    "incident_date": "",
    "incident_time": "",
    "incident_state": 0,
    "incident_site": 0,
    "file_owner": "",
    "entered_date": "",
    "entered_time": "",
    "entered_by": "",
    "witness_name": "",
    "witness_phone": "",
    "witness_address":""
  },
  "special_additional_info": {
    "department_id": 0,
    "specific_incident_type": 0,
    "hospitalized_On": "",
    "patient_reaction": 0,
    "existing_medical_conditions": "",
    "known_allergies": "",
    "prescription": "",
    "treatment_plan": "",
    "followUp_date": "",
    "followup_instruction": "",
    "outcome": "",
    "sequelae": "",
    "relevant_history": "",
    "physician_involved": false,
    "contributing_factors": "",
    "immediate_actions_taken": "",
    "reported_incident_severity": 0,
    "other_information": "",
    "incident_description":"",
    "attachment": ""
  }
}


const medicalCareSlice = createSlice({
  // name: "common",
  name: "medicalCare",
  initialState: initialState,
 
})

export const commonActions = medicalCareSlice.actions
export default medicalCareSlice
