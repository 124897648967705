import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import "react-datepicker/dist/react-datepicker.css";
import { commonActions } from "../../utils/commonstore";
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import PermissionAuditExportExcel from "../../Components/AuditExportButtons/PermissionAuditExcel";
import PermissionAuditExportPDF from "../../Components/AuditExportButtons/PermissionAuditPDF";

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

function PermissionSetAudit() {
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [auditData, setAuditData] = useState([]);
    const [rows, setRows] = useState([]);
    const accessInfo = useSelector(commonSelectors.selectAccessInfo);
    

    useEffect(() => {
        fetchPermissionsData();
    }, []);

    const fetchPermissionsData = () => {
        axios.get(apis.base + apis. GET_PERMISSION_AUDIT_API,
            {
                headers: {
                    userid:loginUserId,
                    Authorization:"Bearer "+ baererToken
                }
            })
            .then((succResp) => {
                const PermissonsetsWithIds = succResp.data.map((auditData, index) => ({
                    ...auditData,
                    id: index + 1 
                }));
        
                setRows(PermissonsetsWithIds);
                setLoading(false);
            })
            .catch((errorresp) => {
                console.log(JSON.stringify(errorresp));
                setError("Error while fetching Audit  details. Please try again.");
                setLoading(false);
            });
    };

    const sortedData = [...rows].sort((a, b) => b.id - a.id);
    const columns = [
        { field: 'id', headerName: <strong>Id</strong>, width: 100 },
        { field: 'profile_id', headerName: <strong>Permissionset Id</strong>, width: 150 },
        { field: 'profile_name', headerName: <strong>Permissionset Name</strong>, width: 200 },
        { field: 'assigned_to_user', headerName: <strong>User Name</strong>, width: 150 },
        { field: 'valid_from',
         headerName: <strong>Valid From</strong>,
          width: 200,
          valueGetter: (params) => new Date(params.row.valid_from).toLocaleString('en-US') 
         },
        { field: 'valid_to',
         headerName: <strong>Valid To</strong>,
          width: 200,
          valueGetter: (params) => new Date(params.row.valid_to).toLocaleString('en-US') 
         },
        { field: 'created_user', headerName: <strong>Created By</strong>, width: 150 },
        { 
            field: 'created_timestamp', 
            headerName: <strong>Created Timestamp</strong>, 
            width: 200,
            valueGetter: (params) => new Date(params.row.created_timestamp).toLocaleString('en-US') 
        },
        { field: 'updated_user', headerName: <strong>Updated By</strong>, width: 150 },
        { 
            field: 'updated_timestamp', 
            headerName: <strong>Updated Timestamp</strong>, 
            width: 200,
            valueGetter: (params) => new Date(params.row.updated_timestamp).toLocaleString('en-US') 
        },
        { field: 'is_deleted', headerName: <strong>Status</strong>, width: 100 ,
        renderCell: (params) => (
            <span>{params.value === 0 ? "Active" : "Inactive"}</span>
        )
        },
    ];

    return (
        <div>
            <div className="page-content">
            <div className="page-inner">
                <div className="panel panel-white">
                    <div className="page-title">
                        <h3>Permissionsets Audit Data</h3>
                        <div className="page-breadcrumb">
                            <ol className="breadcrumb">
                                <li><Link to="/Dashboard">Home</Link></li>
                                <li>Permissionset Audit</li>
                            </ol>
                        </div>
                    </div>

                    <div id="main-wrapper">
                        <div className="row m-t-md">
                            <div className="col-md-12">
                                <div className="mailbox-content" style={{ overflow: 'auto' }}>
                                {!error && (
                               <div style={{ display: "flex", position: "relative", top: "20px", gap: "20px", justifyContent: "end", paddingRight: "20px" }}>
                               {accessInfo?.exports ==="1" && (
                               <>
                               <PermissionAuditExportExcel />
                               <PermissionAuditExportPDF/>
                               </>
                       )}
                           </div>
                                )}
                                    <div style={{ height: "100%", width: '100%', paddingTop:"20px"}}>
                                        {error && <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>{error}</p>}
                                        {loading && <p style={{ textAlign: "center", fontSize: "15px" }}>Loading...</p>}
                                        {!loading && !error && (
                                             <DataGrid
                                             rows={sortedData}
                                             columns={columns}
                                             pageSize={5}
                                             rowsPerPageOptions={[10, 20, 30]}
                                             loading={loading}
                                             classes={{
                                                 overlayWrapper: 'customOverlayWrapper'
                                             }}
                                             sx={{
                                                 "& .customOverlayWrapper ": {
                                                     height: "63px !important"
                                                 },
                                                 fontWeight: 'fontWeightLight',
                                                 mx: 0.5,
                                                 fontSize: 14, fontFamily: "sans-serif",
                                                 boxShadow: 2
                                             }}
                                            
                                             components={{
                                                 Toolbar: () => (
                                                     <GridToolbarQuickFilter
                                                         classes={{
                                                             toolbarQuickFilter: 'customtoolbarQuickFilter'
                                                         }}
                                                         sx={{
                                                             width: '35% !important',
                                                             position: 'absolute !important',
                                                             right: 400,
                                                             top: '-30px !important'
                                                         }}
                                                     />
                                                 ),
                                                 NoRowsOverlay: () => (
                                                     <div
                                                         style={{
                                                             position: 'sticky',
                                                             top: '50%',
                                                             transform: 'translate(-50%, 0%)',
                                                             textAlign: 'center',
                                                             marginLeft: '45%'
                                                         }}
                                                     >
                                                         No data found
                                                     </div>
                                                 ),
                                             }}
                                                
                                         />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default PermissionSetAudit;
