import React from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import { apis } from '../../configuration/configurationAPI';


const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

const PermissionAuditExportPDF = () => {
  const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);
  const exportData = async () => {
    try {
      const headers = {
        userid: loginUserId,
        Authorization:"Bearer "+ baererToken,
        format:"pdf"
        
      };

      const response = await axios.get(apis.base + apis.GET_PROFILE_PERMISSIONSET_AUDIT_EXPORT_PDF, {
        headers: headers,
        responseType: 'blob' 
      });


      const url = window.URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Permission_Audit.pdf');
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  return (
    <div>
      
      <div>
        <Button variant="contained" onClick={exportData}>Export as PDF</Button>
      </div>
    </div>
  );
};

export default PermissionAuditExportPDF;
