import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Menu, MenuItem, Box } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { FaDownload, FaTrash } from 'react-icons/fa';
import { FaEdit } from 'react-icons/fa';
import { MdAssignment, MdPeople, MdWarning, MdNotificationImportant, MdEvent, MdMessage, MdDownload, MdLocalFireDepartment, MdMedicalServices, MdMedicalInformation, MdLocalHospital, MdLocalActivity, MdEmergency } from "react-icons/md";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import { Alert, Select, Snackbar, ClickAwayListener } from "@mui/material";
import store from "../../utils/index";
import BorderColorIcon from '@mui/icons-material/BorderColor';


const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
    commonStore = store.getState().commonStore;
});

function WorkflowActions() {
    const accessInfo = useSelector(commonSelectors.selectAccessInfo);
    const { incident_id } = useParams()
    const [changedoctor, setChangedoctor] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDropDown, setOpenDropDown] = useState('');
    const [commentOpen, setCommentOpen] = useState(false);
    const [commentText, setCommentText] = useState("");
    const [openDropDowndoctor, setOpenDropDowndoctor] = useState(false);
    const [flowToDeleteIndex, setFlowToDeleteIndex] = useState(null);
    const [commentValid, setCommentValid] = useState(false);
    const [doctorValid, setDoctorValid] = useState(false);
    const [data, setData] = useState([]);
    console.log(data, "data1111111111111")
    const [showFileInput, setShowFileInput] = useState(false);
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const loggedinId = useSelector(commonSelectors.selectLoginUserId);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);
    const [nextActions, setNextActions] = useState([]);

    const [editMode, setEditMode] = useState(false);
    const [editPrimaryDeptMode, setEditPrimaryDeptMode] = useState(false);

    const [flows, setFlows] = useState([]);
    const [editSecDeptMode, setEditSecDeptMode] = useState(false);
    const [editTertiarytDeptMode, setEditTertiaryDeptMode] = useState(false);
    const [editQuartDeptMode, setEditQuartDeptMode] = useState(false);

    const [editingIndex, setEditingIndex] = useState(null);
    const [editedDoctorData, setEditedDoctorData] = useState("");

    const [editedPirmaryDepartmentData, setEditedPrimaryDepartmentData] = useState(0);
    const [editedSecondaryDepartmentData, setEditedSecondaryDepartmentData] = useState(0);
    const [editedTertiaryDepartmentData, setEditedTertiaryDepartmentData] = useState(0);
    const [editedQuarternaryDepartmentData, setEditedQuarternaryDepartmentData] = useState(0)

    const [department, setDepartment] = useState([]);
    const [selectedPrimaryDepartment, setSelectedPrimaryDepartment] = useState(0);
    const [selectedSecondaryDepartment, setSelectedSecondaryDepartment] = useState(0);
    const [selectedTertiaryDepartment, setSelectedTertiaryDepartment] = useState(0);
    const [selectedQuartarnaryDepartment, setSelectedQuartarnaryDepartment] = useState(0);

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const [assignPrimaryDepartmentOpen, setAssignPrimaryDepartmentOpen] = useState(false);
    const [assignSecondaryDepartmentOpen, setAssignSecondaryDepartmentOpen] = useState(false);
    const [assignTertiaryDepartmentOpen, setAssignTretiaryDepartmentOpen] = useState(false);
    const [assignQuarternaryDepartmentOpen, setAssignQuarternaryDepartmentOpen] = useState(false);
    const [incidentData, setIncidentData] = useState({})
    const [notifyUsersById, setNotifyUsersById] = useState([])

    //loading Indicator

    const [loadingAssign, setLoadingAssign] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingComment, setLoadingComment] = useState(false);
    const [loadingPrimaryDept, setLoadingPrimaryDept] = useState(false);
    const [loadingSecondaryDept, setLoadingSecondaryDept] = useState(false);
    const [loadingTeritaryDept, setLoadingTeritaryDept] = useState(false);
    const [loadingQuartaryDept, setLoadingQuartaryDept] = useState(false);

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    useEffect(() => {
        axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            }
        })
            .then((response) => {
                setDepartment(response.data);
            })
            .catch((errorresp) => {
                console.log(JSON.stringify(errorresp));

            });
    }, []);

    useEffect(() => {
        fetchIncidentByIdData();
    }, []);
    useEffect(() => {

        fetchData();
    }, []);

    useEffect(() => {
        axios.get(apis.base + apis.GET_NOTIFY_USERS_BY_INCIDENTID + incident_id + `?severity=${incidentData?.form_info?.spec?.special_additional_info?.reported_incident_severity}&locationId=${incidentData?.form_info?.lo}`, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            }
        })
            .then(response => {
                if (Array.isArray(response.data)) {
                    setNotifyUsersById(response.data);
                } else {
                    setNotifyUsersById([response.data]);
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []);

    const fetchIncidentByIdData = () => {

        axios.get(apis.base + apis.GET_INCIDENT_ACTIVITY_BY_INCIDENTID_API + incident_id, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            }
        })
            .then(response => {
                setIncidentData(response.data);
                setNextActions(response.data.nextActions);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };

    const handleClickdoctor = () => {
        setOpenDropDowndoctor(true);
    };

    const handleCloseDoctorMenu = () => {
        setOpenDropDowndoctor(false);
    };

    const handleAssign = () => {
        setLoadingAssign(true);
        const doctorAssignPayload = {
            incident_id,
            action_type: "assign_doctor",
            action_performed_by: loggedinId,
            assigned_to_name: changedoctor,
        };
        axios.post(apis.base + apis.POST_ACTIVITY_BY_CREATE_INCIDENT_API, doctorAssignPayload, {
            headers: {
                userid: loginUserId,
                Authorization: `Bearer ${baererToken}`,
            },
        })
            .then((response) => {
                handleChangecommentClose();
                setChangedoctor("");
                return axios.get(apis.base + apis.GET_INCIDENT_ACTIVITY_BY_INCIDENTID_API + incident_id, {
                    headers: {
                        userid: loginUserId,
                        Authorization: `Bearer ${baererToken}`,
                    },
                });
            })
            .then((response) => {
                setIncidentData(response.data);
                setSnackbar({
                    open: true,
                    message: "Doctor updated successfully",
                    severity: "success",
                });
            })
            .catch((error) => {
                console.error('Error updating doctor:', error);
            })
            .finally(() => {
                setLoadingAssign(false);
            });
    };

    const handleChangeStatus = (selectedStatus) => {
        setLoadingStatus(true);
        const statusPayload = {
            "incident_id": incident_id,
            "action_type": "status_change",
            "action_performed_by": loggedinId,
            "status": selectedStatus,
            "is_deleted": false
        };

        axios.post(apis.base + apis.POST_ACTIVITY_BY_CREATE_INCIDENT_API, statusPayload, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            }
        })
            .then((response) => {
                handleChangestatusClose();
                fetchIncidentByIdData()
                setLoadingStatus(false);
            })
            .catch((error) => {
                console.error('Error changing status:', error);
            });
    };

    const handleComment = () => {
        setLoadingComment(true);
        const commentPayload = {
            "incident_id": incident_id,
            "action_type": "comment",
            "action_performed_by": loggedinId,
            "action_performed_timestamp": new Date().toLocaleString('en-US'),
            "created_timestamp": new Date().toLocaleString('en-US'),
            "updated_timestamp": new Date().toLocaleString('en-US'),
            "comment": commentText,
            "is_deleted": false
        }
        axios.post(apis.base + apis.POST_ACTIVITY_BY_CREATE_INCIDENT_API, commentPayload, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            },
        })
            .then((response) => {
                handleCommentClose();
                setCommentText("");
                setSnackbar({
                    open: true,
                    message: "Comment updated successfully",
                    severity: "success"
                });
                fetchIncidentByIdData()
                setLoadingComment(false);
            })

            .catch((error) => {
                console.error('Error adding comment:', error);
                handleCommentClose();
            });
    };
    const handlePrimaryDepartment = () => {
        setLoadingPrimaryDept(true);
        const departmentPayload = {
            "incident_id": incident_id,
            "action_type": "assign_primary_department",
            "action_performed_by": loggedinId,
            "action_performed_timestamp": new Date().toLocaleString('en-US'),
            "created_timestamp": new Date().toLocaleString('en-US'),
            "updated_timestamp": new Date().toLocaleString('en-US'),
            "department_id": selectedPrimaryDepartment,
            "is_deleted": false
        }
        axios.post(apis.base + apis.POST_ACTIVITY_BY_CREATE_INCIDENT_API, departmentPayload, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            },
        })
            .then((response) => {
                setSnackbar({
                    open: true,
                    message: "Department added successfully",
                    severity: "success"
                });
                fetchIncidentByIdData()
                setEditPrimaryDeptMode(false)
                setDropdownVisible(false)
                setLoadingPrimaryDept(false)
            })

            .catch((error) => {
                console.error('Error adding depertment:', error);


            });
    };
    const handleSecondaryDepartment = () => {
        setLoadingSecondaryDept(true);
        const secondaryDepartmentPayload = {
            "incident_id": incident_id,
            "action_type": "assign_secondary_department",
            "action_performed_by": loggedinId,
            "action_performed_timestamp": new Date().toLocaleString('en-US'),
            "created_timestamp": new Date().toLocaleString('en-US'),
            "updated_timestamp": new Date().toLocaleString('en-US'),
            "department_id": selectedSecondaryDepartment,
            "is_deleted": false
        }
        axios.post(apis.base + apis.POST_ACTIVITY_BY_CREATE_INCIDENT_API, secondaryDepartmentPayload, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            },
        })
            .then((response) => {
                setSnackbar({
                    open: true,
                    message: "Department added successfully ",
                    severity: "success"
                });
                fetchIncidentByIdData()
                setEditSecDeptMode(false)
                setDropdownVisible(false)
                setLoadingSecondaryDept(false);
            })

            .catch((error) => {
                console.error('Error adding dept:', error);
                handleCommentClose();

            });
    };
    const handleTertiaryDepartment = () => {
        setLoadingTeritaryDept(true);
        const tertiaryDepartmentPayload = {
            "incident_id": incident_id,
            "action_type": "assign_tertiary_department",
            "action_performed_by": loggedinId,
            "action_performed_timestamp": new Date().toLocaleString('en-US'),
            "created_timestamp": new Date().toLocaleString('en-US'),
            "updated_timestamp": new Date().toLocaleString('en-US'),
            "department_id": selectedTertiaryDepartment,
            "is_deleted": false
        }
        axios.post(apis.base + apis.POST_ACTIVITY_BY_CREATE_INCIDENT_API, tertiaryDepartmentPayload, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            },
        })
            .then((response) => {
                setSnackbar({
                    open: true,
                    message: "Department added successfully ",
                    severity: "success"
                });
                fetchIncidentByIdData()
                setEditPrimaryDeptMode(false)
                setLoadingTeritaryDept(false);
            })

            .catch((error) => {
                console.error('Error adding comment:', error);


            });
    };
    const handleQuarternaryDepartment = () => {
        setLoadingQuartaryDept(true);
        const quarternaryDepartmentPayload = {
            "incident_id": incident_id,
            "action_type": "assign_quarternary_department",
            "action_performed_by": loggedinId,
            "action_performed_timestamp": new Date().toLocaleString('en-US'),
            "created_timestamp": new Date().toLocaleString('en-US'),
            "updated_timestamp": new Date().toLocaleString('en-US'),
            "department_id": selectedQuartarnaryDepartment,
            "is_deleted": false
        }
        axios.post(apis.base + apis.POST_ACTIVITY_BY_CREATE_INCIDENT_API, quarternaryDepartmentPayload, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            },
        })
            .then((response) => {
                setSnackbar({
                    open: true,
                    message: "Department added successfully ",
                    severity: "success"
                });
                fetchIncidentByIdData()
                setLoadingQuartaryDept(false);
            })

            .catch((error) => {
                console.error('Error adding comment:', error);
                handleCommentClose();

            });
    };
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setSelectedFiles(selectedFiles);
    };

    const handleDeleteFile = (index) => {
        const updatedSelectedFiles = [...selectedFiles];
        updatedSelectedFiles.splice(index, 1);
        setSelectedFiles(updatedSelectedFiles);
    };

    const handleFileUpload = (event) => {
        event.preventDefault();
        if (selectedFiles.length === 0) {
            setSnackbar({
                open: true,
                message: 'File Upload failed',
                severity: 'success',
            });
            return;
        }
        const formData = new FormData();
        selectedFiles.forEach((file, index) => {
            formData.append('files', file);
        });


        axios.post(apis.base + apis.POST_INCIDENT_BY_UPLOAD_BYINCIDENTID, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
                incident_id: incident_id
            },
        })
            .then((response) => {
                setShowFileInput(false)
                fetchIncidentByIdData()
               
               

                setSnackbar({
                    open: true,
                    message: 'File Uploaded successfully',
                    severity: 'success',
                });
            })
            .catch((error) => {
                setSnackbar({
                    open: true,
                    message: 'File Upload failed: ' + error.message,
                    severity: 'error',
                });
                console.error('Error uploading files:', error);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
        handleCloseFileUploadDialog();
    };


    const fetchData = () => {
        axios.get(apis.base + apis.GET_VIEWUPLOADEDFILE_API + incident_id, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            },
        })
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.error("Error fetching uploaded files: ", error);
            });
    };
    const handleDownloadAllattachements = () => {
        axios.get(apis.base + apis.GET_ALL_INCIDENT_INCIDENTBYID_FILESZIP_API, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
                incident_id: incident_id
            },
            responseType: 'blob'
        })
            .then((response) => {
                const downloadLink = document.createElement("a");
                downloadLink.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' })); // Set content type to 'application/zip'
                downloadLink.setAttribute("download", "incident_files.zip");
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            })
            .catch((error) => {
                console.error("Error fetching zip file: ", error);
            });
    }

    const handleDownloadSingleFile = (file) => {
        const fileName = file;
        axios.get(apis.base + apis.GET_INCIDENT_FILENAME_INCIDENTID_DOWNLOAD_API + `?fileKeyword=${encodeURIComponent(fileName)}`, {
            responseType: 'blob',
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
                incident_id: incident_id
            }
        })
            .then((response) => {
                const blob = new Blob([response.data]);
                const extension = fileName.split('.').pop(); // Extract file extension
                const downloadLink = document.createElement("a");
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.setAttribute("download", fileName); // Set filename with proper extension
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            })
            .catch((error) => {
                console.error("Error downloading file: ", error);
            });
    };

    const handleCommentOpen = () => {
        setCommentOpen(true);
        setCommentValid(false);
    };
    const handleCommentClose = () => {
        setCommentOpen(false);
        setCommentText("");
    };

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteIncidentActivityId, setDeleteIncidentActivityId] = useState(null);
    const openDeleteConfirmation = (incident_activity_id) => {
        setDeleteIncidentActivityId(incident_activity_id);
        setDeleteConfirmationOpen(true);
    };

    const cancelDeleteFlow = () => {
        setDeleteConfirmationOpen(false);
    };

    const handleCloseFileUploadDialog = () => {
        setShowFileInput(false);
    };


    const handleClickChangeStatus = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenDropDown(true);
    };

    const handleChangestatusClose = () => {
        setAnchorEl(null);
        setOpenDropDown(false);
    };

    const handleChangecommentClose = () => {
        setOpenDropDowndoctor(false);
    };

    const handleAssignPrimaryDepartmentOpen = (index, data) => {
        setAssignPrimaryDepartmentOpen(true);
        setEditedPrimaryDepartmentData(data?.department_id)
    };
    const handleAssignSecondaryDepartmentOpen = (index, data) => {
        setAssignSecondaryDepartmentOpen(true);
        setEditedSecondaryDepartmentData(data?.department_id)
    };
    const handleAssignTretiaryDepartmentOpen = (index, data) => {
        setAssignTretiaryDepartmentOpen(true);
        setEditedTertiaryDepartmentData(data?.department_id)
    };
    const handleAssignQuarternaryDepartmentOpen = (index, data) => {
        setAssignQuarternaryDepartmentOpen(true);
        setEditedQuarternaryDepartmentData(data?.department_id)
    };

    const handleAssignDepartmentClose = () => {
        setAssignPrimaryDepartmentOpen(false);
        setAssignSecondaryDepartmentOpen(false);
        setAssignTretiaryDepartmentOpen(false);
        setAssignQuarternaryDepartmentOpen(false);
    };

    const handleCancelEditPrimaryDept = () => {
        setEditPrimaryDeptMode(false);
        setEditedPrimaryDepartmentData('');
    };
    const handleCancelEditSecondaryDept = () => {
        setEditSecDeptMode(false);
        setEditedSecondaryDepartmentData('');
    };
    const handleCancelEditTrtiaryDept = () => {
        setEditTertiaryDeptMode(false);
        setEditedTertiaryDepartmentData('');
    };
    const handleCancelEditQuarternaryDept = () => {
        setEditQuartDeptMode(false);
        setEditedQuarternaryDepartmentData('');
    };

    const toggleEditdoctor = (index, data) => {
        setEditingIndex(index);

        setEditedDoctorData(data.assigned_to_name);
        setEditMode(true);
    };
    const handleCancelEdit = () => {
        setEditMode(false);

        setEditingIndex(null);
    };

    const handleDeleteAction = (incident_activity_id) => {
        axios.delete(apis.base + apis.DELETE_INCIDENT_ACTIVITY_BY_INCIDENT_ACTIVITYID + incident_activity_id, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            }
        })
            .then(response => {
                cancelDeleteFlow()
                handleAssignDepartmentClose()
                fetchIncidentByIdData()
            })
            .catch(error => {
                console.error('Error deleting action:', error);
            });
    };
    const handleUpdateDoctor = (incident_activity_id, action) => {
        const UpdateDoctorPayload = {
            "incident_activity_id": incident_activity_id,
            "incident_id": incident_id,
            "action_type": "assign_doctor",
            "assigned_to_name": editedDoctorData,
            "action_performed_by": loginUserId,
            "action_performed_timestamp": new Date().toLocaleString('en-US'),
            "updated_timestamp": new Date().toLocaleString('en-US'),
            "is_deleted": false
        };
        axios.put(apis.base + apis.PUT_INCIDENT_ACTIVITY_BY_INCIDENT_ACTIVITYID + incident_activity_id, UpdateDoctorPayload, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            }
        })
            .then(response => {
                setDropdownVisible(false);
                setSnackbar({
                    open: true,
                    message: "Doctor updated successfully",
                    severity: "success"
                })
                fetchIncidentByIdData()
                setEditMode(false);
                setEditedPrimaryDepartmentData("")
                setEditedDoctorData("");
            })
            .catch(error => {
                console.error('Error updating activity:', error);
            });
    };
    const handleUpdatePrimaryDepartment = (incident_activity_id, action) => {
        const UpdatePrimaryDepartmentPayload = {
            "incident_activity_id": incident_activity_id,
            "incident_id": incident_id,
            "action_type": "assign_primary_department",
            "department_id": editedPirmaryDepartmentData,
            "action_performed_by": loginUserId,
            "action_performed_timestamp": new Date().toLocaleString('en-US'),
            "updated_timestamp": new Date().toLocaleString('en-US'),
            "is_deleted": false
        };
        axios.put(apis.base + apis.PUT_INCIDENT_ACTIVITY_BY_INCIDENT_ACTIVITYID + incident_activity_id, UpdatePrimaryDepartmentPayload, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            }
        })
            .then(response => {
                setDropdownVisible(false);
                setSnackbar({
                    open: true,
                    message: "Department updated successfully",
                    severity: "success"
                })
                fetchIncidentByIdData()
                setEditPrimaryDeptMode(false);
                setEditedPrimaryDepartmentData("")
            })
            .catch(error => {
                console.error('Error updating activity:', error);
            });
    };
    const handleUpdateSecondaryDepartment = (incident_activity_id, action) => {
        const UpdateSecondaryDepartmentPayload = {
            "incident_activity_id": incident_activity_id,
            "incident_id": incident_id,
            "action_type": "assign_secondary_department",
            "department_id": editedSecondaryDepartmentData,
            "action_performed_by": loginUserId,
            "action_performed_timestamp": new Date().toLocaleString('en-US'),
            "updated_timestamp": new Date().toLocaleString('en-US'),
            "is_deleted": false
        };
        axios.put(apis.base + apis.PUT_INCIDENT_ACTIVITY_BY_INCIDENT_ACTIVITYID + incident_activity_id, UpdateSecondaryDepartmentPayload, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            }
        })
            .then(response => {
                setDropdownVisible(false);
                setSnackbar({
                    open: true,
                    message: "Department updated successfully",
                    severity: "success"
                })
                fetchIncidentByIdData()
                setEditSecDeptMode(false);
                setEditedSecondaryDepartmentData("")
            })
            .catch(error => {
                console.error('Error updating activity:', error);
            });
    };
    const handleUpdateTertiaryDepartment = (incident_activity_id, action) => {
        const UpdateTertiaryDepartmentPayload = {
            "incident_activity_id": incident_activity_id,
            "incident_id": incident_id,
            "action_type": "assign_tertiary_department",
            "department_id": editedTertiaryDepartmentData,
            "action_performed_by": loginUserId,
            "action_performed_timestamp": new Date().toLocaleString('en-US'),
            "updated_timestamp": new Date().toLocaleString('en-US'),
            "is_deleted": false
        };

        axios.put(apis.base + apis.PUT_INCIDENT_ACTIVITY_BY_INCIDENT_ACTIVITYID + incident_activity_id, UpdateTertiaryDepartmentPayload, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            }
        })
            .then(response => {
                setDropdownVisible(false);
                setSnackbar({
                    open: true,
                    message: "Department updated successfully",
                    severity: "success"
                })
                fetchIncidentByIdData()
                setEditTertiaryDeptMode(false);
                setEditedTertiaryDepartmentData("")
            })
            .catch(error => {
                console.error('Error updating activity:', error);
            });
    };
    const handleUpdateQuarternaryDepartment = (incident_activity_id, action) => {
        const UpdateQuarternaryDepartmentPayload = {
            "incident_activity_id": incident_activity_id,
            "incident_id": incident_id,
            "action_type": "assign_quarternary_department",
            "department_id": editedQuarternaryDepartmentData,
            "action_performed_by": loginUserId,
            "action_performed_timestamp": new Date().toLocaleString('en-US'),
            "updated_timestamp": new Date().toLocaleString('en-US'),
            "is_deleted": false
        };
        axios.put(apis.base + apis.PUT_INCIDENT_ACTIVITY_BY_INCIDENT_ACTIVITYID + incident_activity_id, UpdateQuarternaryDepartmentPayload, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken,
            }
        })
            .then(response => {
                setDropdownVisible(false);
                setEditQuartDeptMode(false);
                setSnackbar({
                    open: true,
                    message: "Department updated successfully",
                    severity: "success"
                })
                fetchIncidentByIdData()
            })
            .catch(error => {
                console.error('Error updating activity:', error);
            });
    };
    const getTimelineIcon = (actionType) => {
        switch (actionType) {
            case 'status_change':
                return <MdAssignment />;
            case 'assign_doctor':
                return <MdPeople />;
            case 'comment':
                return <MdMessage />;
            case 'assign_primary_department':
                return <MdMedicalServices />
            case 'assign_secondary_department':
                return <MdMedicalServices />
            case 'assign_tertiary_department':
                return <MdMedicalServices />
            case 'assign_quarternary_department':
                return <MdMedicalServices />
            default:
                return null;
        }
    };

    const AssignToRmActivityFilter = incidentData && Array.isArray(incidentData.previousActivities) && incidentData?.previousActivities?.find(item => item.action_type == "assigned_to_rm");

    const previousActivities = (incidentData && Array.isArray(incidentData.previousActivities) && incidentData?.previousActivities?.filter(item => {
        return item.action_type != "assigned_to_rm" && item.action_type != "incident_reported"
    })) || [];

    const reversedActivities = previousActivities.reverse();

    const validWorkflowsEdit = ["1010", "1011", "1110", "1111"];
    const validWorkflowsDelete = ["1001", "1011", "1111","1101"];

    const renderEventDetails = (action, index) => {
        switch (action.action_type) {

            case 'status_change':
                return (
                    <>
                        <h3>Status </h3>

                        <div>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div><p>{action.status}</p></div>
                            </div>


                        </div>
                    </>
                );

            case 'assign_doctor':
                return (
                    <>
                        <h3 style={{ color: action.is_deleted ? 'red' : 'inherit' }}>Assigned To Doctor</h3>
                        {editMode && editingIndex === index ? (
                            <div>
                                <TextField
                                    rows={4}
                                    fullWidth
                                    style={{ marginBottom: "10px" }}
                                    defaultValue={action.assigned_to_name}
                                    value={editedDoctorData}
                                    onChange={(e) => setEditedDoctorData(e.target.value)}
                                />
                                <Button style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }} variant="outlined" onClick={() => handleUpdateDoctor(action.incident_activity_id, 'assig_doctor')}>Update</Button>
                                <Button style={{ backgroundColor: "#34425a", color: "white" }} variant="outlined" onClick={handleCancelEdit}>Cancel</Button>
                            </div>
                        ) : (
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div><p>{action.assigned_to_name}</p></div>
                                <div>
                                {validWorkflowsEdit.includes(accessInfo?.workflow) && (
                                    <BorderColorIcon onClick={() => toggleEditdoctor(index, action)} />
                                )}
                                {validWorkflowsDelete.includes(accessInfo?.workflow) && (
                                    <FaTrash style={{ marginLeft: '10px' }} variant="outlined" onClick={() => openDeleteConfirmation(action.incident_activity_id)} />
                                )}
                                </div>
                            </div>
                        )}
                    </>

                );

            case 'comment':
                return (
                    <>
                        <h3>Comment Added</h3>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div><p>{action.comment}</p></div>
                        </div>

                    </>
                );
            case 'assign_primary_department':
                return (
                    <>
                        <h3>Assigned To Department</h3>
                        <div>
                            {editPrimaryDeptMode ? (
                                <div>
                                    <Select
                                        value={editedPirmaryDepartmentData}
                                        onChange={(e) => setEditedPrimaryDepartmentData(e.target.value)}
                                        fullWidth
                                        style={{ marginBottom: "10px" }}
                                    >
                                        {department.map(dept => (
                                            <MenuItem key={dept.department_id} value={dept.department_id}>
                                                {dept.department_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    
                                    <Button style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }} variant="outlined" onClick={() => handleUpdatePrimaryDepartment(action.incident_activity_id, 'assign_primary_department')}>Update</Button>
                                    <Button style={{ backgroundColor: "#34425a", color: "white" }} variant="outlined" onClick={handleCancelEditPrimaryDept}>Cancel</Button>
                                </div>
                            ) : (
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div><p>{action.department_name}</p></div>
                                    <div>
                                    {validWorkflowsEdit.includes(accessInfo?.workflow) && (
                                        <BorderColorIcon onClick={() => setEditPrimaryDeptMode(true)} />
                                    )}
                                    {validWorkflowsDelete.includes(accessInfo?.workflow) && (
                                        <FaTrash style={{ marginLeft: '10px' }} variant="outlined" onClick={() => openDeleteConfirmation(action.incident_activity_id)} />
                                    )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>


                );

            case 'assign_secondary_department':
                return (
                    <>
                        <h3>Assigned Secondary Department</h3>
                        <div>
                            {editSecDeptMode ? (
                                <div>
                                    <Select
                                        value={editedSecondaryDepartmentData}
                                        onChange={(e) => setEditedSecondaryDepartmentData(e.target.value)}
                                        fullWidth
                                        style={{ marginBottom: "10px" }}
                                    >
                                        {department.map(dept => (
                                            <MenuItem key={dept.department_id} value={dept.department_id}>
                                                {dept.department_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }} variant="outlined" onClick={() => handleUpdateSecondaryDepartment(action.incident_activity_id, 'assign_secondary_department')}>Update</Button>
                                    <Button style={{ backgroundColor: "#34425a", color: "white" }} variant="outlined" onClick={handleCancelEditSecondaryDept}>Cancel</Button>
                                </div>
                            ) : (
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div><p>{action.department_name}</p></div>

                                    <div>
                                    {validWorkflowsEdit.includes(accessInfo?.workflow) && (
                                        <BorderColorIcon onClick={() => setEditSecDeptMode(true)} />
                                    )}
                                    {validWorkflowsDelete.includes(accessInfo?.workflow) && (
                                        <FaTrash style={{ marginLeft: '10px' }} variant="outlined" onClick={() => openDeleteConfirmation(action.incident_activity_id)} />
                                    )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                );
            case 'assign_tertiary_department':
                return (
                    <>
                        <h3>Assigned Tertiary Department</h3>
                        <div>
                            {editTertiarytDeptMode ? (
                                <div>
                                    <Select
                                        value={editedTertiaryDepartmentData}
                                        onChange={(e) => setEditedTertiaryDepartmentData(e.target.value)}
                                        fullWidth
                                        style={{ marginBottom: "10px" }}
                                    >
                                        {department.map(dept => (
                                            <MenuItem key={dept.department_id} value={dept.department_id}>
                                                {dept.department_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }} variant="outlined" onClick={() => handleUpdateTertiaryDepartment(action.incident_activity_id, 'assign_tertiary_department')}>Update</Button>
                                    <Button style={{ backgroundColor: "#34425a", color: "white" }} variant="outlined" onClick={handleCancelEditTrtiaryDept}>Cancel</Button>
                                </div>
                            ) : (
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div><p>{action.department_name}</p></div>

                                    <div>
                                    {validWorkflowsEdit.includes(accessInfo?.workflow) && (
                                        <BorderColorIcon onClick={() => setEditTertiaryDeptMode(true)} />
                                    )}
                                    {validWorkflowsDelete.includes(accessInfo?.workflow) && (
                                        <FaTrash style={{ marginLeft: '10px' }} variant="outlined" onClick={() => openDeleteConfirmation(action.incident_activity_id)} />
                                    )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                );
            case 'assign_quarternary_department':
                return (
                    <>
                        <h3> Quarternary Department</h3>
                        <div>
                            {editQuartDeptMode ? (
                                <div>
                                    <Select
                                        value={editedQuarternaryDepartmentData}
                                        onChange={(e) => setEditedQuarternaryDepartmentData(e.target.value)}
                                        fullWidth
                                        style={{ marginBottom: "10px" }}
                                    >
                                        {department.map(dept => (
                                            <MenuItem key={dept.department_id} value={dept.department_id}>
                                                {dept.department_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }} variant="outlined" onClick={() => handleUpdateQuarternaryDepartment(action.incident_activity_id, 'assign_quarternary_department')}>Update</Button>
                                    <Button style={{ backgroundColor: "#34425a", color: "white" }} variant="outlined" onClick={handleCancelEditQuarternaryDept}>Cancel</Button>
                                </div>
                            ) : (
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div><p>{action.department_name}</p></div>

                                    <div>
                                    {validWorkflowsEdit.includes(accessInfo?.workflow) && (
                                        <BorderColorIcon onClick={() => setEditQuartDeptMode(true)} />
                                    )}
                                    {validWorkflowsDelete.includes(accessInfo?.workflow) && (
                                        <FaTrash style={{ marginLeft: '10px' }} variant="outlined" onClick={() => openDeleteConfirmation(action.incident_activity_id)} />
                                    )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                );

            default:
                return null;
        }
    };

    const basePaths = {
        'Adverse Drug': '/adversedrugreactionForm',
        'Airway Management': '/airwaysmanagementform',
        'Anesthesia or Sedation': '/anesthesiaform',
        'Coding Complications': '/codingcomplicationsform',
        'Diagnostic Image': '/diagnosticImageForm',
        'Fall': '/fallForm',
        'Feedback': '/feedbackForm',
        'Blood And Blood Products': '/bloodAndBloodProductsForm',
        'Lab/Specimen Collection': '/labSpecimenCollectionForm',
        'MedicalCare Treatment': '/medicalCareTreatmentForm',
        'Medical Device Reporting': '/medicalDeviceReportingForm',
        'Medication/Chemotherapy': '/medicationorChemotherpyForm',
        'Obstetrics': '/obstetricsForm',
        'Patient Behavior': '/patientBehaviorForm',
        'Patient Privacy': '/patientPrivacyAuthorizationForm',
        'Restraints': '/restraintsForm',
        'Safety/Security/Facility': '/safetySecurityFacilityForm',
        'Surgery Procedure Consent': '/surgeryProcedureConsentForm',
        'Unprofessional Behavior': '/unprofessionalBehaviorForm'
    };


    // if (!incidentData?.form_info?.incident_id || !incidentData?.form_info?.spec?.general_incident_info?.incident_type) {
    //   return <p>Invalid incident data</p>;
    // }

    const incidentType = incidentData?.form_info?.incident_type;
    const basePath = basePaths[incidentType];
    const editUrl = `${basePath}/${incident_id}/edit`;

    const validWorkflows = ["1100", "1101", "1110", "1111"];

    return (
        <div className="page-content">
            <div className="page-inner">
                <div className="page-title">
                    <h3>Workflow</h3>
                    <div className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li><Link to="/Dashboard">Home</Link></li>
                            <li>WorkFlow Actions</li>
                        </ol>
                    </div>
                </div>
                <ClickAwayListener onClickAway={() => { }}>
                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={2000}
                        onClose={() => setSnackbar({ ...snackbar, open: false })}
                        style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    >
                        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} style={{ width: '300px', height: '55px', fontSize: '12px' }}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                </ClickAwayListener>
                <div id="main-wrapper">
                    <div className="row">
                        <div className="col-md-12">

                            <section id="cd-timeline" className="cd-container">

                                <div className="cd-timeline-block">
                                    <div className="cd-timeline-img cd-success"> <MdAssignment /></div>
                                    <div className="cd-timeline-content">
                                        <h3>Form Submitted</h3>
                                        {/* <p>Incident Id: {incidentData?.form_info?.incident_id}</p> */}
                                        <p>
                                            Incident Id:
                                            <Link to={editUrl}>
                                                {incidentData?.form_info?.incident_id}
                                            </Link>
                                        </p>
                                        <p>Incident Type: {incidentData?.form_info?.spec?.general_incident_info?.incident_type}</p>
                                        <span className="cd-date"><p>{new Date(incidentData?.form_info?.created_timestamp).toLocaleString('en-US')}</p></span>  </div>
                                </div>
                                <div className="cd-timeline-block">
                                    <div className="cd-timeline-img cd-success"> <MdPeople /></div>
                                    <div className="cd-timeline-content">
                                        <h3>Assigned To Risk Manager</h3>
                                        <div>
                                            {AssignToRmActivityFilter && (
                                                <p>
                                                    {AssignToRmActivityFilter.assigned_person}
                                                </p>
                                            )}
                                            <span className="cd-date"><p>{new Date(incidentData?.form_info?.created_timestamp).toLocaleString('en-US')}</p></span>
                                        </div>
                                    </div>
                                </div>

                                {/* Third event */}
                                <div className="cd-timeline-block">

                                    <div className={(incidentData?.form_info?.spec?.special_additional_info?.reported_incident_severity.includes(1) || incidentData?.form_info?.spec?.special_additional_info?.reported_incident_severity.includes(2)) ? "cd-timeline-img cd-warning" : "cd-timeline-img cd-danger"}> <MdWarning /></div>
                                    <div className="cd-timeline-content">
                                        <h3>Severity</h3>
                                        <p>{incidentData?.form_info?.spec?.special_additional_info?.reported_incident_severity}</p>
                                        <span className="cd-date"><p>{new Date(incidentData?.form_info?.created_timestamp).toLocaleString('en-US')}</p></span>
                                    </div>
                                </div>
                                {/* alerts sent to */}
                                <div className="cd-timeline-block">
                                    <div className="cd-timeline-img cd-success">
                                        <MdNotificationImportant />
                                    </div>
                                    <div className="cd-timeline-content">
                                        <h3>Alerts Sent To</h3>

                                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                            {notifyUsersById.map(user => (
                                                <li
                                                    key={user.id}
                                                    style={{ padding: '8px 0', borderBottom: '1px solid #e0e0e0' }}
                                                >
                                                    {user.email}
                                                </li>
                                            ))}
                                        </ul>

                                        <span className="cd-date">
                                            <p>{new Date(incidentData?.form_info?.created_timestamp).toLocaleString('en-US')}</p>
                                        </span>
                                    </div>
                                </div>

                                {/* uploaded files */}
                                <div className="cd-timeline-block">
                                    <div className="cd-timeline-img cd-success">
                                        <MdDownload />
                                    </div>
                                    <div className="cd-timeline-content">
                                        <h3>Uploaded Files</h3>
                                        {incidentData && incidentData.form_info && incidentData.form_info.files_info && (
                                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                                {JSON.parse(incidentData.form_info.files_info).map((file, index) => (
                                                    <li
                                                        key={index}
                                                        style={{ padding: '8px 0', borderBottom: '1px solid #e0e0e0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                                    >
                                                        <span>{file}</span>
                                                        <Button
                                                            onClick={() => handleDownloadSingleFile(file)}
                                                            style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}

                                                        >
                                                            <FaDownload style={{ color: "black", fontSize: "13px" }} />
                                                        </Button>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        <Button
                                            style={{
                                                backgroundColor: "#22baa0",
                                                color: "white",
                                                marginTop: '15px'
                                            }}
                                            onClick={handleDownloadAllattachements}
                                            color="primary"
                                        >
                                            Download All
                                        </Button>

                                        <span className="cd-date">
                                            <p>{new Date(incidentData?.form_info?.created_timestamp).toLocaleString('en-US')}</p>
                                        </span>
                                    </div>
                                </div>


                                {reversedActivities &&
                                    reversedActivities.map((action, index) => (
                                        <div className="cd-timeline-block" key={index}>
                                            <div className="cd-timeline-img cd-success">
                                                {getTimelineIcon(action.action_type)}
                                            </div>
                                            <div className="cd-timeline-content">
                                                {renderEventDetails(action, index)}
                                                <span className="cd-date">
                                                    <p>{new Date(action.action_performed_timestamp).toLocaleString('en-US')}</p>
                                                </span>
                                            </div>
                                        </div>
                                    ))
                                }

                                <Dialog open={deleteConfirmationOpen} onClose={cancelDeleteFlow}>
                                    <DialogTitle><h3>Delete Confirmation</h3></DialogTitle>
                                    <DialogContent>
                                        <h5>Are you to want to delete this activity?</h5>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button style={{ backgroundColor: "#34425a", color: "white" }} onClick={cancelDeleteFlow} color="primary">Cancel</Button>
                                        <Button style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }} onClick={() => handleDeleteAction(deleteIncidentActivityId)} color="primary" autoFocus>Delete</Button>

                                    </DialogActions>
                                </Dialog>

                            </section>


                            <div class="panel-body">
                                <div class="options" style={{ textAlign: "center", marginLeft: "250px" }}>
                                    {loadingAssign || loadingStatus || loadingComment || loadingPrimaryDept || loadingSecondaryDept || loadingTeritaryDept || loadingQuartaryDept ? (
                                        <Box sx={{ position: "fixed", top: "35%", left: "45%", backgroundColor: "white", width: "20%", height: "auto", boxShadow: "2px 2px 5px gray", paddingTop: "10px", paddingBottom: "10px" }}>
                                            <CircularProgress size={60} color="primary" />
                                            <Box sx={{ marginTop: 2 }}>Processing...</Box>
                                        </Box>
                                    ) : (

                                        nextActions.map((action, index) => (

                                            <div key={index}>
                                                {validWorkflows.includes(accessInfo?.workflow) && (
                                                    <>
                                                        {action.action_type === 'assign_doctor' && (
                                                            <div className="option">
                                                                <div className="dropdown">
                                                                    <Button
                                                                        variant="outlined"
                                                                        onClick={handleClickdoctor}
                                                                        style={{ backgroundColor: "#22baa0", fontSize: "10px", color: "white" }}
                                                                    >
                                                                        Assign To Doctor
                                                                    </Button>
                                                                    <Dialog open={openDropDowndoctor} onClose={handleCloseDoctorMenu}>
                                                                        <DialogTitle>Assign To Doctor</DialogTitle>
                                                                        <DialogContent>
                                                                            <TextField
                                                                                value={changedoctor}
                                                                                onChange={(e) => {
                                                                                    setChangedoctor(e.target.value);
                                                                                    setDoctorValid(!!e.target.value);
                                                                                }}
                                                                                placeholder="Enter doctor's name..."
                                                                                rows={4}
                                                                                fullWidth
                                                                                style={{ marginBottom: "10px" }}
                                                                            />
                                                                        </DialogContent>
                                                                        <DialogActions>
                                                                            <Button
                                                                                style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }}
                                                                                disabled={!doctorValid}
                                                                                onClick={handleAssign}
                                                                            >
                                                                                Assign
                                                                            </Button>
                                                                            <Button
                                                                                style={{ backgroundColor: "#34425a", color: "white" }}
                                                                                onClick={handleCloseDoctorMenu}
                                                                            >
                                                                                Cancel
                                                                            </Button>
                                                                        </DialogActions>
                                                                    </Dialog>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {action.action_type === 'status_change' && (
                                                            <div className="option">
                                                                <div className="dropdown">
                                                                    <Button
                                                                        variant="outlined"
                                                                        onClick={handleClickChangeStatus}
                                                                        style={{
                                                                            backgroundColor: "#22baa0",
                                                                            width: "120px",
                                                                            fontSize: "10px",
                                                                            color: "white"
                                                                        }}
                                                                    >
                                                                        Change Status
                                                                    </Button>
                                                                    <Menu
                                                                        id="basic-menu"
                                                                        anchorEl={anchorEl}
                                                                        open={openDropDown}
                                                                        onClose={handleChangestatusClose}
                                                                        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                                                                    >
                                                                        <MenuItem value="Inprogress" onClick={() => handleChangeStatus("Inprogress")}>Inprogress</MenuItem>
                                                                        <MenuItem value="Completed" onClick={() => handleChangeStatus("Completed")}>Completed</MenuItem>
                                                                    </Menu>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {action.action_type === 'comment' && (
                                                            <div className="option">
                                                                <div className="dropdown">
                                                                    <Button variant="outlined" style={{ backgroundColor: "#22baa0", color: "white", fontSize: "10px" }} onClick={handleCommentOpen}>Add Comment</Button>
                                                                    <Dialog open={commentOpen} onClose={handleCommentClose}>
                                                                        <DialogTitle>Add Comment</DialogTitle>
                                                                        <DialogContent>
                                                                            <TextField
                                                                                value={commentText}
                                                                                onChange={(e) => {
                                                                                    setCommentText(e.target.value);
                                                                                    setCommentValid(!!e.target.value);
                                                                                }}
                                                                                placeholder="Type your comment here..."
                                                                                rows={4}
                                                                                fullWidth
                                                                                style={{ marginBottom: "10px" }}
                                                                            />
                                                                        </DialogContent>
                                                                        <DialogActions>
                                                                            <Button style={{ backgroundColor: "#22baa0", color: "white" }} disabled={!commentValid} onClick={handleComment}>Add</Button>
                                                                            <Button onClick={handleCommentClose} style={{ backgroundColor: "#34425a", color: "white" }} color="secondary">Cancel</Button>
                                                                        </DialogActions>
                                                                    </Dialog>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {action.action_type === 'assign_primary_department' && (
                                                            <div className="option">
                                                                <div className="dropdown">
                                                                    <Button variant="outlined" style={{ backgroundColor: "#22baa0", color: "white", fontSize: "10px" }} onClick={handleAssignPrimaryDepartmentOpen}>Assign Department</Button>
                                                                    <Dialog open={assignPrimaryDepartmentOpen} onClose={handleAssignDepartmentClose}>
                                                                        <DialogTitle>Assign Department</DialogTitle>
                                                                        <DialogContent>
                                                                            <Select
                                                                                value={selectedPrimaryDepartment}
                                                                                onChange={(e) => setSelectedPrimaryDepartment(e.target.value)}
                                                                                fullWidth
                                                                                style={{ marginBottom: "10px" }}
                                                                            >
                                                                                {department.map((dept) => (
                                                                                    <MenuItem key={dept.department_id} value={dept.department_id}>
                                                                                        {dept.department_name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </DialogContent>
                                                                        <DialogActions>
                                                                            <Button style={{ backgroundColor: "#22baa0", color: "white" }} disabled={!selectedPrimaryDepartment} onClick={() => handlePrimaryDepartment(action.incident_activity_id)}>Assign</Button>
                                                                            <Button onClick={handleAssignDepartmentClose} style={{ backgroundColor: "#34425a", color: "white" }} color="secondary">Cancel</Button>
                                                                        </DialogActions>
                                                                    </Dialog>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {action.action_type === 'assign_secondary_department' && (
                                                            <div className="option">
                                                                <div className="dropdown">
                                                                    <Button variant="outlined" style={{ backgroundColor: "#22baa0", color: "white", fontSize: "10px" }} onClick={handleAssignSecondaryDepartmentOpen}>Assign Secondary Department</Button>
                                                                    <Dialog open={assignSecondaryDepartmentOpen} onClose={handleAssignDepartmentClose}>
                                                                        <DialogTitle>Assign Secondary Department</DialogTitle>
                                                                        <DialogContent>
                                                                            <Select
                                                                                value={selectedSecondaryDepartment}
                                                                                onChange={(e) => setSelectedSecondaryDepartment(e.target.value)}
                                                                                fullWidth
                                                                                style={{ marginBottom: "10px" }}
                                                                            >
                                                                                {department.map((dept) => (
                                                                                    <MenuItem key={dept.department_id} value={dept.department_id}>
                                                                                        {dept.department_name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </DialogContent>
                                                                        <DialogActions>
                                                                            <Button style={{ backgroundColor: "#22baa0", color: "white" }} disabled={!selectedSecondaryDepartment} onClick={() => handleSecondaryDepartment(action.incident_activity_id)}>Assign</Button>
                                                                            <Button onClick={handleAssignDepartmentClose} style={{ backgroundColor: "#34425a", color: "white" }} color="secondary">Cancel</Button>
                                                                        </DialogActions>
                                                                    </Dialog>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {action.action_type === 'assign_tertiary_department' && (
                                                            <div className="option">
                                                                <div className="dropdown">
                                                                    <Button variant="outlined" style={{ backgroundColor: "#22baa0", color: "white", fontSize: "10px" }} onClick={handleAssignTretiaryDepartmentOpen}>Assign Tertiary Department</Button>
                                                                    <Dialog open={assignTertiaryDepartmentOpen} onClose={handleAssignDepartmentClose}>
                                                                        <DialogTitle>Assign Tertiary Department</DialogTitle>
                                                                        <DialogContent>
                                                                            <Select
                                                                                value={selectedTertiaryDepartment}
                                                                                onChange={(e) => setSelectedTertiaryDepartment(e.target.value)}
                                                                                fullWidth
                                                                                style={{ marginBottom: "10px" }}
                                                                            >
                                                                                {department.map((dept) => (
                                                                                    <MenuItem key={dept.department_id} value={dept.department_id}>
                                                                                        {dept.department_name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </DialogContent>
                                                                        <DialogActions>
                                                                            <Button style={{ backgroundColor: "#22baa0", color: "white" }} disabled={!selectedTertiaryDepartment} onClick={() => handleTertiaryDepartment(action.incident_activity_id)}>Assign</Button>
                                                                            <Button onClick={handleAssignDepartmentClose} style={{ backgroundColor: "#34425a", color: "white" }} color="secondary">Cancel</Button>
                                                                        </DialogActions>
                                                                    </Dialog>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {action.action_type === 'assign_quarternary_department' && (
                                                            <div className="option">
                                                                <div className="dropdown">
                                                                    <Button variant="outlined" style={{ backgroundColor: "#22baa0", color: "white", fontSize: "10px" }} onClick={handleAssignQuarternaryDepartmentOpen}>Assign Quarternary Department</Button>
                                                                    <Dialog open={assignQuarternaryDepartmentOpen} onClose={handleAssignDepartmentClose}>
                                                                        <DialogTitle>Assign Quarternary Department</DialogTitle>
                                                                        <DialogContent>
                                                                            <Select
                                                                                value={selectedQuartarnaryDepartment}
                                                                                onChange={(e) => setSelectedQuartarnaryDepartment(e.target.value)}
                                                                                fullWidth
                                                                                style={{ marginBottom: "10px" }}
                                                                            >
                                                                                {department.map((dept) => (
                                                                                    <MenuItem key={dept.department_id} value={dept.department_id}>
                                                                                        {dept.department_name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </DialogContent>
                                                                        <DialogActions>
                                                                            <Button style={{ backgroundColor: "#22baa0", color: "white" }} disabled={!selectedQuartarnaryDepartment} onClick={() => handleQuarternaryDepartment(action.incident_activity_id)}>Assign</Button>
                                                                            <Button onClick={handleAssignDepartmentClose} style={{ backgroundColor: "#34425a", color: "white" }} color="secondary">Cancel</Button>
                                                                        </DialogActions>
                                                                    </Dialog>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>

                                        ))
                                    )}

                                    < div >
                                    {validWorkflows.includes(accessInfo?.workflow) && (
                                                    <>
                                        <Button
                                            variant="outlined"
                                            style={{ backgroundColor: "#22baa0", color: "white", fontSize: "10px" }}
                                            onClick={() => setShowFileInput(true)}
                                        >
                                            File Upload
                                        </Button>
                                        <Dialog open={showFileInput} onClose={() => setShowFileInput(false)}>
                                            <DialogTitle style={{ fontSize: "20px" }}>Upload Files</DialogTitle>
                                            <DialogContent>
                                                <input type="file" style={{ color: 'transparent' }} onChange={handleFileChange} multiple />
                                                {selectedFiles.map((selectedFile, index) => (
                                                    <div key={index}>
                                                        <span>{selectedFile.name}</span>
                                                        <FaTrash
                                                            style={{ marginLeft: "10px", cursor: "pointer" }}
                                                            onClick={() => handleDeleteFile(index)}
                                                        />
                                                    </div>
                                                ))}
                                            </DialogContent>
                                            <DialogActions>
                                                <Button style={{ backgroundColor: "#22baa0", color: "white" }} onClick={handleFileUpload} color="primary">
                                                    Upload
                                                </Button>
                                                <Button style={{ backgroundColor: "#34425a", color: "white" }} onClick={() => setShowFileInput(false)} color="primary">
                                                    Cancel
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                        </>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    )
}

export default WorkflowActions;