import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Chatbot from "./chatbot";

const Footer = () => {
  const navigate = useNavigate();

  const handleHelpClick = () => {
    navigate('/faq');
  };

  const handlePrivacyPolicyClick = () => {
    navigate('/privacy');
  };

  return (
    <div style={{ width: '100%', bottom: "100px" }} >
      <p className="no-s" >2024 &copy; Project M by Stalwarts.</p>
      <div style={{ position: 'absolute', top: '15px', right: '10px', marginRight: '200px', cursor: 'pointer', fontWeight: '500', display: 'flex', alignItems: 'center' }} onClick={handleHelpClick}>
        <ContactSupportIcon style={{ marginRight: '3px', fontSize: '20px', color: 'rgba(0, 0, 0, 0.87)' }} /> <span style={{ fontWeight: '400' }}>Help</span>
      </div>
      {/* <Chatbot/> */}
      <p className="no-s" style={{ cursor: 'pointer' }} onClick={handlePrivacyPolicyClick}>Privacy & Policy.</p>
    </div>

  )
}

export default Footer;
