import React, { useEffect, useState } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { apis } from "../../configuration/configurationAPI";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import store from "../../utils/index";

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});
const ViewPermissionSet = (userId) => {
    const { profile_id } = useParams();
    const [profileName, setProfileName] = useState("");
    const [updatedby, setUpdatedBy] = useState(0);
    const [createdby, setCreatedBy] = useState(0);
    const [permissions, setPermissions] = useState(null);
    const [error, setError] = useState(null);
    const [updatedbyUserName, setUpdatedbyUserName] = useState("");
    const [createdbyUserName, setCreatedbyUserName] = useState("");
    const [assignedTo, setAssignedTo] = useState(0);
    const [assignedToUserName, setAssignedToUserName] = useState("");
    const [updatedTimestamp, setUpdatedTimestamp] = useState("");
    const [createdTimestamp, setCreatedTimestamp] = useState("");

    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loggedinId = useSelector(commonSelectors.selectLoginUserId);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);

    useEffect(() => {
        axios
            .get(apis.base + apis.GET_PROILE_PERMISSIONID_API + profile_id,{
                headers: {

                userid: loggedinId,
                Authorization:"Bearer "+ baererToken

            }
            })
            .then((response) => {
                const { dashboard, audit, forms, submitted_files,  roles, users,profiles,departments, permission_list, reports,exports, profile_name, updated_user,created_user, assigned_to,created_timestamp, updated_timestamp,assigned_to_user } = response.data;
                setPermissions({ dashboard, audit, forms, submitted_files,  roles, users,profiles,departments, permission_list, reports,exports});
                setProfileName(profile_name);
                setUpdatedbyUserName(updated_user);
                setCreatedbyUserName(created_user);
                setAssignedTo(assigned_to);  
                setAssignedToUserName(assigned_to_user);
                setUpdatedTimestamp(formatDate(updated_timestamp));
                setCreatedTimestamp(formatDate(created_timestamp));
                setError(null);
            })
            .catch((error) => {
                console.error("Error fetching permission details:", error);
                setError("Error while fetching permission details. Please try again.");
            });
    }, [profile_id]);
    
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const offset = date.getTimezoneOffset(); // Get the time zone offset in minutes
        const adjustedDate = new Date(date.getTime() + (offset * 60 * 1000)); // Adjust the date by the offset
        const formattedDate = `${adjustedDate.getMonth() + 1}/${adjustedDate.getDate()}/${adjustedDate.getFullYear()}`;
        const formattedTime = `${adjustedDate.getHours()}:${('0' + adjustedDate.getMinutes()).slice(-2)}:${('0' + adjustedDate.getSeconds()).slice(-2)}`;
        return `${formattedDate} ${formattedTime}`;
    };

    
    

    return (
        <div className="page-content">
            <div className="page-inner">
                <div className="panel panel-white">
                    <div className="page-title">
                        <h3 style={{ color: "black" }}>View Permission Set</h3>
                        <div className="page-breadcrumb">
                            <ol className="breadcrumb">
                                <li><Link to="/Dashboard">Home</Link></li>
                                <li><Link to="">User Management</Link></li>
                                <li><Link to="">Permission Set List</Link></li>
                                <li>View Permission Set</li>
                            </ol>
                        </div>
                    </div>
                    <div className="panel panel-white" >
                    <div className="panel-body">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", padding: "10px" }}>
                        <div>
                            <label style={{ fontSize: "15px",fontWeight:"500" }}>Permission Set Name <br/><span style={{ fontSize: "15px", fontWeight:"normal" }}>{profileName}</span></label>
                        </div>
                        <div>
                            <label style={{ fontSize: "15px", fontWeight:"500" }}>Created By <br/> <span style={{ fontSize: "15px", fontWeight:"normal" }}>{createdbyUserName}</span></label>
                        </div>
                        {/* <div>
                            <label style={{ fontSize: "15px", fontWeight:"500" }}>Last Updated By <br/> <span style={{ fontSize: "15px", fontWeight:"normal" }}>{updatedbyUserName? updatedbyUserName:"N/A" }</span></label>
                        </div> */}
                        {/* <div>
                            <label style={{ fontSize: "15px" , fontWeight:"500"}}>Assigned To<br/><span style={{ fontSize: "15px", fontWeight:"normal" }}>{assignedToUserName}</span></label>
                        </div> */}
                        <div>
                            <label style={{ fontSize: "15px", fontWeight:"500" }}>Created Time<br/><span style={{ fontSize: "15px", fontWeight:"normal" }}>{createdTimestamp}</span></label>
                        </div>
                        {/* <div>
                            <label style={{ fontSize: "15px", fontWeight:"500" }}>Last Updated Time<br/><span style={{ fontSize: "15px", fontWeight:"normal" }}>{updatedTimestamp}</span></label>
                        </div> */}
                    </div>
                    </div>
                    </div>
                    <div className="panel-body">
                        {error && <p style={{ color: "red",textAlign:"center" }}>{error}</p>}
                        {!error && (

                            
                            <div className="table-responsive">
                                {permissions === null ? (
                                    <p style={{color:"Black",fontWeight:"bold",textAlign:"center"}}>Loading permissions...</p>
                                ) : permissions ? (
                                    <table id="example" className="display table" style={{ width: "100%" }}>
                                        <thead style={{backgroundColor:"rgb(35 212 182)"}}>
                                            <tr>
                                                <th> PERMISSIONSET NAME</th>
                                                <th>VIEW</th>
                                                <th>CREATE</th>
                                                <th>EDIT</th>
                                                <th>DELETE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(permissions).map(([key, permission]) => (
                                                <tr key={key}>
                                                    <td>{key.toUpperCase()}</td>
                                                    {permissions[key] && permissions[key].split("").map((value, index) => (
                                                        <td key={index}>
                                                            <FormControlLabel
                                                                control={<Checkbox
                                                                    disabled
                                                                    checked={value === "1"}
                                                                    style={{ color: value === "1" ? "#3c70dc" : "inherit" }}
                                                                />}
                                                            />
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p>No permissions found</p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            </div>
    );
};

export default ViewPermissionSet;
