import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/dist';
import { Link } from "react-router-dom";
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import { apis } from '../../configuration/configurationAPI';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Button, TextField, label } from "@mui/material";
import FileUploadButton from '../../Components/FileSubmitButton/FileUploadButton';
import Autocomplete from '@mui/material/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import DatePicker from 'react-datepicker';
import { IconButton, InputAdornment } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store from "../../utils/index";
import { commonSelectors } from "../../utils/commonstore";
import { Alert, Snackbar, ClickAwayListener } from "@mui/material";
const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

const FeedbackForm = () => {
  const { action } = useParams();
  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loginUserId = useSelector(commonSelectors.selectLoginUserId);
  const editIncidentData = useSelector(commonSelectors.selectEditIncidentData);
  const viewIncidentData = useSelector(commonSelectors.selectViewIncidentData);
  const getCurrentTime = () => {
    const now = new Date();
    const utcOffset = -4 * 60; // UTC offset for Eastern Time Zone (-4 hours)
    const utcTime = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
    const estTime = new Date(utcTime + (utcOffset * 60 * 1000));
    const hours = String(estTime.getHours()).padStart(2, '0');
    const minutes = String(estTime.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };



  const navigatateTo = useNavigate();
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [genderError, SetGenderError] = useState("");

  const [cityError, setcityError] = useState("");
  const [stateError, setstateError] = useState("");
  const [countryError, setcountryError] = useState("");
  const [zipCodeError, setzipCodeError] = useState("");




  const [receivedDateError, setReceivedDateError] = useState("");


  const [incidentdateError, setIncidentdateError] = useState("");



  const [personPatientIdError, setPersonPatientIdError] = useState("");
  const [personmrnError, setPersonmrnError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [workPhoneError, setWorkPhoneError] = useState("");
  const [admissionDateError, setAdmissionDateError] = useState("");
  const [personFirstNameError, setPersonFirstNameError] = useState("");
  const [personLastNameError, setPersonLastNameError] = useState("");
  const [personGenderError, setPersonGenderError] = useState("");

  const [otherInsuranceError, setOtherInsuranceError] = useState("");
  const [insuranceError, setInsuranceError] = useState("");
  const [reportedIncidentSeverityError, setReportedIncidentSeverityError] = useState("");
  const [issueListError, setIssueListError] = useState("");

  const [showSuccessPopup, setShowSuccessPopup] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [workphoneNumber, setWorkPhoneNumber] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [workphoneNumber2, setWorkPhoneNumber2] = useState("");

  const accessInfo = useSelector(commonSelectors.selectAccessInfo);

  const createAllowed = [ "1100", "1101", "1110", "1111"];

// Check if the user has the permission to submit (create or update)
const canSubmit = accessInfo?.forms && createAllowed.includes(accessInfo.forms);

// Determine if it's an "edit-only" permission (like 1010)
const isEditOnly = accessInfo?.forms === "1010" || accessInfo?.forms === "1011";
  const [feedbackState, setFeedbackState] = useState(
    viewIncidentData.spec ? viewIncidentData.spec :
      (editIncidentData.spec ? editIncidentData.spec :
        {
          "person_effected": {
            "patient_type": 0,
            // "patient_type": "",
            "last_name": "",
            "first_name": "",
            "patient_mrn": "",
            "gender": "",
            "dob": null,
            "address": "",
            "city": "",
            "state": "",
            "zipcode": "",
            "country": "",
            "phone": "",
            "work_phone": "",
            "insurance": "",
            "insurance_if_other": ""
          },
          "communicated_by": {
            "last_name": "",
            "first_name": "",
            "gender": "",
            "address": "",
            "city": "",
            "state": "",
            "zipcode": "",
            "country": "",
            "phone": "",
            "work_phone": "",
            "admission_date": new Date(),
            "insurance": "",
            "insurance_if_other": "",
            "patient_relation": ""
          },
          "general_incident_info": {
            "incident_type": "Feedback",
            "patient_type": "Out-Patient",

          },
          "demographics_info": {
            "patient_mrn": "",
            "first_name": "",
            "last_name": "",
            "gender": "",
            "dob": "",
            "address": "",
            "street": "",
            "city": "",
            "state": "",
            "country": "",
            "zipcode": "",


          },

          "incident_details": {

            "incident_state": 0,
            "incident_site": 0,

          },
          "special_additional_info": {
            "file_id": "",
            "department_id": 0,
            "entered_date": new Date(),
            "entered_time": getCurrentTime(),
            "entered_by": "",
            "received_date": null,
            "method": "",
            "reported_incident_severity": "",
            "file_owner": "",
            "referral_Source": "",
            "desired_outcome": "",
            "issue_list": "",
            "attachment": []
          }
        })
  );

  const feedbackStateString = JSON.stringify(feedbackState);

  const handleStateChange = (key, value, date) => {

    let tempFeedbackState = JSON.parse(JSON.stringify(feedbackState));
    switch (key) {
      // general incident info
      case "patient_id": tempFeedbackState.general_incident_info.patient_type = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_last_name": tempFeedbackState.person_effected.last_name = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_first_name": tempFeedbackState.person_effected.first_name = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_mrn": tempFeedbackState.person_effected.patient_mrn = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_gender": tempFeedbackState.person_effected.gender = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_dob": tempFeedbackState.person_effected.dob = date;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_address": tempFeedbackState.person_effected.address = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_city": tempFeedbackState.person_effected.city = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_state": tempFeedbackState.person_effected.state = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_zipcode": tempFeedbackState.person_effected.zipcode = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_country": tempFeedbackState.person_effected.country = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "addressValues1":
        tempFeedbackState.person_effected.zipcode = value.zipcode;
        tempFeedbackState.person_effected.country = value.country;
        tempFeedbackState.person_effected.state = value.state;
        tempFeedbackState.person_effected.city = value.city;
        tempFeedbackState.person_effected.street = value.street;
        tempFeedbackState.person_effected.address = value.address;

        // tempMedicationorChemotherpyState.demographics_info.address = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_phone": tempFeedbackState.person_effected.phone = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_work_phone": tempFeedbackState.person_effected.work_phone = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_insurance": tempFeedbackState.person_effected.insurance = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "patient_insurance_if_other": tempFeedbackState.person_effected.insurance_if_other = value;
        setFeedbackState(tempFeedbackState);
        break;



      case "incident_state": tempFeedbackState.incident_details.incident_state = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "incident_site": tempFeedbackState.incident_details.incident_site = value;
        setFeedbackState(tempFeedbackState);
        break;
      // communicated by
      case "last_name": tempFeedbackState.communicated_by.last_name = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "first_name": tempFeedbackState.communicated_by.first_name = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "gender": tempFeedbackState.communicated_by.gender = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "dob": tempFeedbackState.communicated_by.dob = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "address": tempFeedbackState.communicated_by.address = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "city": tempFeedbackState.communicated_by.city = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "state": tempFeedbackState.communicated_by.state = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "zipcode": tempFeedbackState.communicated_by.zipcode = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "country": tempFeedbackState.communicated_by.country = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "phone": tempFeedbackState.communicated_by.phone = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "addressValues2":
        tempFeedbackState.communicated_by.zipcode = value.zipcode;
        tempFeedbackState.communicated_by.country = value.country;
        tempFeedbackState.communicated_by.state = value.state;
        tempFeedbackState.communicated_by.city = value.city;
        tempFeedbackState.communicated_by.street = value.street;
        tempFeedbackState.communicated_by.address = value.address;
        setFeedbackState(tempFeedbackState);
        break;
      case "work_phone": tempFeedbackState.communicated_by.work_phone = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "admission_date": tempFeedbackState.communicated_by.admission_date = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "insurance": tempFeedbackState.communicated_by.insurance = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "insurance_if_other": tempFeedbackState.communicated_by.insurance_if_other = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "relation": tempFeedbackState.communicated_by.patient_relation = value;
        setFeedbackState(tempFeedbackState);
        break;





      // feedback details
      case "file_id": tempFeedbackState.special_additional_info.file_id = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "department_id": tempFeedbackState.special_additional_info.department_id = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "entered_date": tempFeedbackState.special_additional_info.entered_date = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "entered_time": tempFeedbackState.special_additional_info.entered_time = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "entered_by": tempFeedbackState.special_additional_info.entered_by = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "received_date": tempFeedbackState.special_additional_info.received_date = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "method": tempFeedbackState.special_additional_info.method = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "reported_incident_severity": tempFeedbackState.special_additional_info.reported_incident_severity = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "file_owner": tempFeedbackState.special_additional_info.file_owner = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "referral_Source": tempFeedbackState.special_additional_info.referral_Source = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "desired_outcome": tempFeedbackState.special_additional_info.desired_outcome = value;
        setFeedbackState(tempFeedbackState);
        break;
      case "issue_list": tempFeedbackState.special_additional_info.issue_list = value;
        setFeedbackState(tempFeedbackState);
        break;

      case "attachment": tempFeedbackState.special_additional_info.attachment = value;
        setFeedbackState(tempFeedbackState);
        break;
      default: break;
    }
  }


  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });


  const handleCloseSnackbar = () => {
    setShowSuccessPopup(false);
    navigatateTo('/forms') // Hide snackbar when closed
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;
    const namePattern = /^[a-zA-Z]+$/;
    const zipCodePattern = /^\d{6}$/;

    //  person effected
    if (!feedbackState.general_incident_info.patient_type) {
      setPersonPatientIdError("Please select patient type.");
    } else {
      setPersonPatientIdError("");
    }

    if (feedbackState.person_effected && feedbackState.person_effected.patient_last_name && feedbackState.person_effected.patient_last_name.match(namePattern)) {
      setPersonLastNameError("");
    } else {
      setPersonLastNameError("Please enter Person LastName.");
      valid = false;
    }
    if (!feedbackState.person_effected.patient_mrn) {
      setPersonmrnError("Please enter mrn.");
    } else {
      setPersonmrnError("");
    }


    if (!feedbackState.communicated_by.last_name.match(namePattern)) {
      setLastNameError("Please enter LastName.");
      valid = false;
    } else {
      setLastNameError("");
    }

    // if (!feedbackState.communicated_by.patient_relation) {
    //   setPatientRelationError("Please enter Insurance.");
    // } else {
    //   setstateError("");
    // }

    // feedback details
    if (!feedbackState.special_additional_info.received_date) {
      setReceivedDateError("Please enter received date.");
    } else {
      setcountryError("");
    }
    if (!feedbackState.special_additional_info.reported_incident_severity) {
      setReportedIncidentSeverityError("Please Select Reported Incident Severity.");
    } else {
      setzipCodeError("");
    }
    if (!feedbackState.special_additional_info.issue_list) {
      setIssueListError("Please enter received date.");
    } else {
      setIncidentdateError("");
    }

    if (editIncidentData.incident_id) {
      handleUpdate();
    } else {
      handlePost();
    }
  };
  const handleSamePersonEffected = () => {
    let tempFeedbackStateone = ({ ...feedbackState, communicated_by: { ...feedbackState.communicated_by, ...feedbackState.person_effected } })
    setFeedbackState(tempFeedbackStateone)
  }
  const putdata = {

    "file_count": 0,
    "is_deleted": false,
    "spec": feedbackStateString,
    "updated_by": loginUserId,
    "updated_timestamp": new Date().toISOString('en-US'),
    "files_info": ""

  }

  const [files, setFiles] = useState([]);

  const handleFileUpload = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFiles([...files, ...selectedFiles]);
    }
  };
  const handlePost = () => {
    const formData = new FormData();
    var reqb = {
      is_deleted: false,
      spec: feedbackStateString,
      created_by: loginUserId,
      updated_by: loginUserId
    }
    formData.append("IncidentInfo", JSON.stringify(reqb));


    files.forEach((file, index) => {
      formData.append(`files`, file);
    });


    axios.post(apis.base + apis.POST_INCIDENT_API, formData, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log("Response data:", response.data);
        console.log("Form submitted successfully!");
        setShowSuccessPopup(true);
        setSnackbarMessage("Form submitted successfully!");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleUpdate = () => {
    axios.put(apis.base + apis.PUT_INCIDENT_BYID_API + editIncidentData.incident_id, putdata, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((res) => {
        console.log("putdata", res);
        console.log("Form updated successfully!");
        // resetFormState();
        setShowSuccessPopup(true);
        setSnackbarMessage('Feedback updated successfully!');
      })
      .catch((error) => {
        console.error("Error updating form:", error);
      });
  }

  const [departmentData, setDepartmentData] = useState([]);
  useEffect(() => {
    axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setDepartmentData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
  }, []);

  const [locationData, setLocationData] = useState([])
  useEffect(() => {
    axios.get(apis.base + apis.GET_LOCATION_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setLocationData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
  }, []);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleDeleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };


  const patientRelationList = [
    { label: 'Mother' },
    { label: 'Father' },
    { label: 'Sister' },
    { label: 'Brother' },
    { label: 'Son' },
    { label: 'Daughter' },
    { label: 'Others' }

  ]

  const departmentsList = [
    { label: 'Neurology' },
    { label: 'Radiology' },
    { label: 'Orthology' },
  ]

  const methodList = [
    { label: 'Verbal' },
    { label: 'Telephone' }
  ]
  const issueList = [
    { label: 'Equipment Issues' },
    { label: 'Cleaning' }
  ]

  const reportedIncidentSeverityList = [
    { label: '1 - Minor' },
    { label: '2 - Moderate' },
    { label: '3 - Major' },
    { label: 'P3 - Potential Major' },
    { label: '4 - Catastrophic' },
    { label: 'P4-Potential Catastrophic' },
  ]
  const patientTypeList = [
    { label: 'In-Patient' },
    { label: 'Out-Patient' },
  ]

  // First name , Last name , File Owner name (first letter should be capitalized )
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [geolocation, setGeolocation] = useState(null);

  const GooglePlacesInput = () => {
    return (
      <div>
        <GooglePlacesAutocomplete
          placeholder={'Please enter your address'}
          currentLocation={true}
          onPress={(details = null) => {

            setGeolocation({
              latitude: details.geometry.location.lat,
              longitude: details.geometry.location.lng
            });
            handleStateChange("address", details.formatted_address)
            if (details.address_components.filter(item => item.types.includes('postal_code')).length) {
              handleStateChange("zipcode", details.address_components.filter(item => item.types.includes('postal_code'))[0].short_name)
            }
          }}
          fetchDetails={true}
          query={{
            key: 'AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8',
            language: 'en',
            components: 'country:us',
          }}
        />
      </div>
    );
  };
  return (
    <div>
      <div className="page-inner">
        <div className="page-title">
          <h3>Feedback - Patient Complaints or Grievances</h3>
          <div className="page-breadcrumb">
            <ol className="breadcrumb">
              <li><Link to="/Dashboard">Home</Link></li>
              <li><Link to="/Icons">Forms</Link></li>
              <li className="active">feedback</li>
            </ol>
          </div>
        </div>

        <div class="col-md-12">
          <form onSubmit={handleSubmit}>

            <div style={{ display: "flex" }} >
              <div id="main-wrapper" className='col-md-6'>
                <div class="row">
                  <div class="panel panel-white">

                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <h4 class="panel-title" >Person Effected</h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div class="panel-body">

                          <div class="form-group ">
                            <label for="exampleTextFieldEmail1">Patient type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                            {action === "view" || action === "edit" ? (
                              <Autocomplete disabled={action == "view"}
                                disablePortal
                                options={patientTypeList}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Please Select patient Type"
                                    fullWidth
                                  />
                                )}
                                value={patientTypeList.find(option => option.label === feedbackState.general_incident_info.patient_type) || null}

                              />) : (<Autocomplete
                                disablePortal
                                options={patientTypeList}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Please Select patient Type"
                                    fullWidth
                                  />
                                )}
                              />)}

                          </div>
                          <div class="form-group">
                            <label for="exampleTextFieldEmail1">Last Name <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                            <TextField fullWidth disabled={action == "view"}
                              value={feedbackState?.person_effected?.last_name}
                              onChange={(e) => handleStateChange("patient_last_name", e.target.value)}

                              type="text" id="exampleTextFieldEmail1" placeholder="Last Name" />

                          </div>

                          <div class="form-group">
                            <label for="exampleTextFieldEmail1">First Name
                            </label>
                            <TextField fullWidth disabled={action == "view"}
                              value={feedbackState?.person_effected?.first_name}
                              onChange={(e) => handleStateChange("patient_first_name",capitalizeFirstLetter (e.target.value))}
                              type="text" id="exampleTextFieldEmail1" placeholder="First Name" />
                            <div className="text-danger ">{personFirstNameError}</div>
                          </div>
                          <div class="form-group">
                            <label for="exampleTextFieldEmail1"></label>MRN <span style={{ fontweight: "bold", color: "red" }} >*</span>
                            <TextField
                              fullWidth
                              disabled={action === "view"}
                              value={feedbackState?.person_effected?.patient_mrn}
                              onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, ''); // Replace non-digit characters with an empty string
                                handleStateChange("patient_mrn", value);
                              }}
                              type="text"
                              id="exampleTextFieldEmail1"
                              placeholder="MRN"
                              inputProps={{ pattern: "[0-9]{18}", maxLength: 18 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton edge="end">
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />


                            <div className="text-danger ">{personmrnError}</div>
                          </div>


                          <div className="form-group">
                            <label htmlFor="exampleInputName2">Sex
                              {/* <span style={{ fontWeight: "bold", color: "red" }}>*</span> */}
                            </label>
                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                              <label className="col-md-4">
                                <input disabled={action == "view"} type="radio" name="patient_gender" checked={feedbackState.person_effected.gender === "male"} onChange={() => handleStateChange("patient_gender", "male")} /> Male
                              </label>
                              <label className="col-md-4">
                                <input disabled={action == "view"} type="radio" name="patient_gender" checked={feedbackState.person_effected.gender === "female"} onChange={() => handleStateChange("patient_gender", "female")} /> Female
                              </label>
                              <label className="col-md-4">
                                <input disabled={action == "view"} type="radio" name="patient_gender" checked={feedbackState.person_effected.gender === "others"} onChange={() => handleStateChange("patient_gender", "others")} /> Others
                              </label>

                            </div>
                            <div className="text-danger ">{personGenderError}</div>
                          </div>
                          < div class="form-group " style={{ display: "flex", flexDirection: "column" }}>
                            <label for="exampleInputName">DOB
                              {/* <span style={{ fontweight: "bold", color: "red" }} >*</span> */}
                            </label>
                            <DatePicker className='form-control'
                              fullWidth disabled={action == "view"}
                              selected={feedbackState.person_effected.dob}
                              onChange={(date) => {
                                setFeedbackState({
                                  ...feedbackState,
                                  person_effected: {
                                    ...feedbackState.person_effected,
                                    dob: date,
                                  },
                                });
                              }}
                              dateFormat="MM/dd/yyyy"
                              placeholderText="MM/DD/YYYY"
                              maxDate={new Date()}
                            />
                          </div>

                          <div className=" form-group">
                            <label >Address</label>
                            {action === "view" ? (
                              <div>
                                <GooglePlacesAutocomplete
                                  apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                  selectProps={{
                                    onChange: (e, a, b) => {
                                      handleStateChange("addressValues1", e.value)
                                      geocodeByPlaceId(e.value.place_id)
                                        .then(results => {
                                          console.log(results)
                                          handleStateChange("addressValues1", {
                                            "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                            "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                            "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                            "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                            "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                          })
                                        })
                                        .catch(error => console.error(error));
                                    }

                                  }}
                                />
                                <div style={{ position: "absolute", width: "95%", height: "37px", background: "black", opacity: "0.1", zIndex: 2, top: "24px" }}>
                                </div>
                              </div>
                            ) : (


                              <GooglePlacesAutocomplete
                                apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                selectProps={{
                                  onChange: (e, a, b) => {
                                    handleStateChange("addressValues1", e.value)
                                    geocodeByPlaceId(e.value.place_id)
                                      .then(results => {
                                        console.log(results)
                                        handleStateChange("addressValues1", {
                                          "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                          "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                          "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                          "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                          "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                        })
                                      })
                                      .catch(error => console.error(error));
                                  }

                                }}
                              />
                            )}

                          </div>

                          <div class="form-group">
                            <label >Street</label>
                            <div>
                              <TextField type="text" fullWidth placeholder="Street"
                                value={feedbackState?.person_effected?.street}
                                onChange={(e) => handleStateChange("street", e.target.value)}
                                disabled={action == "view"}
                              />

                            </div>

                            {/* </div> */}
                            {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
                            <div class="form-group">
                              <label for="exampleInputName">City</label>
                              <TextField type="text" fullWidth placeholder="City"
                                value={feedbackState?.person_effected?.city}
                                onChange={(e) => handleStateChange("city", e.target.value)}
                                disabled={action == "view"}
                              />
                              <div className="text-danger">{cityError}</div>
                            </div>


                            <div class="form-group  ">
                              <label for="exampleInputName2">State/Region</label>
                              <TextField type="text" fullWidth placeholder="State/Region"
                                value={feedbackState?.person_effected?.state}
                                onChange={(e) => handleStateChange("state", e.target.value)}
                                disabled={action == "view"}
                              />
                              <div className="text-danger">{stateError}</div>
                            </div>
                            {/* </div> */}
                            {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
                            <div class="form-group ">
                              <label for="exampleInputName">Country</label>
                              <TextField type="text" fullWidth placeholder="Country"
                                value={feedbackState?.person_effected?.country}
                                onChange={(e) => handleStateChange("country", e.target.value)}
                                disabled={action == "view"}

                              />
                              <div className="text-danger">{countryError}</div>
                            </div>
                            <div class="form-group ">
                              <label for="exampleInputName2">Zip code</label>
                              <TextField
                                placeholder='Zip Code'
                                fullWidth
                                type="text"
                                disabled={action == "view"}
                                value={feedbackState?.person_effected?.zipcode}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  if (/^\d{0,5}$/.test(value)) {
                                    setInputValue(value);
                                    handleStateChange("zipcode", value);
                                  }
                                }}
                                inputProps={{
                                  pattern: '[0-9]*',
                                }}
                              />
                            </div>
                            {/* </div> */}

                          </div>
                          <div class="form-group">
                            <label for="exampleTextFieldEmail1">Phone  </label>
                            <TextField disabled={action == "view"}
                              placeholder='Witness Phone'
                              fullWidth
                              type="text"
                              value={feedbackState?.person_effected?.phone}
                              onChange={(event) => {
                                const value = event.target.value;
                                if (/^\d{0,10}$/.test(value)) {
                                  setWorkPhoneNumber2(value);
                                  handleStateChange("patient_phone", value);
                                }
                              }}
                              inputProps={{
                                pattern: '[0-9]*',
                              }}
                            />



                          </div>
                          <div class="form-group">
                            <label for="exampleTextFieldEmail1">Work Phone </label>
                            <TextField disabled={action == "view"}
                              placeholder='Work Phone'
                              fullWidth
                              type="text"
                              value={feedbackState?.person_effected?.work_phone}
                              onChange={(event) => {
                                const value = event.target.value;

                                if (/^\d{0,10}$/.test(value)) {
                                  setWorkPhoneNumber(value);
                                  handleStateChange("patient_work_phone", value);
                                }
                              }}
                              inputProps={{
                                pattern: '[0-9]*',
                              }}
                            />
                          </div>
                          <div class="form-group">
                            <label for="exampleTextFieldEmail1">Insurance(If not on list,select other and specify below )
                            </label>
                            <TextField fullWidth disabled={action == "view"}
                              value={feedbackState?.person_effected?.insurance}
                              onChange={(e) => handleStateChange("patient_insurance", e.target.value)}
                              id="exampleTextFieldEmail1" placeholder="Insurance"></TextField>
                          </div>
                          <div class="form-group ">

                            <label for="exampleTextFieldEmail1">Insurance (If Others)
                            </label>
                            <TextField fullWidth disabled={action == "view"}
                              value={feedbackState?.person_effected?.insurance_if_other}
                              onChange={(e) => handleStateChange("patient_insurance_if_other", e.target.value)}
                              id="exampleTextFieldEmail1" placeholder="if it is on list, enter none"></TextField>
                          </div>

                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>

              <div id="main-wrapper" className='col-md-6'>
                <div class="row">
                  <div class="panel panel-white">
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <h4 class="panel-title"  >Communicated By</h4>
                      </AccordionSummary>
                      <AccordionDetails>

                        <div class="panel-body">

                          <form>
                            <div style={{ marginTop: "30px" }}>
                              <button
                                onClick={handleSamePersonEffected}
                                type="button" class="btn btn-success">Same as Person Effected</button>

                            </div>
                            {/* <label for="exampleTextFieldEmail1" required  >Last Name <span style={{ fontweight: "bold", color: "red" }} >*</span> </label> */}

                            <div class="form-group " style={{ marginTop: "20px" }} >
                              <label for="exampleTextFieldEmail1" required  >Last Name <span style={{ fontweight: "bold", color: "red" }} >*</span> </label>
                              <div>
                                <TextField fullWidth type="text" disabled={action == "view"}
                                  value={feedbackState?.communicated_by?.last_name}
                                  onChange={(e) => handleStateChange("last_name", capitalizeFirstLetter(e.target.value))}
                                  id="exampleTextFieldEmail1" placeholder="Last Name" />
                                <div className="text-danger ">{lastNameError}</div>
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="exampleTextFieldEmail1" required  >First Name<span style={{ fontweight: "bold", color: "red" }} >*</span> </label>
                              <TextField fullWidth type="text" disabled={action == "view"}
                                value={feedbackState?.communicated_by?.first_name}
                                onChange={(e) => handleStateChange("first_name", e.target.value)}
                                id="exampleTextFieldEmail1" placeholder="First Name" />
                              <div className="text-danger ">{firstNameError}</div>

                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleInputName2">Sex<span style={{ fontWeight: "bold", color: "red" }}>*</span></label>
                              <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <label className="col-md-4">
                                  <input disabled={action == "view"} type="radio" name="gender" checked={feedbackState.communicated_by.gender === "male"} onChange={() => handleStateChange("gender", "male")} /> Male
                                </label>
                                <label className="col-md-4">
                                  <input disabled={action == "view"} type="radio" name="gender" checked={feedbackState.communicated_by.gender === "female"} onChange={() => handleStateChange("gender", "female")} /> Female
                                </label>
                                <label className="col-md-4">
                                  <input disabled={action == "view"} type="radio" name="gender" checked={feedbackState.communicated_by.gender === "others"} onChange={() => handleStateChange("gender", "others")} /> Others
                                </label>

                              </div>
                              <div className="text-danger ">{genderError}</div>
                            </div>


                            <div className=" form-group">
                              <label >Address</label>
                              {action === "view" ? (
                                <div>
                                  <GooglePlacesAutocomplete
                                    apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                    selectProps={{
                                      onChange: (e, a, b) => {
                                        handleStateChange("address", e.value)
                                        geocodeByPlaceId(e.value.place_id)
                                          .then(results => {
                                            console.log(results)
                                            handleStateChange("addressValues2", {
                                              "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                              "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                              "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                              "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                              "street": results[0].address_components.find(component => component.types.includes("route")).long_name,

                                            })
                                          })
                                          .catch(error => console.error(error));
                                      }

                                    }}
                                  />
                                  <div style={{ position: "absolute", width: "95%", height: "37px", background: "black", opacity: "0.1", zIndex: 2, top: "24px" }}>
                                  </div>
                                </div>
                              ) : (
                                <GooglePlacesAutocomplete
                                  apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                  selectProps={{
                                    onChange: (e, a, b) => {
                                      handleStateChange("address", e.value)
                                      geocodeByPlaceId(e.value.place_id)
                                        .then(results => {
                                          console.log(results)
                                          handleStateChange("addressValues2", {
                                            "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                            "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                            "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                            "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                            "street": results[0].address_components.find(component => component.types.includes("route")).long_name,

                                          })
                                        })
                                        .catch(error => console.error(error));
                                    }

                                  }}
                                />
                              )}

                            </div>

                            <div class="form-group">
                              <label >Street</label>
                              <div>
                                <TextField type="text" fullWidth placeholder="Street"
                                  value={feedbackState?.communicated_by?.street}
                                  onChange={(e) => handleStateChange("street", e.target.value)}
                                  disabled={action == "view"}
                                />

                              </div>


                              <div className=" form-group">
                                <label for="exampleInputName">City</label>
                                <TextField type="text" fullWidth placeholder="City"
                                  value={feedbackState?.communicated_by?.city}
                                  onChange={(e) => handleStateChange("city", e.target.value)}
                                  disabled={action == "view"}
                                />
                                <div className="text-danger">{cityError}</div>
                              </div>


                              <div class="form-group">
                                <label for="exampleInputName2">State/Region</label>
                                <TextField type="text" fullWidth placeholder="State/Region"
                                  value={feedbackState?.communicated_by?.state}
                                  onChange={(e) => handleStateChange("state", e.target.value)}
                                  disabled={action == "view"}
                                />
                                <div className="text-danger">{stateError}</div>
                              </div>





                              <div class="form-group">
                                <label for="exampleInputName">Country</label>
                                <TextField type="text" fullWidth placeholder="Country"
                                  value={feedbackState?.communicated_by?.country}
                                  onChange={(e) => handleStateChange("country", e.target.value)}
                                  disabled={action == "view"}

                                />
                                <div className="text-danger">{countryError}</div>
                              </div>
                              <div class="form-group">
                                <label for="exampleInputName2">Zip code</label>
                                <TextField
                                  placeholder='Zip Code'
                                  fullWidth
                                  type="text"
                                  disabled={action == "view"}
                                  value={feedbackState?.communicated_by?.zipcode}
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    if (/^\d{0,5}$/.test(value)) {
                                      setInputValue(value);
                                      handleStateChange("zipcode", value);
                                    }
                                  }}
                                  inputProps={{
                                    pattern: '[0-9]*',
                                  }}
                                />
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="exampleTextFieldEmail1">Phone<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField disabled={action == "view"}
                                placeholder='Witness Phone'
                                fullWidth
                                type="text"
                                value={feedbackState?.communicated_by?.phone}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  if (/^\d{0,10}$/.test(value)) {
                                    setWorkPhoneNumber2(value);
                                    handleStateChange("phone", value);
                                  }
                                }}
                                inputProps={{
                                  pattern: '[0-9]*',
                                }}
                              />
                              <div className="text-danger  ">{phoneError}</div>
                            </div>
                            <div class="form-group">
                              <label for="exampleTextFieldEmail1">Work phone <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField
                                placeholder='Witness Phone'
                                fullWidth
                                disabled={action == "view"}
                                type="text"
                                value={feedbackState?.communicated_by?.work_phone}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  if (/^\d{0,10}$/.test(value)) {
                                    setPhoneNumber2(value);
                                    handleStateChange("work_phone", value);
                                  }
                                }}
                                inputProps={{
                                  pattern: '[0-9]*', // Only allows numeric values
                                }}
                              />
                              <div className="text-danger  ">{workPhoneError}</div>
                            </div>

                            < div class="form-group " style={{ display: "flex", flexDirection: "column" }}>
                              <label for="exampleInputName">Admission Date<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <DatePicker disabled={action == "view"} className='form-control'
                                fullWidth
                                selected={feedbackState.communicated_by.admission_date}
                                onChange={(date) => {
                                  setFeedbackState({
                                    ...feedbackState,
                                    communicated_by: {
                                      ...feedbackState.communicated_by,
                                      admission_date: date,
                                    },
                                  });
                                }}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="MM/DD/YYYY"
                                maxDate={new Date()}
                              />
                            </div>
                            <div class="form-group">

                              <label for="exampleTextFieldEmail1">Insurance(If not on list,select other and specify below )<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <TextField fullWidth disabled={action == "view"}
                                value={feedbackState?.communicated_by?.insurance}
                                onChange={(e) => handleStateChange("insurance", e.target.value)}
                                id="exampleTextFieldEmail1" placeholder="Insurance"></TextField>
                              <div className="text-danger  ">{insuranceError}</div>
                            </div>
                            <div class="form-group">
                              <label for="exampleTextFieldEmail1">Insurance (If Others)<span style={{ fontweight: "bold", color: "red" }} >*</span> </label>
                              <TextField fullWidth disabled={action == "view"}
                                value={feedbackState?.communicated_by?.insurance_if_other}
                                onChange={(e) => handleStateChange("insurance_if_other", e.target.value)}
                                id="exampleTextFieldEmail1" placeholder="if it is on list, enter none"></TextField>
                              <div className="text-danger  ">{otherInsuranceError}</div>
                            </div>
                            <div class="form-group" >
                              <label for="exampleTextFieldPassword1" >Relation to patient <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              {action === "view" || action === "edit" ? (
                                <Autocomplete
                                  disabled={action == "view"}
                                  disablePortal
                                  options={patientRelationList}
                                  getOptionLabel={(option) => option.label}

                                  onChange={(event, value) => handleStateChange("relation", value ? value.label : "")}
                                  renderInput={(params) => (<TextField
                                    placeholder='please select the relation'
                                    fullWidth {...params} />)}
                                  value={patientRelationList.find(option => option.label === feedbackState?.communicated_by?.patient_relation) || null}

                                />) : (<Autocomplete

                                  disablePortal
                                  options={patientRelationList}
                                  getOptionLabel={(option) => option.label}

                                  onChange={(event, value) => handleStateChange("relation", value ? value.label : "")}
                                  renderInput={(params) => (<TextField
                                    placeholder='please select the relation'
                                    fullWidth {...params} />)}

                                />)}


                            </div>



                          </form>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>

            </div>


            <div id="main-wrapper">
              <div class="row">
                <div>
                  <div class="panel panel-white">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <h4 class="panel-title"  >Incident  Details</h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div class="panel-body">
                          <div class="form-horizontal col-md-12">


                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div class="form-group col-md-6">
                                <label for="procedureDate"  >State Where Incident Occurred at<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <div>
                                  {action === "view" || action === "edit" ? (
                                    <Autocomplete
                                      options={locationData}
                                      getOptionLabel={(location) => location?.state}

                                      disabled={action == "view"}

                                      onChange={(e, value) => {
                                        handleStateChange("incident_state", value ? value.state : 0);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select State Where Incident Occured"
                                          variant="outlined" />
                                      )}

                                      value={locationData.filter(location => {
                                        return location.location_state == feedbackState.communicated_by.location_state
                                      })[0] || null}
                                      defaultValue={
                                        locationData.filter(location => {
                                          return location.location_state == feedbackState.communicated_by.location_state
                                        })[0] || {}
                                      }
                                    />) : (
                                    <Autocomplete
                                      options={locationData}
                                      getOptionLabel={(location) => location?.state}
                                      onChange={(e, value) => {
                                        handleStateChange("incident_state", value ? value.state : 0);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select State Where Incident Occured"
                                          variant="outlined" />

                                      )}
                                    />
                                  )}
                                </div>
                              </div>

                              <div class="form-group col-md-6">
                                <label for="procedureDate"  >Site Where Incident Occured <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                <div>
                                  {action === "view" || action === "edit" ? (
                                    <Autocomplete
                                      options={locationData}
                                      getOptionLabel={(location) => location?.location_name}
                                      disabled={action == "view"}
                                      onChange={(e, value) => {
                                        handleStateChange("incident_site", value ? value.location_name : 0);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Site Where Incident Occured"
                                          variant="outlined" />
                                      )}

                                      value={locationData.filter(locationsite => {
                                        return locationsite.location?.location_name == feedbackState.incident_details.location?.location_name
                                      })[0] || null}
                                      defaultValue={
                                        locationData.filter(locationsite => {
                                          return locationsite.location?.location_name == feedbackState.incident_details.location?.location_name
                                        })[0] || {}
                                      }
                                    />) : (
                                    <Autocomplete
                                      options={locationData}
                                      getOptionLabel={(location) => location?.location_name}
                                      onChange={(e, value) => {
                                        handleStateChange("incident_site", value ? value.location_id : 0);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Site Where Incident Occured"
                                          variant="outlined" x />
                                      )}
                                    />
                                  )}

                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>

            {/*  ******Feedback Details******** */}

            <div id="main-wrapper">
              <div class="row">
                <div>
                  <div class="panel panel-white">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <h4 class="panel-title"  >Feedback Details</h4>
                      </AccordionSummary>
                      <AccordionDetails>

                        <div class="panel-body">

                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div class="form-group col-md-6 ">
                              <label for="surgeryType" >File Id</label>
                              <div >
                                <TextField disabled={action == "view"} fullWidth type="text" id="surgeryType" style={{ borderRadius: "5px" }}
                                  value={feedbackState?.special_additional_info?.file_id}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(/\D/g, ''); // Replace non-digit characters with an empty string
                                    if (/^\d{0,4}$/.test(value)) { // Check if the value consists of at most 4 digits
                                      handleStateChange("file_id", value);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div class="form-group col-md-6" >
                              <label for="exampleInputPassword1"  >Department <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                              <div>
                                {action === "view" || action === "edit" ? (
                                  departmentData && departmentData.length > 0 ? (

                                    <Autocomplete
                                      options={departmentData}
                                      getOptionLabel={(department) => department.department_name}
                                      disabled={action === "view"}
                                      onChange={(e, value) => {
                                        handleStateChange("department_id", value ? value.department_id : 0);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select department"
                                          variant="outlined"
                                        />
                                      )}
                                      value={departmentData.filter(department => {
                                        return department.department_id == feedbackState.special_additional_info.department_id
                                      })[0] || null}
                                      defaultValue={
                                        departmentData.filter(department => {
                                          return department.department_id == feedbackState.special_additional_info.department_id
                                        })[0] || {}
                                      }
                                    />
                                  ) : (
                                    // Handle case when departmentData is undefined or empty
                                    <p>No department data available</p>
                                  )
                                ) : (
                                  <Autocomplete
                                    options={departmentData}
                                    getOptionLabel={(department) => department.department_name}
                                    disabled={action === "view"}
                                    onChange={(e, value) => {
                                      handleStateChange("department_id", value ? value.department_id : 0);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Please Select department"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                              <label htmlFor="surgeryType" >Entered Date</label>

                              <DatePicker disabled={action == "view"} className='form-control'
                                fullWidth
                                selected={feedbackState.special_additional_info.entered_date}
                                onChange={(date) => {
                                  setFeedbackState({
                                    ...feedbackState,
                                    special_additional_info: {
                                      ...feedbackState.special_additional_info,
                                      entered_date: date,
                                    },
                                  });
                                }}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="MM/DD/YYYY"
                                maxDate={new Date()}
                              />

                            </div>
                            <div class="form-group col-md-6">
                              <label for="procedureDate" >Entered Time</label>
                              <div >
                                <TextField disabled={action == "view"} fullWidth type="Time" placeholder="entered time" id="procedureDate"
                                  value={feedbackState?.special_additional_info?.entered_time}
                                  onChange={(e) => handleStateChange("entered_time", e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div class="form-group col-md-6 ">
                              <label for="procedureDate" >Entered By</label>
                              <div >
                                <TextField disabled={action == "view"} fullWidth type="text" placeholder="entered by" id="procedureDate"
                                  value={feedbackState?.special_additional_info?.entered_by}
                                  onChange={(e) => handleStateChange("entered_by", e.target.value)}

                                />
                              </div>
                            </div>

                            <div class="form-group col-md-6 " style={{ display: 'flex', flexDirection: "column" }}>
                              <label for="surgeryType" >  Received Date <span style={{ fontweight: "bold", color: "red" }} >*</span> </label>

                              <DatePicker className='form-control'
                                fullWidth
                                disabled={action == "view"}
                                selected={feedbackState.special_additional_info.received_date}
                                onChange={(date) => {
                                  setFeedbackState({
                                    ...feedbackState,
                                    special_additional_info: {
                                      ...feedbackState.special_additional_info,
                                      received_date: date,
                                    },
                                  });
                                }}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="MM/DD/YYYY"
                                maxDate={new Date()}
                              />
                              <div className="text-danger  ">{receivedDateError}</div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div class="form-group col-md-6">

                              <label for="exampleInputPassword1">Method</label>
                              <div >
                                {action === "view" || action === "edit" ? (
                                  <Autocomplete
                                    disablePortal
                                    disabled={action == "view"}
                                    options={methodList}
                                    getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("method", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Method"
                                          fullWidth
                                        
                                    />)}
                                    value={methodList.find(option => option.label === feedbackState.special_additional_info.method) || null}
                                  />) : (<Autocomplete
                                    disablePortal
                                    options={methodList}
                                    getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("method", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Method"
                                          fullWidth
                                    />)}
                                  />)}

                              </div>
                            </div>
                            <div class="form-group col-md-6 ">
                              <label for="surgeryType" >Severity </label>
                              <div >
                                {/* {action === "view" || action === "edit" ? (
                                <Autocomplete
                                  disablePortal
                                  disabled={action == "view"}
                                  options={reportedIncidentSeverityList}
                                  getOptionLabel={(option) => option.label}
                                  renderInput={(params) => (<TextField fullWidth placeholder='please select the reported severity' {...params}
                                    onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                  />)}
                                  value={reportedIncidentSeverityList.find(option => option.label === feedbackState.special_additional_info.reported_incident_severity) || null}
                                />):(                                <Autocomplete
                                  disablePortal
                                  options={reportedIncidentSeverityList}
                                  getOptionLabel={(option) => option.label}
                                  renderInput={(params) => (<TextField fullWidth placeholder='please select the reported severity' {...params}
                                    onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                  />)}
                                />)} */}

                                {action === "view" || action === "edit" ? (

                                  <Autocomplete
                                    disablePortal
                                    disabled={action == "view"}
                                    options={reportedIncidentSeverityList}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Please Select reported incident severity"
                                        fullWidth
                                      />
                                    )}
                                    value={reportedIncidentSeverityList.find(option => option.label === feedbackState.special_additional_info.reported_incident_severity) || null}
                                  />) : (<Autocomplete
                                    disablePortal
                                    options={reportedIncidentSeverityList}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Please Select reported incident severity"
                                        fullWidth
                                      />
                                    )}
                                  />)}

                              </div>


                            </div>

                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div class="form-group col-md-6 ">
                              <label for="surgeryType" >  File Owner </label>
                              <div >
                                <TextField fullWidth type="text" placeholder='enter file owner name' id="surgeryType"
                                  disabled={action == "view"}
                                  value={feedbackState?.special_additional_info?.file_owner}
                                  onChange={(e) => handleStateChange("file_owner", capitalizeFirstLetter(e.target.value))}
                                />
                              </div>
                            </div>

                            <div class="form-group col-md-6 ">
                              <label for="surgeryType" >  Referal Source </label>
                              <div >
                                <TextField fullWidth type="text" placeholder='referal source' id="surgeryType"
                                  disabled={action == "view"}
                                  value={feedbackState?.special_additional_info?.referral_Source}
                                  onChange={(e) => handleStateChange("referral_Source", e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div class="form-group col-md-6 ">
                              <label for="medications" >Desired Outcome</label>
                              <div >
                                <TextField fullWidth id="medications" placeholder='desired outcome'
                                  disabled={action == "view"}
                                  value={feedbackState?.special_additional_info?.desired_outcome}
                                  onChange={(e) => handleStateChange("desired_outcome", e.target.value)}
                                />
                              </div>
                            </div>



                            <div class="form-group col-md-6 ">

                              <label for="exampleInputPassword1">Issue List</label>
                              <div >
                                {action === "view" || action === "edit" ? (
                                  <Autocomplete
                                    disablePortal
                                    disabled={action == "view"}
                                    options={issueList}
                                    getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("issue_list", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Issue"
                                          fullWidth
                                        
                                    />)}
                                    value={issueList.find(option => option.label === feedbackState.special_additional_info.issue_list) || null}
                                  />) : (<Autocomplete
                                    disablePortal
                                    options={issueList}
                                    getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("issue_list", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Issue"
                                          fullWidth
                                        
                                    />)}
                                  />)}
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                           <label htmlFor="procedureDate" style={{ textAlign: "start" }}>File Upload</label>
                            <div>
                              <input type="file" disabled={action == "view"} style={{ marginTop: "10px", color: 'transparent' }} multiple onChange={handleFileUpload} />
                              {files.length > 0 ? (
                                <ol>
                                  {files.map((file, index) => (
                                    <li disabled={action === "view"} key={index}>
                                      {file.name}
                                      <IconButton disabled={action === "view"} onClick={() => handleDeleteFile(index)} aria-label="delete">
                                        <DeleteIcon />
                                      </IconButton>
                                    </li>
                                  ))}
                                </ol>
                              ) : (
                                <p>No files chosen</p>
                              )}

                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ marginLeft: "45%" }}>
            {canSubmit && (

             
              <Button
                variant="contained"
                type="submit"
                style={{ height: "35px", width: "auto", fontSize: "15px", background: "#22baa0" }}
                onClick={handleSubmit}
              >
                {editIncidentData.incident_id ? "Update" : "Submit"}
              </Button>
                )}
            </div>


          </form >
          <Snackbar
            open={showSuccessPopup}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MuiAlert onClose={handleCloseSnackbar}
              severity="success"
              variant="filled"
              sx={{ width: '100%' }}
              style={{ width: '300px', backgroundColor: "whitesmoke", color: "green", height: '45px', fontSize: '12px' }}>
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
        </div >
      </div >
    </div >

  );
}
export default FeedbackForm;
