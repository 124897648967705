import {createSlice} from "@reduxjs/toolkit"
import { createSelector } from '@reduxjs/toolkit';

const selectCommon = (state) => state.commonStore;

export const commonSelectors = {
  selectBaererToken: createSelector(
    [selectCommon],
    (common) => common.baererToken
  ),
  selectLoginUserId: createSelector(
    [selectCommon],
    (common) => common.loginUserId
  ),
  selectUserInfo: createSelector(
    [selectCommon],
    (common) => common.userInfo
  ),
 
};

const initialState = {
  "general_incident_info": {
    "incident_type": 0,
    "patient_type": 0,
    "injury_incurred": "",
    "equipment_involved": ""
  },
  "demographics_info": {
    "first_name": "",
    "last_name": "",
    "gender": 0,
    "dob": "",
    "address": "",
    "street": "",
    "city": "",
    "state": "",
    "country": "",
    "zipcode": ""
  },
  "incident_details": {
    "incident_date": "",
    "incident_time": "",
    "incident_state": 0,
    "incident_site": 0,
    "file_owner": "",
    "entered_date": "",
    "entered_time": "",
    "entered_by": "",
    "witness_name": "",
    "witness_phone": ""
  },
  "specific_incident_info": {
    "department_id": 0,
    "fall_was": "",
    "patient_assessed_prior": false,
    "fall_risk_score": 0,
    "safety_precautions": "",
    "anticoagulant": false,
    "repeat_fall": false,
    "physician_involved": false,
    "contributing_factors": "",
    "immediate_actions_taken": "",
    "reported_incident_severity": 0,
    "incident_description": "",
    "attachment": ""
  }
}

const fallSlice = createSlice({
  // name: "common",
  name: "fall",
  initialState: initialState,
 
})

export const commonActions = fallSlice.actions
export default fallSlice
