import {createSlice} from "@reduxjs/toolkit"
import { createSelector } from '@reduxjs/toolkit';

const selectCommon = (state) => state.commonStore;

export const commonSelectors = {
  selectBaererToken: createSelector(
    [selectCommon],
    (common) => common.baererToken
  ),
  selectLoginUserId: createSelector(
    [selectCommon],
    (common) => common.loginUserId
  ),
  selectUserInfo: createSelector(
    [selectCommon],
    (common) => common.userInfo
  ),
 
};

const initialState = {
  "general_incident_info": {
    "incident_type": 0,
    "patient_type": "",
    "injury_incurred": "",
    "equipment_involved": ""
  },
  "demographics_info": {
    "first_name": "",
    "last_name": "",
    "gender": 0,
    "dob": "",
    "address": "",
    "street": "",
    "city": "",
    "state": "",
    "country": "",
    "zipcode": ""
  },
  "incident_details": {
    "incident_date": "",
    "incident_time": "",
    "incident_state": "",
    "incident_site": "",
    "file_owner": "",
    "entered_by": "",
    "entered_date": "",
    "entered_time": "",
    "witness_name": "",
    "witness_phone": "",
    "witness_address":""

  },
  "special_additional_info": {
    "department_id": 0,
    "specific_incident_type": 0,
    "hospitalized_On": "",
     "image_procedure_type":"",
    "area_or_organ_studied": "",
    "contrast_media_used": "",
    "Radiologic_technologist_or_sonographer_name": "",
    "imaging_study_date": "",
    "imaging_study_time": "",
    "asessment_of_technical_quality_images_": "",
    "patient_reaction": 0,
    "outcome": "",
    "sequelae": "",
    "relevant_history": "",
    "physician_involved": false,
    "contributing_factors": "",
    "immediate_actions_taken": "",
    "reported_incident_severity": 0,   
     "other_information": "",
     "incident_description":"",

    "attachment": ""

  }
}


const diagnosticImagingSlice = createSlice({
  // name: "common",
  name: "diagnosticImaging",
  initialState: initialState,
 
})

export const commonActions = diagnosticImagingSlice.actions
export default diagnosticImagingSlice
