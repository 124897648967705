import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaUserEdit, FaEdit, FaExchangeAlt, FaTrash } from 'react-icons/fa';
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
import { Button, InputLabel } from "@mui/material";
import { format } from 'date-fns';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { commonActions } from "../../utils/commonstore";
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";

import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
    commonStore = store.getState().commonStore;
});
function PermissionSetList() {

    const accessInfo = useSelector(commonSelectors.selectAccessInfo);

    const navigate=useNavigate()
    // const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [nameFilter, setNameFilter] = useState("");
    // const [idFilter, setIdFilter] = useState(0);
    // const [duration, setDuration] = useState(null);
    // const [fromDateFilter, setFromDateFilter] = useState(null);
    // const [toDateFilter, setToDateFilter] = useState(null);
    const [error, setError] = useState(null);
    // const [filterError, setFilterError] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [profileIdToDelete, setProfileIdToDelete] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);
    const [rows, setRows] = useState([]);
    const [filterModel, setFilterModel] = useState({ items: [] });

    useEffect(() => {
        fetchPermissions();
    }, []);

    const fetchPermissions = () => {
        axios.get(apis.base + apis.GET_PROFILE_PERMISSION_API,
            {
                headers: {
                    userid: loginUserId,
                    Authorization: "Bearer " + baererToken
                }
            })
            .then((succResp) => {
                const permissions = succResp.data.map((el) => {
                    return ({
                        ...el,
                        profile_id: el.profile_id,
                        profile_name: el.profile_name,
                        assigned_to_user: el.assigned_to_user,
                    })
                })
                setRows(permissions);
                setLoading(false);
            })
            .catch((errorresp) => {
                console.log(JSON.stringify(errorresp));
                setError("Error while fetching profile details. Please try again.");
                setLoading(false);
            });
    };

    const handlePermissionset=()=>{
        navigate("/ADDPERMISSIONS")
    }


    const confirmDelete = (profileId) => {
        setProfileIdToDelete(profileId);
        setDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };
    const handleDeleteConfirmation = (profileId) => {
        setProfileIdToDelete(profileId);
        setDeleteDialogOpen(true);
    };
    const removeProfile = (profileId) => {

        axios.delete(apis.base + apis.DELETE_PROFILE_API + profileId, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            }
        })

            .then((resp) => {
                fetchPermissions();
                setDeleteDialogOpen(false);
                closeDeleteDialog();
            })
            .catch((error) => {
                console.log("Error deleting permission:", error);
                setError("Error deleting permission. Please try again.");
            });

    }

    const deleteAllowed = ["1001", "1011", "1101", "1111"];
    const viewAllowed = ["1000", "1001","1010","1011","1100","1101", "1110", "1111"];

    const canDelete = accessInfo?.permission_list && deleteAllowed.includes(accessInfo?.permission_list);
    const canView = accessInfo?.permission_list && viewAllowed.includes(accessInfo?.permission_list);

    const columns = [
        // {field:'id', headerName:<strong>Id</strong>,width:150},
        { field: 'profile_id', headerName: <strong>Permission Set Id</strong>, width: 200 },
        { field: 'profile_name', headerName: <strong>Permission Set Name</strong>, width: 400 },
        // { field: 'assigned_to_user', headerName: <strong>User Name</strong>, width: 200 },
        // {
        //     field: 'valid_from', headerName: <strong>Valid From</strong>, width: 200,
        //     valueGetter: (params) => new Date(params.row.valid_from).toLocaleString('en-US')
        // }, {
        //     field: 'valid_to', headerName: <strong>Valid To</strong>, width: 200,
        //     valueGetter: (params) => new Date(params.row.valid_to).toLocaleString('en-US')
        // },
        {
            field: 'action', headerName: <strong> Action</strong>, width: 200,
            renderCell: (params) => (
                <div>
                    {canDelete || canView ? (
                        <div>
                            {canView && (
                    <Link to={`/viewpermissionset/${params.row.profile_id}`} className="btn btn-normal btn-addon m-b-sm" title="View">
                        <FaEye style={{ color: 'black' }} />
                    </Link>
                            )}
                    {/* <Link to={`/updateuserpermission/${params.row.profile_id}`} className="btn btn-normal btn-addon m-b-sm" title="Update">
                        <FaUserEdit style={{ color: 'black' }} />
                    </Link> */}
                    {/* <Link to={`/editpermissions/${params.row.profile_id}`} className="btn btn-normal btn-addon m-b-sm" title="Edit">
                        <FaEdit style={{ color: 'black' }} />
                    </Link> */}
                    {/* <Link to={`/reassignpermissionsset/${params.row.profile_id}`} className="btn btn-normal btn-addon m-b-sm" title="Reassign">
                        <FaExchangeAlt style={{ color: 'black' }} />
                    </Link> */}
                    {canDelete && (
                    <button
                        className="btn btn-normal btn-addon m-b-sm"
                        title="Delete"
                        style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer' }}
                        onClick={() => handleDeleteConfirmation(params.row.profile_id)}
                    >
                        <FaTrash style={{ color: 'black' }} />
                    </button>
                    )}
                    </div>
                    ) : null}
                </div>
            )
        },
    ];

    const addPermissions = ["1100", "1101", "1110", "1111"]

    return (
        <div className="page-content">
            <div className="page-inner">
                <div className="panel panel-white">
                    <div className="page-title">
                        <h3>Permission Set List</h3>
                        <div className="page-breadcrumb">
                            <ol className="breadcrumb">
                                <li><Link to="/Dashboard">Home</Link></li>
                                <li>Permission Set List</li>
                            </ol>
                        </div>
                    </div>


                    <div id="main-wrapper">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="mailbox-content" style={{ overflow: 'auto' }}>
                                    <div style={{ height: "100%", width: '100%', paddingTop: "10px" }}>
                                    {addPermissions.includes(accessInfo?.permission_list) && (
                                    <Button variant="contained" onClick={handlePermissionset} style={{marginBottom:"5px",marginLeft:"5px" }}> Add Permission Set</Button>
                                    )}
                                        {error && <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>{error}</p>}
                                        {loading && <p style={{ textAlign: "center", fontSize: "15px" }}>Loading...</p>}
                                        {!loading && !error && (
                                            <DataGrid
                                                rows={rows}
                                                columns={columns}
                                                pageSize={5}
                                                rowsPerPageOptions={[10, 20, 30]}
                                                loading={loading}
                                                classes={{
                                                    overlayWrapper: 'customOverlayWrapper'
                                                }}
                                                sx={{
                                                    "& .customOverlayWrapper ": {
                                                        height: "63px !important"
                                                    },
                                                    fontWeight: 'fontWeightLight',
                                                    mx: 0.5,
                                                    fontSize: 14, fontFamily: "sans-serif",
                                                    boxShadow: 2
                                                }}
                                                components={{
                                                    Toolbar: () => (
                                                        <GridToolbarQuickFilter
                                                            classes={{
                                                                toolbarQuickFilter: 'customtoolbarQuickFilter'
                                                            }}
                                                            sx={{
                                                                width: '35% !important',
                                                                position: 'absolute !important',
                                                                right: 0,
                                                                top: '-30px !important'
                                                            }}
                                                            filterModel={filterModel}
                                                            onFilterModelChange={(newModel) => setFilterModel(newModel)}
                                                        />
                                                    ),
                                                    NoRowsOverlay: () => (
                                                        <div
                                                            style={{
                                                                position: 'sticky',
                                                                top: '50%',
                                                                transform: 'translate(-50%, 0%)',
                                                                textAlign: 'center',
                                                                marginLeft: '45%'
                                                            }}
                                                        >
                                                            No data found
                                                        </div>
                                                    ),

                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={closeDeleteDialog}
                >
                    <DialogTitle>Delete Profile</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this profile?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDeleteDialog}>Cancel</Button>
                        <Button onClick={() => removeProfile(profileIdToDelete)} variant="contained" color="error">Delete</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}

export default PermissionSetList;
